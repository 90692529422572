import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CustomObjectState } from '@shared/modules/custom-objects/state/state';
import { AccountState } from '@state/account/state';
import { TaskRelatedToModule } from '@tasks/tasks.types';
import { ObjectsManagerActions } from 'app/modules/settings/objects-manager/state/actions';
import { ObjectsManagerState } from 'app/modules/settings/objects-manager/state/state';
import { plural } from 'pluralize';

@Injectable({
  providedIn: 'root',
})
export class TasksBaseUrlService {
  constructor(private store: Store) {}

  getTasksUrl(relatedToType: string, relatedToTypeId: number): string {
    if (relatedToType === TaskRelatedToModule.AggregatedTasks) {
      relatedToType = 'Account';
    }

    if (relatedToType === TaskRelatedToModule.TaskListTemplate) {
      relatedToType = 'Account';
      relatedToTypeId = +this.store.selectSnapshot(AccountState.getAccount)?.id;
    }

    if (relatedToType === TaskRelatedToModule.CustomObjectValue) {
      const accountId = +this.store.selectSnapshot(AccountState.getAccount)?.id;
      const selectedCo = this.store.selectSnapshot(ObjectsManagerState.getById);
      return `accounts/${accountId}/custom_objects/${selectedCo?.id}/values/${relatedToTypeId}`;
    }

    const pluralType = plural(relatedToType).toLowerCase();
    return `${pluralType}/${relatedToTypeId}`;
  }
}
