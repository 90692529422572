import { SelectableFieldDef } from 'app/shared/components/column-selector/column-selector.types';

export const defaultTaskTableFields: Partial<SelectableFieldDef>[] = [
  { id: -1, name: 'title', label: 'Title' },
  { id: -2, name: 'workflow_state', label: 'Status' },
  { id: -3, name: 'start_date', label: 'Start Date' },
  { id: -4, name: 'due_date', label: 'Due Date' },
  {
    id: -5,
    name: 'assigned_user_id',
    label: 'Assigned User',
    meta: {
      sorting: 'assigned_user_name',
    },
  },
  {
    id: -6,
    name: 'project_id',
    label: 'Deal',
    meta: {
      sorting: 'project.title',
    },
  },
  {
    id: -7,
    name: 'property_id',
    label: 'Property',
    meta: {
      sorting: 'property.title',
    },
  },
];
