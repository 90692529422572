import { FileResource, FolderResource } from '../folders.types';

interface CreateFileOptions {
  createDocument?: boolean;
  updateState?: boolean;
  relatedToType?: string;
  relatedToId?: number;
}
export namespace Folders {
  export class UpdateFolderModule {
    static readonly type = '[Folders] UpdateFolderModule';

    constructor(
      public moduleBaseUrl: string,
      public moduleId: number,
    ) {}
  }

  export class GetFolder {
    static readonly type = '[Folders] GetFolder';

    constructor(public id: number | undefined) {}
  }

  export class SearchFolder {
    static readonly type = '[Folders] SearchFolder';

    constructor(public query: any) {}
  }

  export class AddFolder {
    static readonly type = '[Folders] AddFolder';

    constructor(
      public folder: FolderResource,
      public opts = { updateCurrentFolder: true },
    ) {}
  }

  export class UpdateFolder {
    static readonly type = '[Folders] UpdateFolder';

    constructor(public folder: FolderResource) {}
  }

  export class ResetFolderState {
    static readonly type = '[Folders] ResetFolderState';

    constructor() {}
  }

  export class DeleteFolder {
    static readonly type = '[Folders] DeleteFolder';

    constructor(public id: number) {}
  }

  export class ShareFolder {
    static readonly type = '[Folders] ShareFolder';

    constructor(public folderId: number) {}
  }

  export class GetFile {
    static readonly type = '[Folders] GetFile';

    constructor(
      public folderId: number,
      public fileId: number,
    ) {}
  }

  export class GetFileById {
    static readonly type = '[Folders] GetFileById';

    constructor(public fileId: number) {}
  }
  export class CreateFile {
    static readonly type = '[Folders] CreateFile';

    constructor(
      public folderId: number,
      public file: File,
      public opts: CreateFileOptions = { updateState: true },
    ) {}
  }

  export class CreateFileDocument {
    static readonly type = '[Folders] CreateFileDocument';

    constructor(
      public folderId: number,
      public fileId: number,
      public file: File,
      public opts = { updateState: true },
    ) {}
  }

  export class DeleteDocument {
    static readonly type = '[Folders] DeleteDocument';

    constructor(
      public folderId: number,
      public fileId: number,
      public documentId: number,
      public opts = { updateState: true },
    ) {}
  }

  export class GetBase64Document {
    static readonly type = '[Folders] GetBase64Document';

    constructor(
      public folderId: number,
      public fileId: number,
      public documentId: number,
    ) {}
  }

  export class UploadExtractedData {
    static readonly type = '[Folders] UploadedExtractedData';

    constructor(
      public folderId: number,
      public fileId: number,
      public documentId: number,
      public data: string,
    ) {}
  }

  export class DeleteFile {
    static readonly type = '[Folders] DeleteFile';

    constructor(
      public folderId: number,
      public fileId: number,
    ) {}
  }

  export class UpdateFile {
    static readonly type = '[Folders] UpdateFile';

    constructor(
      public folderId: number,
      public fileResource: FileResource,
    ) {}
  }

  export class UpdateRank {
    static readonly type = '[Folders] UpdateRank';

    constructor(public files: any[]) {}
  }

  export class SendToDocusign {
    static readonly type = '[Folders] SendToDocusign';

    constructor(
      public folderId: number,
      public fileId: number,
    ) {}
  }

  export class ShareFile {
    static readonly type = '[Folders] ShareFile';

    constructor(
      public folderId: number,
      public fileId: number,
    ) {}
  }
}
