<div
  class="container"
  [ngClass]="{
    'w-full': uiOptions?.size === 'full',
    'no-rounded-borders': uiOptions?.roundedBorders === false,
    'item-selected': !!selectedItem,
    'focus:border-primary': !disabled,
  }"
  [matTooltip]="tooltip"
  [tabindex]="disabled ? -1 : 0"
  (keydown.enter)="openOptionsPanel($event)"
>
  <div
    class="container__splash"
    [ngClass]="{ 'container__splash--disabled': disabled }"
    [class]="ui.splashId"
    [class.container__splash--disabled-transparent]="disabled && uiOptions?.disabledClass === 'disabled-transparent'"
  >
    <div class="container__splash__selection-box">
      <ng-container *ngIf="selectedItem || selectedItems.length > 0; else noSelection">
        <div
          class="container__splash__selection-box__item"
          [ngClass]="{ 'container__splash__selection-box__item--full': uiOptions?.size === 'full' }"
        >
          @if (multi) {
            <div class="w-full flex flex-row items-center gap-1">
              <span class="max-w-1/2 inline-block truncate">{{ selectedItems[0] || '' | labelForValue: items }}</span>
              @if (selectedItems.length > 1) {
                <span class="opacity-75 text-sm" [matTooltip]="selectedItems | labelForValue: items">
                  (+{{ selectedItems.length - 1 }} {{ selectedItems.length === 2 ? 'other' : 'others' }})
                </span>
              }
            </div>
          } @else {
            <ng-container *ngIf="getSelectedItemInfo() as itemInfo">
              {{ itemInfo.label }}
            </ng-container>
          }
        </div>
      </ng-container>
      <ng-template #noSelection>
        <div class="container__splash__selection-box__item no-selection">{{ uiOptions?.placeholder || 'select' }}</div>
      </ng-template>
    </div>
    <div class="flex flex-row gap-1">
      <button tabindex="1" *ngIf="multi" class="flex flex-row items-center" (click)="clearSelection($event)">
        <ts-icon color="light-gray" icon="close"></ts-icon>
      </button>
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
  </div>
  <div
    *ngIf="isPanelOpen | async"
    class="container__options-panel"
    [ngClass]="{ 'rounded-none': uiOptions?.roundedBorders === false }"
    [class]="ui.optionsPanelId"
  >
    <div class="container__options-panel__search-box">
      <mat-icon>search</mat-icon>
      <input
        #searchInput
        type="text"
        [placeholder]="uiOptions?.searchPlaceholder || 'Search...'"
        [formControl]="searchFormControl"
        (keydown.escape)="closeOptionsPanel(); $event.preventDefault(); $event.stopPropagation()"
        (keydown.tab)="closeOptionsPanel(); $event.preventDefault(); $event.stopPropagation()"
      />
    </div>
    <cdk-virtual-scroll-viewport
      appendOnly
      itemSize="36"
      class="container__options-panel__list"
      [ngClass]="{
        medium: uiOptions?.size === 'medium',
        small: uiOptions?.size === 'small',
        'one-item': items?.length === 1,
        'two-items': items?.length === 2,
        'three-items': items?.length === 3,
        'four-items': items?.length === 4,
        'has-group': hasGroups,
      }"
    >
      <ng-container *ngIf="hasGroups; else simpleOptions">
        <div
          class="container__options-panel__list__item"
          [class.group]="item.isGroup"
          [class.cursor-pointer]="!item.isGroup"
          [class.selected]="item[itemKey] | tsSelected: (multi ? selectedItems : selectedItem)"
          *cdkVirtualFor="
            let item of items | tsGroupBy: true | tsFilterBy: [] : (searchValue$ | async) : filterFn;
            templateCacheSize: 20
          "
          (click)="onOptionSelect($event, item)"
        >
          <mat-icon *ngIf="item[itemKey] | tsSelected: (multi ? selectedItems : selectedItem)"
            >check_circle_outline</mat-icon
          >
          {{ item.isGroup ? item.label : item[itemLabel] }}
        </div>
      </ng-container>
      <ng-template #simpleOptions>
        <div
          class="container__options-panel__list__item cursor-pointer"
          [ngClass]="{ 'container__options-panel__list__item--disabled': item?.disabled }"
          [class.group]="item.isGroup"
          [class.selected]="item[itemKey] | tsSelected: (multi ? selectedItems : selectedItem)"
          *cdkVirtualFor="let item of items | tsFilterBy: [] : (searchValue$ | async) : filterFn; templateCacheSize: 20"
          (click)="onOptionSelect($event, item)"
        >
          <mat-icon *ngIf="item[itemKey] | tsSelected: (multi ? selectedItems : selectedItem)"
            >check_circle_outline</mat-icon
          >
          <span>{{ item[itemLabel] }}</span>
        </div>
      </ng-template>
    </cdk-virtual-scroll-viewport>
  </div>
</div>
