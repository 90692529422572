import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';

export const specialCharValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
  const reg = /^[A-Za-z0-9 ]+$/g; // only Alpha numeric
  if (!reg.test(control.value)) {
    return { specialChar: true };
  }
  return null;
};

export const noWhiteValidator = (control: AbstractControl): Observable<{ [key: string]: boolean } | null> => {
  let isWhitespace = (control.value || '').trim().length === 0;
  return !isWhitespace ? null : of({ whitespace: true });
};

export const passwordNotMatchValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
  const confirmPassword = control;
  const password = control.root.get('password');
  if (password && confirmPassword && password.value !== confirmPassword.value) {
    return { passwordNotMatch: true };
  }
  return null;
};

export const subdomainValidator = (control: AbstractControl): { [key: string]: boolean } | null => {
  const reg = /^[a-z0-9]{3,16}$/i;
  if (!reg.test(control.value)) {
    return { subdomain: true };
  }
  return null;
};

/**
 * @description Validates if the input value is unique in the array based on the array's property name.
 * @param array - The array to check for duplicates.
 * @param propertyName - The array's property name to check for duplicates.
 * @param caseSensitive - Whether the comparison should be case-sensitive or not. Defaults to true.
 * @returns A validator function.
 */
export const uniqueNameValidator = (
  array: any[],
  propertyName: string,
  caseSensitive: boolean = false,
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    // Trim leading and trailing spaces.
    const inputValue = control.value.trim();

    // Convert to lowercase for case-insensitive comparison if caseSensitive is false.
    const normalizedInputValue = caseSensitive ? inputValue : inputValue.toLowerCase();

    // Check if the input value already exists in the array.
    const isDuplicate = array.some((item) => {
      const itemValue = caseSensitive ? item[propertyName] : item[propertyName].toLowerCase();
      return itemValue === normalizedInputValue;
    });

    // If a duplicate is found, return a validator function, otherwise, return null.
    return isDuplicate ? { duplicateName: true } : null;
  };
};
