import { CustomObjectPermissions } from '@core/models';
import { Permissionable } from '../../modules/projects/projects.types';

export namespace PermissionUtils {
  export const canView = (data: Partial<Permissionable>) => {
    return data?.permissions ? data.permissions.is_viewable : true;
  };

  export const canEdit = (data: Partial<Permissionable>) => {
    return data?.permissions ? data.permissions.is_editable : true;
  };

  export const canDelete = (data: Partial<Permissionable>) => {
    return data?.permissions ? data.permissions.is_deletable : true;
  };

  export const generateCustomObjectPermissions = (customObjectFullName: string): CustomObjectPermissions => {
    const name = customObjectFullName.toUpperCase();
    return {
      VIEW: `${name}_VIEW`,
      CREATE: `${name}_CREATE`,
      EDIT: `${name}_EDIT`,
      DELETE: `${name}_DELETE`,
      MANAGE_ADD_FORM: `${name}_MANAGE_ADD_FORM`,
      MANAGE_LAYOUT: `${name}_MANAGE_LAYOUT`,
      PUBLIC_CUSTOM_VIEWS: `${name}_PUBLIC_CUSTOM_VIEWS`,
      SHARING_RECORDS: `${name}_SHARING_RECORDS`,
    };
  };
}
