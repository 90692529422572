import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EthanChat, EthanMessage, EthanMessageResponse } from '@core/models/ethan.types';
import { AccountState } from '@state/account/state';
import { Store } from '@ngxs/store';
import { User } from 'app/core/models/user.types';
import { environment } from '@env/environment';
import { Account } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class EthanService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {}

  getAccountId(): string {
    const account = this.store.selectSnapshot(AccountState.getAccount); // Extract account details
    return account.id;
  }

  getAccount(): Account {
    const account = this.store.selectSnapshot(AccountState.getAccount);
    return account;
  }

  getEthanChats(searchText?: string): Observable<{ ethan_chats: EthanChat[] }> {
    const accountId = this.getAccountId();
    return this.http.get<{ ethan_chats: EthanChat[] }>(`accounts/${accountId}/ethan_chats?search=${searchText ?? ''}`);
  }

  getEthanQuestions(chatId: number, pageNum?: number): Observable<EthanMessageResponse> {
    const accountId = this.getAccountId();
    return this.http.get<EthanMessageResponse>(
      `accounts/${accountId}/ethan_chats/${chatId}/ethan_messages?page=${pageNum ?? 1}&per_page=10`,
    );
  }

  askEthan(chatId: number, ethanQuestion: string, fileUrl?: string, fileName?: string): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.post<any>(`accounts/${accountId}/ethan_chats/${chatId}/ethan_messages`, {
      question: ethanQuestion,
      file_url: fileUrl,
      file_name: fileName,
    });
  }

  updateEthanAnswer(chatId: number, messageId: number, answer: string): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.put<any>(`accounts/${accountId}/ethan_chats/${chatId}/ethan_messages/${messageId}`, {
      answer: answer,
    });
  }

  createEthanChat(chatName: string): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.post<any>(`accounts/${accountId}/ethan_chats`, { ethan_chat: { name: chatName } });
  }

  deleteEthanChat(chatId: number): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.delete<any>(`accounts/${accountId}/ethan_chats/${chatId}`);
  }

  updateEthanQuestion(ethanQuestion: EthanMessage): Observable<any> {
    const accountId = this.getAccountId();
    return this.http.put<any>(`accounts/${accountId}/ethan/${ethanQuestion.id}`, {
      ethan_question: ethanQuestion,
    });
  }
  async ethanPostStream(
    url: string,
    body: any,
    user: User,
    chatId?: number,
    attachments: string[] = [],
  ): Promise<ReadableStream<Uint8Array>> {
    const account = this.getAccount();
    body = {
      ...body,
      thread_id: `${account.id}${user.id}${chatId}`,
      termsheet_metadata: { environment: environment.ethanAssistant.env },
      user_info: {
        email: user.email,
        name: user.name,
      },
      attachments,
    };
    const headers = new Headers({
      'Content-Type': 'application/json',
      'x-api-key': environment.ethanAssistant.apiKey,
      'termsheet-account-id': account.id,
    });
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
    return response.body;
  }

  async ethanSendDocument(name: string, url: string, user: User, chatId?: number) {
    const account = this.getAccount();
    const headers = new Headers({
      'Content-Type': 'application/json',
      'x-api-key': environment.ethanAssistant.apiKey,
      'termsheet-account-id': account.id,
    });
    const body = {
      thread_id: `${account.id}${user.id}${chatId}`,
      documents: [
        {
          name: name,
          url: url,
          last_updated: new Date().toISOString(),
        },
      ],
    };
    const response = await fetch(`${environment.ethanAssistant.url}/assistant/send_document`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body),
    });
    return response;
  }
}
