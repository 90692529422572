<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="flex flex-col">
  <div class="flex flex-col m-3" *ngIf="!readonly">
    <form [formGroup]="commentForm" #formDirective="ngForm" (ngSubmit)="saveComment(formDirective)">
      <div class="flex items-start">
        <div class="w-full relative">
          <quill-editor
            class="w-full"
            [formControlName]="'body'"
            [modules]="quillModules"
            [mentionConfig]="mentionConfig"
          ></quill-editor>
        </div>
      </div>
      <div class="flex items-center mt-1 ml-auto justify-end">
        <button
          class="send-button"
          mat-flat-button
          [disabled]="commentForm.pristine || commentForm.invalid || isPosting"
          type="submit"
          [color]="'primary'"
        >
          <span>Post Comment</span>
        </button>
      </div>
    </form>
  </div>

  <div class="max-h-120 overflow-y-auto">
    <div class="flex flex-col m-3">
      <ng-container *ngFor="let comment of comments; trackBy: trackByFn">
        <ng-container
          [ngTemplateOutlet]="commentTemplate"
          [ngTemplateOutletContext]="{ comment: comment }"
        ></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #commentTemplate let-comment="comment">
  <div class="flex mt-2 w-full comment-container">
    <div class="flex flex-col w-full" *ngIf="!comment.editing">
      <div class="flex flex-row items-end mb-2">
        <img class="w-8 h-8 rounded-full mr-3" src="{{ comment.user.avatar_url }}" />
        <b>{{ comment.user.full_name }}</b>
        <div class="text-secondary ml-1" *ngIf="comment.commentable_type && comment.commentable_type != 'Project'">
          • {{ comment.commentable_name }}
        </div>
      </div>
      <div
        class="border border-gray-200 rounded-md p-2"
        [innerHTML]="formatMessage(comment?.body) | linkify | htmlSanitizer"
      ></div>
      <div class="flex items-center text-sm text-secondary mt-2 comment-controls" *ngIf="!comment.replying">
        <a class="hover:underline" (click)="replyToComment(comment)">Reply</a>
        <ng-container *ngIf="user">
          <span class="mx-1"> • </span>
          <a class="hover:underline" (click)="editComment(comment)" *ngIf="user.id == comment.user.id">Edit</a>
          <span class="mx-1"> • </span>
          <a class="hover:underline" (click)="deleteComment(comment)" *ngIf="user.id == comment.user.id">Delete</a>
        </ng-container>
        <span class="ml-auto">{{ comment.created_at | date: 'MMM dd, y' }}</span>
      </div>

      <ng-container *ngIf="comment.replying">
        <form [formGroup]="replyCommentForm">
          <div class="flex items-center text-sm text-secondary mt-2 w-full flex-col">
            <div class="flex w-full">
              <div class="w-full relative">
                <quill-editor
                  class="w-full"
                  [formControlName]="'body'"
                  [modules]="quillModules"
                  [mentionConfig]="mentionConfig"
                ></quill-editor>
              </div>
            </div>
            <div class="flex items-center mt-1 ml-auto justify-end">
              <button class="cancel-button mr-1" mat-flat-button (click)="cancelCommentReply(comment)">
                <span>Cancel</span>
              </button>
              <button
                class="send-button"
                mat-flat-button
                type="submit"
                [color]="'primary'"
                (click)="saveCommentReply()"
              >
                <span>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ng-container>
    </div>

    <div class="flex flex-col mt-2px w-full" *ngIf="comment.editing">
      <form [formGroup]="editCommentForm" #editformDirective="ngForm" (ngSubmit)="updateComment(editformDirective)">
        <div class="flex items-start">
          <div class="w-full relative">
            <quill-editor
              class="w-full"
              [formControlName]="'body'"
              [modules]="quillModules"
              [mentionConfig]="mentionConfig"
            ></quill-editor>
          </div>
        </div>
        <div class="flex items-center mt-1 ml-auto justify-end">
          <button class="cancel-button mr-1" mat-flat-button (click)="cancelEditComment(comment)">
            <span>Cancel</span>
          </button>
          <button
            class="send-button"
            mat-flat-button
            [disabled]="editCommentForm.pristine || editCommentForm.invalid"
            type="submit"
            [color]="'primary'"
          >
            <span>Save</span>
          </button>
        </div>
      </form>
    </div>
  </div>
  <ng-container *ngFor="let childComment of comment.children; trackBy: trackByFn">
    <div class="flex ml-6 flex-col">
      <ng-container
        [ngTemplateOutlet]="commentTemplate"
        [ngTemplateOutletContext]="{ comment: childComment }"
      ></ng-container>
    </div>
  </ng-container>
</ng-template>
