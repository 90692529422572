import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { cloneDeep, cloneDeepWith, noop } from 'lodash';
import { delay, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  Task,
  TaskList,
  TaskModuleRelatedToType,
  WorkflowState,
  TaskType,
  Todo,
  TaskViewType,
  TaskView,
  TaskQueryFilter,
  TaskListTemplate,
  TaskRelatedToModule,
} from '@tasks/tasks.types';
import { TaskUtils } from '../tasks.utils';
import { TaskActions } from './actions';
import { Approval } from '@shared/modules/approvals/approvals.types';
import { User } from '@core/models';
import { Contact } from 'app/modules/contacts/contacts.types';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { LegacyTasksTabService } from '@shared/modules/legacy-tasks/services/tasks.service';
import { TaskListService } from '@tasks/services/task-list.service';
import { TasksService } from '@tasks/services/tasks.service';
import { RelatedToType } from '@shared/enums';
import { SavedViewsActions } from '@state/saved-view/action';
import { SavedViewsState } from '@state/saved-view/state';
import { AccountService } from '@core/services';
import { TaskListTemplateService } from '@tasks/services/task-list-template.service';
import { query } from '@angular/animations';
import moment from 'moment';
import { TasksBaseUrlService } from '@tasks/services/tasks-base-url.service';
import { TsTableService } from 'tsui';
import { defaultTaskTableFields } from '@shared/modules/legacy-tasks/list/list.constants';
import { Observable } from 'rxjs';

export interface TaskStateModel {
  apiBaseUrl: string;
  relatedToType: TaskRelatedToModule;
  relatedToTypeId: number;
  customObjectName?: string;
  viewType: TaskViewType;
  tasks: Task[];
  queryFilters?: TaskQueryFilter;
  selectedTasks?: Partial<Task>[];
  taskView: TaskView; // TO MJR: Change to TaskView
  previousTaskViewVersion?: TaskView;
  taskLists: TaskList[];
  taskTemplateId?: number;
  taskListTemplate?: TaskListTemplate;
  sidebarItems: TaskView[];
  currentTask: Task;
  pagination?: {
    page: number;
    pageSize: number;
    totalItems: number;
  };
  taskCounters: {
    [key: number]: { currentTaskCount: number; currentTaskCompleteCount: number };
  };
}

@State<TaskStateModel>({
  name: new StateToken<TaskStateModel>('taskState'),
  defaults: {
    relatedToType: null,
    relatedToTypeId: null,
    viewType: TaskViewType.List,
    tasks: [],
    selectedTasks: [],
    queryFilters: null,
    taskView: null,
    previousTaskViewVersion: null,
    taskLists: [],
    sidebarItems: [],
    taskTemplateId: null,
    taskListTemplate: null,
    apiBaseUrl: '',
    currentTask: null,
    pagination: {
      page: 1,
      pageSize: 10,
      totalItems: 0,
    },
    taskCounters: {},
  },
})
@Injectable()
export class TaskState {
  constructor(
    private taskBaseUrlService: TasksBaseUrlService,
    private taskService: TasksService,
    private taskListsService: TaskListService,
    private taskListTemplateService: TaskListTemplateService,
    private accountService: AccountService,
    private store: Store,
  ) {}

  get isAggregatedTasks() {
    return this.store.selectSnapshot(TaskState.getTaskRelatedTo).relatedToType === TaskRelatedToModule.AggregatedTasks;
  }

  get isTaskListTemplate() {
    return (
      (this.store.selectSnapshot(TaskState.getTaskRelatedTo).relatedToType as string) ===
      TaskRelatedToModule.TaskListTemplate
    );
  }

  @Selector()
  static getTaskCounters(state: TaskStateModel): {
    [key: number]: { currentTaskCount: number; currentTaskCompleteCount: number };
  } {
    return state.taskCounters;
  }

  @Action(TaskActions.SetRelatedTo)
  setRelatedToType(
    context: StateContext<TaskStateModel>,
    { relatedToType, relatedToTypeId, customObjectName }: TaskStateModel,
  ) {
    const apiBaseUrl = this.taskBaseUrlService.getTasksUrl(relatedToType, relatedToTypeId);
    if (relatedToType !== context.getState().relatedToType || relatedToTypeId !== context.getState().relatedToTypeId) {
      context.patchState({
        tasks: [],
        taskView: null,
        previousTaskViewVersion: null,
        currentTask: null,
        selectedTasks: [],
        taskLists: [],
        sidebarItems: [],
        relatedToType,
        relatedToTypeId,
        taskListTemplate: null,
        taskTemplateId: relatedToType === 'TaskListTemplate' ? relatedToTypeId : null,
        apiBaseUrl,
        customObjectName,
        taskCounters: {},
      });
    }
  }

  @Action(TaskActions.SetViewType)
  setViewType(context: StateContext<TaskStateModel>, { viewType }: { viewType: TaskViewType }) {
    context.patchState({ viewType });
  }

  @Selector()
  static getTaskRelatedTo(state: TaskStateModel): {
    relatedToType: TaskRelatedToModule;
    relatedToTypeId: number;
    customObjectName?: string;
  } {
    return cloneDeep({
      relatedToType: state.relatedToType,
      relatedToTypeId: state.relatedToTypeId,
      customObjectName: state.customObjectName,
    });
  }

  @Selector()
  static getApiBaseUrl(state: TaskStateModel): string {
    return state.apiBaseUrl;
  }

  @Selector()
  static getViewType(state: TaskStateModel): TaskViewType {
    return state.viewType;
  }

  @Selector()
  static getPagination(state: TaskStateModel): { page: number; pageSize: number; totalItems: number } {
    return state?.pagination;
  }

  @Selector()
  static getQueryFilters(state: TaskStateModel): TaskQueryFilter {
    return state.queryFilters;
  }

  static getTaskById(taskId: number) {
    return createSelector([TaskState], (state: TaskStateModel) => TaskUtils.deepFindTaskById(state.tasks, taskId));
  }

  // =========================
  // TASK

  @Selector()
  static getCurrentTask(state: TaskStateModel): Task {
    return state?.currentTask;
  }

  @Action(TaskActions.SetPage)
  setCurrentPage(context: StateContext<TaskStateModel>, { page }: { page: number }) {
    context.patchState({ pagination: { ...context.getState().pagination, page } });
  }

  @Action(TaskActions.GetTaskDetail, { cancelUncompleted: true })
  getTaskById(context: StateContext<TaskStateModel>, { taskListId, taskId }: { taskListId: number; taskId: number }) {
    // TODO MJR TsRxjsOperators.handle404toNotFound(this.router),
    return this.taskService.getTaskDetailById(taskListId, taskId).pipe(
      tap((task) => {
        task.children = TaskUtils.deepSortTasksByRank(task.children);
        task.todos = task.todos?.sort((a, b) => a.rank - b.rank);
        context.patchState({ currentTask: task });
      }),
    );
  }

  @Action(TaskActions.DuplicateTask)
  duplicateTask(
    context: StateContext<TaskStateModel>,
    { taskListId, task, templateId }: { taskListId: number; task: Task; templateId: number },
  ) {
    return this.taskService.duplicateTask(taskListId, templateId, task).pipe(
      switchMap(() => context.dispatch(new TaskActions.RecalculateTaskListDates(taskListId))),
      this.updateCount(context),
    );
  }

  @Action(TaskActions.ClearCurrentTask)
  clearCurrentTask(context: StateContext<TaskStateModel>) {
    context.patchState({ currentTask: null });
  }

  @Action(TaskActions.CreateTask)
  createTask(
    context: StateContext<TaskStateModel>,
    { model }: { model: { taskType: TaskType; parentId?: number; rank?: number } },
  ) {
    let currentTasks = cloneDeep(context.getState().tasks);
    const currentTaskView = cloneDeep(context.getState().taskView);

    const task = {
      task_type: model.taskType,
      task_list_id: currentTaskView.task_list_id || currentTaskView.id,
      rank: model.rank || 0,
      parent_id: model.parentId,
    };

    return this.taskService.createTask(task.task_list_id, task).pipe(
      tap((newTask: Task) => {
        if (newTask.parent_id) {
          const parentTask = TaskUtils.deepFindTaskById(currentTasks, newTask.parent_id);
          parentTask.children = [...parentTask.children, newTask];
          currentTasks = TaskUtils.deepUpdateTaskById(currentTasks, parentTask);
        } else {
          currentTasks.push(newTask);
        }

        const newState = {
          tasks: currentTasks,
          currentTask: newTask,
        };

        context.patchState(newState);
      }),
      this.updateCount(context),
    );
  }

  @Action(TaskActions.CreateSubTask)
  createSubTask(context: StateContext<TaskStateModel>) {
    const nextCurrentTaskRank = () => {
      const currentTask = context.getState().currentTask;
      if (!currentTask.children || currentTask.children.length === 0) {
        return 1;
      }

      return Math.max(...currentTask.children.map((child) => child.rank)) + 1;
    };

    const currentTask = { ...context.getState().currentTask };

    let subtask = {
      task_type: currentTask.task_type,
      task_list_id: currentTask.task_list_id,
      rank: nextCurrentTaskRank(),
      parent_id: currentTask.id,
    };

    return this.taskService.createTask(currentTask.task_list_id, subtask).pipe(
      tap((task) => {
        currentTask.children = [...currentTask.children, task];
        const tasks = TaskUtils.deepUpdateTaskById(context.getState().tasks, currentTask);
        context.patchState({ currentTask, tasks });
      }),
      this.updateCount(context),
    );
  }

  @Action(TaskActions.UpdateTask)
  updateTask(
    context: StateContext<TaskStateModel>,
    { task, templateId, recalculateTaskList }: { task: Task; templateId: number; recalculateTaskList: boolean },
  ) {
    // format all date fields to MM-DD-YYYY
    Object.keys(task).forEach((key) => {
      if (['start_date', 'due_date', 'completed_at', 'start', 'end_date', 'end'].includes(key)) {
        task[key] = task[key] ? moment(task[key]).format('YYYY-MM-DD') : null;
      }
    });

    return this.taskService.updateTaskById(task, templateId)?.pipe(
      tap((task) => {
        const state = context.getState();
        const currentTask = state.currentTask;
        const prevTaskVersion = TaskUtils.deepFindTaskById(state.tasks, task.id);

        task.children = TaskUtils.deepSortTasksByRank(task.children);
        task.todos = task.todos?.sort((a, b) => a.rank - b.rank);

        const newTasksArray = cloneDeepWith(state.tasks, (value) => {
          if (value?.id === task.id && value?.task_type === task.task_type) {
            return task;
          }
        });

        const newState = {
          tasks: newTasksArray,
        };

        if (task.parent_id === currentTask?.id) {
          currentTask.children = TaskUtils.deepUpdateTaskById(currentTask.children, task);
          newState['currentTask'] = currentTask;
        }

        if (task.id === currentTask?.id) {
          newState['currentTask'] = task;
        }

        context.patchState(newState);
        if (!prevTaskVersion) {
          return;
        }

        const isAggregatedTask = state.relatedToType === TaskRelatedToModule.AggregatedTasks;
        const hasPredecessorsChanged = TaskUtils.hasObjectChanged(prevTaskVersion.predecessors, task.predecessors);

        if (
          recalculateTaskList &&
          !isAggregatedTask &&
          (prevTaskVersion?.start_date !== task.start_date ||
            prevTaskVersion?.due_date !== task.due_date ||
            prevTaskVersion?.completed_at !== task.completed_at ||
            hasPredecessorsChanged)
        ) {
          context.dispatch(new TaskActions.RecalculateTaskListDates(task.task_list_id));
        }
      }),
      this.updateCount(context),
    );
  }

  @Action(TaskActions.UnlinkTask)
  unlinktask(context: StateContext<TaskStateModel>, { task }: { task: Task }) {
    return this.taskService.unlinkTask(task.id).pipe(tap(() => {}));
  }

  @Action(TaskActions.CreateTodo)
  createTodo(context: StateContext<TaskStateModel>) {
    const currentTask = cloneDeep(context.getState().currentTask);

    let rank = 0;
    if (currentTask.todos?.length > 0) {
      rank = currentTask.todos.length - 1;
    }

    let todo = {
      title: '',
      done: false,
      editing: true,
      rank: rank,
    };

    currentTask.todos?.push(todo);

    return this.taskService.updateTaskById(currentTask, null).pipe(
      tap((task) => {
        task.children = TaskUtils.deepSortTasksByRank(task.children);
        const tasks = TaskUtils.deepUpdateTaskById(cloneDeep(context.getState().tasks), task);
        context.patchState({ currentTask: task, tasks });
      }),
    );
  }

  @Action(TaskActions.DeleteTodo)
  deleteTodo(context: StateContext<TaskStateModel>, { task, todo }: { task: Task; todo: Todo }) {
    task.todos = task.todos.map((t) => {
      if (t.id === todo.id) {
        todo['_destroy'] = true;
        return todo;
      }
      return t;
    });

    context.dispatch(new TaskActions.UpdateTask(task));
  }

  @Action(TaskActions.AddApprover)
  addApprover(context: StateContext<TaskStateModel>, { task, approval }: { task: Task; approval: Approval }) {
    const currentTask = cloneDeep(context.getState().currentTask);

    return this.taskService.addApproverToTask(task.task_list_id, task.id, approval).pipe(
      tap((approval) => {
        if (!task.approvals) {
          task.approvals = [];
        }

        task.approvals.push(approval);

        let newState;
        if (task.id === currentTask?.id) {
          newState = { currentTask: task };
        }

        newState = { ...newState, tasks: TaskUtils.deepUpdateTaskById(context.getState().tasks, task) };
        context.patchState(newState);
      }),
    );
  }

  @Action(TaskActions.RemoveApprover)
  removeApprover(context: StateContext<TaskStateModel>, { task, approval }: { task: Task; approval: Approval }) {
    const currentTask = cloneDeep(context.getState().currentTask);
    return this.taskService.removeApproverFromTask(task.task_list_id, task.id, approval).pipe(
      tap(() => {
        task.approvals = task.approvals.filter((a) => a.id !== approval.id);
        let newState;
        if (task.id === currentTask?.id) {
          newState = { currentTask: task };
        }

        newState = { ...newState, tasks: TaskUtils.deepUpdateTaskById(context.getState().tasks, task) };
        context.patchState(newState);
      }),
    );
  }

  @Action(TaskActions.AddFollower)
  addFollower(context: StateContext<TaskStateModel>, { task, user }: { task: Task; user: User }) {
    task.followings.unshift(user);

    const newTask = { ...task, following_user_ids: task.followings.map((user) => user.id) };
    context.dispatch(new TaskActions.UpdateTask(newTask));
  }

  @Action(TaskActions.RemoveFollower)
  removeFollower(context: StateContext<TaskStateModel>, { task, user }: { task: Task; user: User }) {
    task.followings.splice(
      task.followings.findIndex((item) => item.id === user.id),
      1,
    );

    const newTask = { ...task, following_user_ids: task.followings.map((user) => user.id) };
    context.dispatch(new TaskActions.UpdateTask(newTask));
  }

  @Action(TaskActions.AddContact)
  addContact(context: StateContext<TaskStateModel>, { task, contact }: { task: Task; contact: Contact }) {
    task.contacts.unshift(contact);

    const newTask = { ...task, contact_ids: task.contacts.map((user) => user.id) };
    context.dispatch(new TaskActions.UpdateTask(newTask));
  }

  @Action(TaskActions.RemoveContact)
  removeContact(context: StateContext<TaskStateModel>, { task, contact }: { task: Task; contact: Contact }) {
    task.contacts.splice(
      task.contacts.findIndex((item) => item.id === contact.id),
      1,
    );
    const newTask = { ...task, contact_ids: task.contacts.map((contact) => contact.id) };
    context.dispatch(new TaskActions.UpdateTask(newTask));
  }

  @Action(TaskActions.SetTaskView)
  setTaskView(context: StateContext<TaskStateModel>, { taskView }: { taskView: TaskView }) {
    if (!taskView) {
      return;
    }
    const updateProjectAndPropertyIdFieldsToTile = (fields) => {
      return fields.map((f) => {
        if (f === 'project_id') {
          f = f.replace('_id', '_title');
        }
        if (f === 'property_id') {
          f = f.replace('_id', '_name');
        }
        return f;
      });
    };
    const state = { taskView };
    taskView.definition =
      typeof taskView?.definition === 'string' ? JSON.parse(taskView.definition || '{}') : taskView.definition;

    if (taskView.type === 'SavedView') {
      if (taskView.definition['fields']) {
        taskView.definition.table.fields = updateProjectAndPropertyIdFieldsToTile(taskView.definition['fields']);
      } else {
        taskView.definition['fields'] = updateProjectAndPropertyIdFieldsToTile(
          taskView.definition.table.fields || defaultTaskTableFields,
        );
      }

      taskView.definition.filters = taskView.saved_filter.filters;
    }

    if (taskView?.id !== context.getState().previousTaskViewVersion?.id) {
      state['previousTaskViewVersion'] = taskView;
    }

    context.patchState(state);
  }

  @Action(TaskActions.UndoTaskViewChanges)
  undoTaskViewChanges(context: StateContext<TaskStateModel>) {
    const previousTaskViewVersion = context.getState().previousTaskViewVersion;
    if (previousTaskViewVersion) {
      context.patchState({ taskView: cloneDeep(previousTaskViewVersion) });
    }
  }

  @Selector()
  static getSidebarItems(state: TaskStateModel): any[] {
    //TODO MJR: Add type
    return state?.sidebarItems;
  }

  @Selector()
  static getVisibleSidebarItems(state: TaskStateModel): any[] {
    return state?.sidebarItems?.filter((x) => x.visibility !== 'private_visibility');
  }

  @Action(TaskActions.UpdateTaskView)
  updateTaskView(context: StateContext<TaskStateModel>, { taskView }: { taskView: TaskView }) {
    const currentTaskView = context.getState().taskView;
    if (taskView.type === 'TaskList' || taskView.type === 'TaskListTemplate') {
      context.patchState({ previousTaskViewVersion: taskView });
      return context.dispatch(new TaskActions.UpdateTaskList(taskView));
    } else if (taskView.type === 'SavedView') {
      // "convert" taskView to SavedView/SavedFilter
      const savedView = {
        ...taskView,
        definition: JSON.stringify({
          table: {
            ...(taskView.definition?.table ?? {}),
            fields: taskView.definition.fields,
            groupBy: taskView.definition?.table?.groupBy,
          },
        }),
        saved_filter: {
          ...taskView.saved_filter,
          filters: taskView.definition.filters,
        },
      };

      return this.store.dispatch(new SavedViewsActions.UpdateView(savedView)).pipe(
        switchMap(() => this.accountService.updateFilter(savedView.saved_filter)),
        tap(() => {
          if (savedView.id === currentTaskView.id) {
            context.patchState({ previousTaskViewVersion: savedView });
          }

          context.dispatch(new TaskActions.GetSidebarItems());
        }),
      );
    }
  }

  @Action(TaskActions.GetSidebarItems)
  getSidebarItems(context: StateContext<TaskStateModel>) {
    // TODO MJR: Create a TaskSidebarService and move it to there.
    const relatedToType = context.getState().relatedToType;
    if (this.isAggregatedTasks) {
      return this.store.dispatch(new SavedViewsActions.LoadViews()).pipe(
        tap(() => {
          const savedviews = this.store.selectSnapshot(SavedViewsState.getViews);
          const sidebarItems = savedviews
            .filter((sv) => sv.related_to_type === RelatedToType.AggregatedTasks)
            .map((sv) => ({ ...sv, type: 'SavedView' }));
          context.patchState({ sidebarItems: sidebarItems as TaskView[], taskLists: sidebarItems as TaskView[] });
        }),
      );
    } else if (relatedToType === 'TaskListTemplate') {
      const relatedToId = context.getState().relatedToTypeId;
      return this.taskListTemplateService.getTaskListTemplateById(relatedToId).pipe(
        tap((taskListTemplate) => {
          const taskView = {
            ...taskListTemplate,
            ...taskListTemplate.task_list,
            type: 'TaskListTemplate',
            task_list_template_id: relatedToId,
          } as TaskView;
          context.patchState({
            sidebarItems: [taskView],
            taskLists: [taskView],
            taskCounters: {
              [taskListTemplate.task_list_id]: {
                currentTaskCount: taskListTemplate.task_list?.task_count ?? 0,
                currentTaskCompleteCount: taskListTemplate.task_list?.complete_task_count ?? 0,
              },
            },
          });
        }),
      );
    } else {
      return this.taskListsService.getTaskLists().pipe(
        tap((taskLists) => {
          context.patchState({
            sidebarItems: taskLists.map((tl) => ({ ...tl, type: 'TaskList' })),
            taskCounters: taskLists.reduce((p, c) => {
              return {
                ...p,
                [c.id]: { currentTaskCount: c.task_count, currentTaskCompleteCount: c.complete_task_count },
              };
            }, {}),
          });
        }),
      );
    }
  }

  @Selector()
  static getSelectedTasks(state: TaskStateModel): Partial<Task>[] {
    return state?.selectedTasks;
  }

  @Action(TaskActions.SetSelectedTasks)
  setSelectedTasks(context: StateContext<TaskStateModel>, { tasks }: { tasks: Task[] }) {
    context.patchState({ selectedTasks: tasks });
  }

  @Action(TaskActions.BulkUpdateSelectedTasks)
  bulkUpdateSelectedTasks(context: StateContext<TaskStateModel>, { newTaskParams }: { newTaskParams: Partial<Task> }) {
    const selectedTasks = cloneDeep(context.getState().selectedTasks);

    const bulkParams = selectedTasks.map((task) => {
      return { id: task.id, ...newTaskParams };
    });

    return this.taskService.bulkUpdateTasks(bulkParams).pipe(
      tap((res) => {
        const currentTasks = cloneDeep(context.getState().tasks);
        const newTasks = res.reduce((acc, task) => {
          return TaskUtils.deepUpdateTaskById(acc, task);
        }, currentTasks);

        context.patchState({ tasks: newTasks });
      }),
    );
  }

  @Action(TaskActions.BulkDeleteSelectedTasks)
  bulkDeleteSelectedTasks(context: StateContext<TaskStateModel>) {
    const selectedTasks = cloneDeep(context.getState().selectedTasks);
    const totalDeletedTasks = TaskUtils.flattenTasks(selectedTasks).length;
    const bulkParams = selectedTasks.map((task) => {
      return { id: task.id };
    });

    return this.taskService.bulkDeleteTasks(bulkParams).pipe(
      tap((res) => {
        const currentTasks = cloneDeep(context.getState().tasks);
        const deletedIds = selectedTasks.map((task) => +task.id);
        const newTasks = TaskUtils.deepTaskDeleteById(currentTasks, deletedIds);
        let newState: Partial<TaskStateModel> = { tasks: newTasks };

        const currentTask = context.getState().currentTask;
        if (currentTask && deletedIds.includes(currentTask.id)) {
          newState['currentTask'] = null;
        }
        context.patchState(newState);
      }),
      this.updateCount(context),
    );
  }

  @Action(TaskActions.BulkDuplicateSelectedTasks)
  bulkDuplicateSelectedTasks(context: StateContext<TaskStateModel>) {
    const selectedTasks = cloneDeep(context.getState().selectedTasks);
    const totalDuplicatedTasks = TaskUtils.flattenTasks(selectedTasks).length;

    const bulkParams = selectedTasks.map((task) => {
      return { id: task.id };
    });
    const currentView = context.getState().taskView;
    return this.taskService.bulkDuplicateTasks(bulkParams).pipe(
      mergeMap(() => {
        const queryFilters = context.getState().queryFilters;
        if (queryFilters) {
          return context.dispatch(new TaskActions.FilterTasks(queryFilters, false));
        } else {
          return context.dispatch(new TaskActions.GetTasks(currentView.id, {}));
        }
      }),
      this.updateCount(context),
    );
  }

  // =========================
  // TASK LIST

  @Selector()
  static getTaskView(state: TaskStateModel): TaskView {
    const taskView = state?.taskView;
    taskView.definition =
      typeof taskView?.definition === 'string' ? JSON.parse(taskView.definition || '{}') : taskView.definition;
    return taskView;
  }

  @Selector()
  static getTaskLists(state: TaskStateModel): TaskList[] {
    return state?.taskLists;
  }

  @Action(TaskActions.CreateTaskList)
  createTaskList(context: StateContext<TaskStateModel>, { taskList }: { taskList: TaskList }) {
    return this.taskListsService.createTaskList(taskList).pipe(
      tap((newTaskList) => {
        context.setState(
          patch({
            taskLists: append([newTaskList]),
          }),
        );
      }),
      tap(() => {
        context.dispatch(new TaskActions.GetSidebarItems());
      }),
    );
  }

  @Action(TaskActions.GetTaskListById)
  getTaskListById(
    context: StateContext<TaskStateModel>,
    { taskListId, templateId, includeTasks }: { taskListId: number; templateId: number; includeTasks: any },
  ) {
    return this.taskListsService.getTaskListById(taskListId.toString(), templateId, includeTasks).pipe(
      tap((taskList) => {
        if (includeTasks && taskList.tasks) {
          context.patchState({ tasks: taskList.tasks, taskView: taskList as TaskView });
        } else {
          context.dispatch(new TaskActions.GetTasks(taskList.id, {}));
          context.patchState({ taskView: taskList as TaskView, queryFilters: null });
        }
      }),
    );
  }

  @Action(TaskActions.GetTaskLists)
  getTaskLists(context: StateContext<TaskStateModel>) {
    return this.taskListsService.getTaskLists().pipe(
      tap((taskLists) => {
        context.patchState({ taskLists: taskLists });
      }),
    );
  }

  @Action(TaskActions.DeleteTaskList)
  deleteTaskList(context: StateContext<TaskStateModel>, { taskListId }: { taskListId: number }) {
    return this.taskListsService.deleteTaskList(taskListId).pipe(
      tap(() => {
        context.setState(
          patch({
            taskLists: removeItem<TaskList>((taskList) => taskList.id === taskListId),
            sidebarItems: removeItem<TaskView>((taskList) => taskList.id === taskListId),
          }),
        );
      }),
    );
  }

  @Action(TaskActions.SetTaskList)
  setTaskList(context: StateContext<TaskStateModel>, { taskList }: { taskList: TaskList }) {
    context.patchState({ taskView: taskList as TaskView });
  }

  @Action(TaskActions.UpdateTaskList)
  updateTaskList(context: StateContext<TaskStateModel>, { taskList }: { taskList: TaskList }) {
    return this.taskListsService.updateTaskList(taskList).pipe(
      tap((taskList) => {
        const taskLists = context.getState().taskLists;
        const sidebarItems = context.getState().sidebarItems;
        const index = taskLists.findIndex((t) => t.id === taskList.id);
        const sidebarItemsIndex = sidebarItems.findIndex((t) => t.id === taskList.id);
        taskLists[index] = taskList;
        sidebarItems[sidebarItemsIndex] = { ...taskList, type: 'TaskList' };
        const state = { sidebarItems: cloneDeep(sidebarItems), taskLists: cloneDeep(taskLists) };
        if (taskList.id === context.getState().taskView.id) {
          state['previousTaskViewVersion'] = taskList;
          state['taskView'] = taskList as TaskView;
        }
        state['taskView'] = { ...state['taskView'], type: 'TaskList' };
        context.patchState(state);
      }),
    );
  }

  @Action(TaskActions.RecalculateTaskListDates, { cancelUncompleted: true })
  recalculateTaskListDates(context: StateContext<TaskStateModel>, { taskListId }: { taskListId: number }) {
    const templateId = context.getState().taskTemplateId;
    return this.taskService.recalculateTaskDates({ id: taskListId }, templateId).pipe(
      mergeMap(() => {
        const state = context.getState();
        if (state.currentTask) {
          context.dispatch(new TaskActions.GetTaskDetail(state.currentTask.task_list_id, state.currentTask.id));
        }

        if (state.queryFilters) {
          return context.dispatch(new TaskActions.FilterTasks(state.queryFilters, false));
        } else {
          return context.dispatch(new TaskActions.GetTasks(taskListId, {}));
        }
      }),
    );
  }

  @Action(TaskActions.ClearTaskList)
  clearCurrentTaskList(context: StateContext<TaskStateModel>) {
    context.patchState({
      currentTask: null,
      taskView: null,
      previousTaskViewVersion: null,
      tasks: [],
      queryFilters: null,
    });
  }

  // =========================
  // TASKS
  @Selector()
  static getTasks(state: TaskStateModel): Task[] {
    return state?.tasks;
  }

  @Action(TaskActions.GetTasks, { cancelUncompleted: true })
  getTasks(context: StateContext<TaskStateModel>, { taskListId, filters }: { taskListId: number; filters: any }) {
    const templateId = context.getState().taskTemplateId;
    const currentTaskView = context.getState().taskView;
    return this.taskService
      .getTasks(taskListId, templateId, {
        groupBy: currentTaskView.definition?.table?.groupBy || '',
        sort: currentTaskView.definition?.table?.sort || '',
      })
      .pipe(
        tap((tasks) => {
          const transformedTasks = tasks.map((t) => TaskUtils.taskCustomFieldValuesAsAttributes(t));
          const newState = { tasks: transformedTasks, queryFilters: null };
          const currentTask = context.getState().currentTask;
          if (currentTask) {
            newState['currentTask'] = TaskUtils.deepFindTaskById(transformedTasks, currentTask.id);
          }

          context.patchState(newState);
        }),
      );
  }

  @Action(TaskActions.ReorderColumns, { cancelUncompleted: true })
  reorderColumns(context: StateContext<TaskStateModel>, { columns }: { columns: string[] }) {
    const taskView = context.getState().taskView;
    taskView.definition.fields = columns;
    context.patchState({ taskView });
  }

  @Action(TaskActions.ReorderTasks, { cancelUncompleted: true })
  reorderTasks(
    context: StateContext<TaskStateModel>,
    { taskListId, tasks, tempalteId }: { taskListId: number; tasks: Task[]; tempalteId: number },
  ) {
    return this.taskService.updateTasksOrders({ id: taskListId }, tasks, tempalteId).pipe(
      tap(() => {
        const stateTasks = context.getState().tasks;
        // deep update task rank
        const updateTaskRank = (stateTasks, tasks) => {
          return stateTasks.map((task) => {
            const newTask = tasks.find((t) => t.id === task.id);
            const updatedTask = {
              ...task,
              rank: (newTask || task)?.rank,
            };
            if (updatedTask.children) {
              updatedTask.children = updateTaskRank(updatedTask.children, tasks);
            }
            return updatedTask;
          });
        };

        const newTasksState = updateTaskRank(stateTasks, tasks);
        const sortedTasks = TaskUtils.deepSortTasksByRank(newTasksState);
        context.patchState({ tasks: sortedTasks });
      }),
    );
  }
  re;

  // deletaaction
  @Action(TaskActions.DeleteTask)
  deleteTask(
    context: StateContext<TaskStateModel>,
    { taskListId, taskId, templateId }: { taskListId: number; taskId: number; templateId: number },
  ) {
    return this.taskService.deleteTask(taskListId, templateId, taskId).pipe(
      tap(() => {
        const currentTask = cloneDeep(context.getState().currentTask);
        let deletingCurrentTaskChild = false;

        const filterTask = (arr, id) => {
          return arr.filter((task, index) => {
            if (task.id === id) {
              deletingCurrentTaskChild = task.parent_id === currentTask?.id;
              return false;
            }
            if (task.children) {
              task.children = filterTask(task.children, id);
            }
            return true;
          });
        };

        const state = context.getState();
        const newTasksArray = filterTask(state.tasks, taskId);
        let newState: Partial<TaskStateModel> = { tasks: newTasksArray };

        if (deletingCurrentTaskChild) {
          currentTask.children = filterTask(currentTask?.children, taskId);
          newState['currentTask'] = currentTask;
        }

        context.patchState(newState);
      }),
      this.updateCount(context),
    );
  }

  @Action(TaskActions.FilterTasks)
  filterTasks(context: StateContext<TaskStateModel>, params) {
    const moveTitleToFirst = (arr) => {
      arr = arr.filter((item) => item !== 'title');
      arr.unshift('title');
      return arr;
    };

    const queryFilters = params.queryFilters as TaskQueryFilter;
    const taskView: TaskView = cloneDeep(context.getState().taskView);
    const tempFilter = queryFilters.fjson ? JSON.parse(queryFilters.fjson) : [];
    const groupBy = taskView.definition?.table?.groupBy;

    if (taskView.type !== 'SavedView') {
      tempFilter.push(['task_list_id', 'eq', taskView?.id]);
    }

    let selectFields = taskView.definition?.fields || taskView.definition?.table?.fields;
    if (selectFields) {
      selectFields = moveTitleToFirst(selectFields);
    }

    const filter = {
      ...queryFilters,
      fjson: JSON.stringify(tempFilter),
      sort: queryFilters.sort || [],
      q: queryFilters.q,
      allow_templates: this.isTaskListTemplate,
      page: queryFilters.pagination?.activePage || 1,
      per_page: queryFilters.pagination?.pageSize || (this.isAggregatedTasks ? 20 : 500),
      format: queryFilters.format || undefined,
      select: queryFilters.format && selectFields ? selectFields.join(',') : undefined,
      group: groupBy,
    };

    delete filter.pagination;

    return this.taskService.search(filter).pipe(
      tap((response) => {
        if (response.data?.message) {
          return;
        }
        const transformedTasks = response.tasks.map((t) => TaskUtils.taskCustomFieldValuesAsAttributes(t));
        context.patchState({
          queryFilters: filter,
          tasks: transformedTasks,
          pagination: { page: +filter.page, pageSize: +filter.per_page, totalItems: +response.count },
        });
      }),
    );
  }

  @Action(TaskActions.TaskList.ToggleVisibility)
  toggleVisibility(context: StateContext<TaskStateModel>, { id }: { id: number }) {
    const taskList = context.getState().sidebarItems.find((t) => t.id === id);

    taskList.visibility = taskList.visibility === 'private_visibility' ? 'public_visibility' : 'private_visibility';

    return this.taskListsService.updateTaskList(taskList).pipe(
      tap(() => {
        const taskLists = context.getState().sidebarItems;
        const index = taskLists.findIndex((t) => t.id === taskList.id);
        taskLists[index] = taskList;
        context.patchState({ sidebarItems: [...taskLists] });
      }),
    );
  }

  @Action(TaskActions.UpdateCurrentTaskNums)
  updateCurrentTaskNums(context: StateContext<TaskStateModel>) {
    const currentTask = context.getState().currentTask;
    return this.taskService
      .getTaskDetailById(currentTask.task_list_id, currentTask.id, ['num_comments', 'last_comment'])
      .pipe(
        tap((task) => {
          const tasks = TaskUtils.deepUpdateTaskById(cloneDeep(context.getState().tasks), task);
          context.patchState({ currentTask: { ...currentTask, ...task }, tasks });
        }),
      );
  }

  private updateCount(context: StateContext<TaskStateModel>): (source$: Observable<any>) => Observable<any> {
    return (source$) => {
      return source$.pipe(
        filter(
          () =>
            ![RelatedToType.AggregatedTasks, RelatedToType.Contact, RelatedToType.Company].includes(
              context.getState().taskView.related_to_type as RelatedToType,
            ),
        ),
        switchMap(() =>
          this.taskListsService.getTaskListByIdAndSelect<Pick<TaskList, 'task_count' | 'complete_task_count'>>(
            context.getState().taskView.id,
            ['task_count', 'complete_task_count'],
          ),
        ),
        tap((taskList: TaskList) => {
          const sidebarItems = context.getState().sidebarItems;
          const currentView = context.getState().taskView;
          const taskView = sidebarItems.findIndex((item) => item.id === currentView.id);

          sidebarItems[taskView].task_count = taskList.task_count;

          const taskCounters = { ...context.getState().taskCounters };

          taskCounters[taskList.id] = {
            currentTaskCount: taskList.task_count,
            currentTaskCompleteCount: taskList.complete_task_count,
          };

          context.patchState({
            taskCounters,
            sidebarItems: cloneDeep(sidebarItems),
          });
        }),
      );
    };
  }
}
