import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Injector,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { LookupContract } from '../cross-cutting/models';
import { OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { TsGroupByPipe } from '../cross-cutting/pipes';
import PerfectScrollbar from 'perfect-scrollbar';
import { randomId } from '../cross-cutting/util';

export type TsSelectOptionMetadata = {
  disabled?: boolean;
};

@Component({
  selector: 'ts-select',
  templateUrl: './ts-select.component.html',
  styleUrls: ['./ts-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TsSelectComponent,
    },
  ],
})
export class TsSelectComponent extends OnDestroyMixin implements OnInit, ControlValueAccessor {
  @Input() items: (any & TsSelectOptionMetadata)[];

  @Input() set disabled(value: boolean) {
    this.isDisabled = value;
  }
  @Input() multi = false;

  @Input() tooltip: string;
  @Input() lookupContract: LookupContract;
  @Input() itemKey;
  @Input() itemLabel;
  @Input() hasGroups = false;
  @Input() uiOptions = {
    roundedBorders: true,
    placeholder: 'select',
    searchPlaceholder: 'Search...',
    size: 'medium',
    disabledClass: null,
  };

  @Output() selectionCleared = new EventEmitter<void>();

  @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

  selectedItem: any;
  selectedItems: any[] = [];
  searchFormControl = new FormControl('');
  searchValue$: Observable<string>;
  isPanelOpen = new BehaviorSubject(false);
  ui = {
    splashId: '',
    optionsPanelId: '',
  };

  private isDisabled = false;
  private perfectScrollbar: PerfectScrollbar;

  get disabled() {
    const control = this.injector.get(NgControl);

    return this.isDisabled || control.disabled;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private injector: Injector,
  ) {
    super();

    this.ui = {
      splashId: `x${randomId()}`,
      optionsPanelId: `x${randomId()}`,
    };

    this.filterFn.bind(this);
  }

  ngOnInit(): void {
    this.searchValue$ = this.searchFormControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.perfectScrollbar.update()),
    );
  }

  writeValue(obj: any): void {
    if (this.multi) {
      this.selectedItems = Array.isArray(obj) ? obj : [];
    } else {
      this.selectedItem = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  onOptionSelect(event: Event, option: any): void {
    if (option.disabled) return;

    if (option.isGroup) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.multi) {
      this.handleMultipleSelection(option);
    } else {
      this.handleSingleSelection(option);
    }
  }

  private handleMultipleSelection(option: any) {
    // Multi-select logic
    const index = this.selectedItems.findIndex((item) => item === option[this.itemKey]);

    if (index === -1) {
      this.selectedItems = [...this.selectedItems, option[this.itemKey]]; // Trigger change detection
    } else {
      this.selectedItems = this.selectedItems.filter((item) => option[this.itemKey] !== item); // Remove from selection
    }

    this.onChange(this.selectedItems); // Emit the selected array
  }

  private handleSingleSelection(option: any) {
    if (option[this.itemKey] !== this.selectedItem) {
      this.selectedItem = option[this.itemKey];
      this.onChange(option[this.itemKey]);
    }

    this.closeOptionsPanel();
  }

  getSelectedItemInfo(): { label: string; groupLabel?: string } {
    const field = this.items?.find((x) => x[this.itemKey] === this.selectedItem);

    return {
      label: field?.[this.itemLabel],
      groupLabel: field?.meta?.group?.label,
    };
  }

  @HostListener('document:click', ['$event'])
  documentClickHandler(event: any): void {
    if (this.isDisabled) {
      return;
    }

    if (document.querySelector(`.${this.ui.splashId}`)?.contains(event.target)) {
      this.openOptionsPanel();
      return;
    }

    const opened = document.querySelector(`.${this.ui.optionsPanelId}`);

    if (opened && !opened.contains(event.target)) {
      this.closeOptionsPanel();
    }
  }

  openOptionsPanel($event?: KeyboardEvent): void {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    if (this.disabled) return;

    this.isPanelOpen.next(true);

    this.cdr.detectChanges();

    if (this.searchInput?.nativeElement) {
      this.searchInput.nativeElement.focus();
    } else {
      console.warn('Search input element not available');
    }
    this.initializePerfectScrollbar();
  }

  closeOptionsPanel(): void {
    this.searchFormControl.setValue('', { emitEvent: false });
    this.isPanelOpen.next(false);
  }

  filterFn = (values: any, args: any): any[] => {
    if (!args) {
      return values;
    }

    if (!this.hasGroups) {
      return values.filter((x) => !x.isGroup && x[this.itemLabel]?.toLowerCase().includes(args.toLowerCase()));
    }

    return new TsGroupByPipe().transform(
      values.filter((x) => !x.isGroup && x[this.itemLabel].toLowerCase().includes(args.toLowerCase())),
      true,
    );
  };

  private onChange = (value: any) => {};

  private initializePerfectScrollbar(): void {
    if (!this.perfectScrollbar) {
      this.perfectScrollbar = new PerfectScrollbar('.container__options-panel__list', {
        wheelSpeed: 1,
        minScrollbarLength: 20,
      });
    } else {
      this.perfectScrollbar.update();
    }
  }

  clearSelection(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.selectedItems = [];
    this.onChange(this.selectedItems);
    this.selectionCleared.emit();
  }
}
