import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Parcel, PropertyUseCode, LatLng, BoundingBox, FilterForm, ReverseGeoCode } from './research.types';
import { Cacheable } from 'ts-cacheable';
import { environment } from '@env/environment';

const propertyUseCodesCacheBuster$ = new Subject<void>();

export interface TileServerItem {
  id: string;
  tileId: string;
  label: string;
  layer: string;
  category: string;
  minZoom: number;
  layerType: 'POLYGON' | 'CIRCLE';
}

@Injectable({
  providedIn: 'root',
})
export class ResearchService {
  constructor(private readonly httpClient: HttpClient) {}

  getParcelById(geoId: string): Observable<Parcel> {
    return this.httpClient.get<any>(`parcels/${geoId}`).pipe(
      map((response) => {
        return response.data;
      }),
    );
  }

  getParcelsByBBox(filter: BoundingBox & { filter?: FilterForm }): Observable<Parcel[]> {
    return this.httpClient.post<any>(`parcels/search_by_bbox`, filter).pipe(map((response) => response?.data || []));
  }

  getParcelsByBusinessName(filter: BoundingBox & { businessName?: string }): Observable<Parcel[]> {
    return this.httpClient.post<any>(`parcels/search_by_business_name`, filter).pipe(map(({ data }) => data));
  }

  getParcelByCoords(filter: LatLng): Observable<Parcel> {
    return this.httpClient.get<any>(`parcels/search_by_coords?lat=${filter.latitude}&lng=${filter.longitude}`).pipe(
      map((response) => {
        return response?.data;
      }),
    );
  }

  // this needs to be refactored to use the new API
  getParcelByApn(apn?: string, lat?: number, lng?: number): Observable<Parcel> {
    return this.httpClient.get<any>(`parcels/by_apn?apn=${apn}&latitude=${lat}&longitude=${lng}`).pipe(
      map((response) => {
        if (response?.data) {
          return response.data;
        } else {
          return null;
        }
      }),
    );
  }

  reverseGeocode(apn: string | null, lat: number, lng: number): Observable<ReverseGeoCode> {
    return this.httpClient.get<ReverseGeoCode>(`parcels/reverse_geocode?apn=${apn}&latitude=${lat}&longitude=${lng}`);
  }

  downloadParcelToPdf(apn: string): Observable<any> {
    return this.httpClient.get<any>(`parcels/to_pdf?apn=${apn}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  @Cacheable({ cacheBusterObserver: propertyUseCodesCacheBuster$ })
  getPropertyUseCodes(): Observable<PropertyUseCode[]> {
    return this.httpClient.get<PropertyUseCode[]>('parcels/property_use_codes');
  }

  getGeoData(latLng: LatLng, geography = 'zcta', radiusMiles = 25): Observable<any> {
    return this.httpClient.post<any>('properties/demographics', {
      lat: latLng.latitude,
      lng: latLng.longitude,
      geography: geography,
      radius_miles: radiusMiles,
    });
  }

  getDemographicData(geography, geographyType: string = 'ZIP_CODE'): Observable<any> {
    return this.httpClient
      .get<any>(`parcels/demographics?geography_code=${geography}&geography_type=${geographyType}`)
      .pipe(
        tap((response) => {
          return response;
        }),
      );
  }

  getEsriDemographics(
    latitude,
    longitude,
    distanceType: string = 'RingBuffer',
    radius: any[] = [1, 3, 5],
  ): Observable<any> {
    return this.httpClient
      .get<any>(
        `parcels/esri_demographics?latitude=${latitude}&longitude=${longitude}&distance_type=${distanceType}&radius=${radius}`,
      )
      .pipe(
        tap((response) => {
          return response;
        }),
      );
  }

  getSchoolRatings(latitude, longitude): Observable<any> {
    return this.httpClient.get<any>(`parcels/school_ratings?latitude=${latitude}&longitude=${longitude}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getEmploymentData(postalCode): Observable<any> {
    return this.httpClient.get<any>(`parcels/usa_bls_employment?postal_code=${postalCode}`).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getPermitsData(postalCode): Observable<any> {
    return this.httpClient.get<any>(`parcels/census_permit_survey_post?postal_code=${postalCode}`).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  searchPersonData(contactName, mailingAddress, ownerId): Observable<any> {
    return this.httpClient
      .post<any>(`parcels/search_person`, {
        name: contactName,
        address: mailingAddress,
        owner_id: ownerId,
      })
      .pipe(
        tap((response) => {
          return response;
        }),
      );
  }

  getAvailableLayers(): Observable<TileServerItem[]> {
    return this.httpClient.get<TileServerItem[]>(`${environment.tilesApi.url}/app/api/tiles`).pipe(
      map((response) => {
        return response;
      }),
    );
  }
}
