import { AfterViewInit, Component, ContentChild, EventEmitter, Input, Optional, Output } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseFormDirective } from '../directives/base-form.directive';
import { TsAutocompleteComponent } from '../ts-autocomplete';
import { HTMLInputTypeAttribute } from 'react';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { KeyValuePipe, NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { TSIconModule } from '../ts-icon';

@Component({
  selector: 'ts-input',
  standalone: true,
  templateUrl: './ts-input.component.html',
  styleUrls: ['./ts-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TsInputComponent,
      multi: true,
    },
  ],
  imports: [MatAutocompleteModule, ReactiveFormsModule, NgStyle, TSIconModule, NgClass, NgIf, NgForOf, KeyValuePipe],
})
export class TsInputComponent extends BaseFormDirective implements AfterViewInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() icon: string;
  @Input() autofocus = false;
  @Input() clearable = false;

  @Input() set type(val: HTMLInputTypeAttribute | 'money') {
    if (val === 'money') {
      this.inputType = 'number';
      this.min = 0.0;
      this.max = 10_000_000.0;
      this.step = 0.01;
    }

    this.inputType = val;
  }

  @Input() tabindex = 0;
  @Input() dataCy: string;
  @Input() errorMessages: { [validationErrorKey: string]: string };
  @Input() readonly = false;
  @Input() roundedBorders = true;
  @Input() variant: 'standard' | 'slim' = 'standard';
  @Input() size: 'md' | 'lg' | 'sm' = 'md';
  @Input() autocomplete = 'off';

  @Output() blur = new EventEmitter<void>();

  @ContentChild(TsAutocompleteComponent) autoCompleteComponentRef: TsAutocompleteComponent;
  matAutocompleteComponentRef: MatAutocomplete;

  inputType = 'text';
  min: number;
  max: number;
  step: number;

  constructor(@Optional() protected controlContainer: ControlContainer) {
    super(controlContainer);
  }

  ngAfterViewInit(): void {
    if (this.autoCompleteComponentRef) {
      this.matAutocompleteComponentRef = this.autoCompleteComponentRef.autocomplete;
    }
  }
}
