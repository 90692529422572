import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { SavedView } from 'app/core/models/account.types';
import { AccountService } from 'app/core/services';
import { Store } from '@ngxs/store';
import { Module } from '@core/enums';
import { ObjectsManagerState } from 'app/modules/settings/objects-manager/state/state';

@Injectable({
  providedIn: 'root',
})
export class SavedViewsResolver {
  constructor(private _accountService: AccountService) {}

  resolve(): Observable<SavedView[]> {
    return this._accountService.getSavedViews();
  }
}

@Injectable({
  providedIn: 'root',
})
export class SavedViewResolver {
  constructor(
    private _router: Router,
    private _accountService: AccountService,
    private readonly store: Store,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SavedView> {
    return this._accountService.getSavedViewById(route.paramMap.get('saved_view_id')).pipe(
      switchMap((savedView) => {
        if (savedView.definition === null) {
          let fields = ['id', 'name'];

          if (route.data.module === Module.COMPANIES) {
            fields = ['name', 'phone', 'company_name', 'tag_list'];
          }

          return this._accountService.updateSavedView({
            ...savedView,
            definition: JSON.stringify({
              table: { fields },
            }),
          });
        }

        return of(savedView);
      }),
      // Error here means the requested contact is not available
      catchError((error) => {
        if (route.data.module === Module.CUSTOM_OBJECTS) {
          const objId = route.paramMap.get('id');
          const objName = this.store.selectSnapshot(ObjectsManagerState.get).find((obj) => obj.id === +objId);
          const [firstSavedView] = this._accountService._savedViews.value.filter(
            (x) => x.related_to_type == objName.name,
          );
          const url = `/objects/${objId}/${firstSavedView.id}/table`;
          this._router.navigateByUrl(url);
          return throwError(error);
        }

        // Log the error
        console.error(error);

        // Get the parent url
        const parentUrl = state.url.split('/').slice(0, -1).join('/');

        // Navigate to there
        this._router.navigateByUrl(parentUrl);

        // Throw an error
        return throwError(error);
      }),
    );
  }
}
