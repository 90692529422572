import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivate, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AccountState } from '@state/account/state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectToNewTaskModuleGuard implements CanActivate {
  constructor(
    private readonly store: Store,
    private _router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUrl = state.url;
    const currentAccountId = this.store.selectSnapshot(AccountState.getAccount)?.id;
    if (currentUrl.includes('aggregatedtasks')) {
      this._router.navigate(['new-tasks', 'AggregatedTasks', currentAccountId]);
      return false;
    }

    if (currentUrl.includes('tasks/global')) {
      this._router.navigate(['new-tasks', 'Account', currentAccountId]);
      return false;
    }

    if (currentUrl.includes('projects') && currentUrl.includes('tasks')) {
      this._router.navigate([
        'projects',
        route.params.id,
        route.params.project_id,
        'new-tasks',
        'Project',
        route.params.project_id,
      ]);
      return false;
    }

    if (currentUrl.includes('properties') && currentUrl.includes('tasks')) {
      this._router.navigate(['properties', route.params.id, 'new-tasks', 'Property', route.params.id]);
      return false;
    }

    return true;
  }
}
