import { FileResource } from 'app/shared/modules/folders/folders.types';
import { User } from 'app/core/models/user.types';
import { Project, Property } from 'app/modules/projects/projects.types';
import { FieldDef, ProjectStage, SavedView } from 'app/core/models';
import { RelatedToType } from 'app/shared/enums';
import { Approval } from '../approvals/approvals.types';

export const LAST_TASK_VIEW_ID_ACCESSED_KEY = 'task-module.$RELATED_TO_MODULE$.last-task-view-id-accessed';
export const LAST_TASK_VIEW_TYPE_ACCESSED_KEY = 'task-module.last-task-view-type-accessed';
export const LAST_PER_PAGE_SELECTED = 'tasks-module.last-per-page-selected';

export enum DurationType {
  CalendarDays = 'calendar_days',
  BusinessDays = 'business_days',
}

export type WorkflowState =
  | 'n_a'
  | 'not_started'
  | 'needs_review'
  | 'in_progress'
  | 'in_approval'
  | 'complete'
  | 'sent'
  | 'received'
  | 'rejected'
  | 'quoted'
  | 'competitive'
  | 'accepted';

export type TaskType = 'task' | 'section' | 'key_date';

export const PREDECESSOR_TYPES = Object.freeze([
  { slug: 'FS', name: 'Finish to Start (FS)' },
  { slug: 'FF', name: 'Finish to Finish (FF)' },
  { slug: 'SS', name: 'Start to Start (SS)' },
  { slug: 'SF', name: 'Start to Finish (SF)' },
]);

export const GANTT_PAPER_SIZES = [
  { slug: 'Letter', name: 'Letter' },
  { slug: 'Legal', name: 'Legal' },
  { slug: 'Letter11x17', name: '11x17' },
  { slug: 'A3', name: 'A3 (11.7 x 16.5 in)' },
  { slug: 'A2', name: 'A2 (16.5 x 23.4 in)' },
  { slug: 'A1', name: 'A1 (23.4 x 33.1 in)' },
  { slug: 'A0', name: 'A0 (33.1 x 46.8 in)' },
];

export interface Todo {
  id?: number;
  task_id?: number;
  rank?: number;
  title: string;
  done: boolean;
  created_at?: string;
  editing?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _destroy?: boolean;
}

export interface Predecessor {
  id?: number;
  task_id?: number;
  predecessor_task_id?: number;
  predecessor_type?: string;
  lag?: number;
  lag_type?: DurationType;
  title?: string;
}

export interface Task {
  account_id?: number;
  assigned_user_id?: number;
  assigned_user_name?: string;
  assigned_task_role_id?: number;
  assigned_task_role_name?: string;
  children?: Task[];
  completed?: boolean;
  completed_at?: string | null;
  custom_field_values?: { name: string; val: any }[];
  predecessor_task_ids?: number[];
  predecessor_tasks_count?: number;
  predecessors?: Predecessor[];
  description?: string;
  due_date?: string | null;
  end_date?: string | null;
  expanded?: boolean;
  id?: number;
  is_complete?: boolean;
  num_documents?: number;
  definition?: TaskDefinition;
  object_name?: string | null;
  parent_id?: number;
  parent_name?: string;
  project?: Project;
  property?: Property;
  project_id?: number;
  project_phase?: any | null;
  project_phase_id?: number | null;
  project_stage?: ProjectStage;
  project_stage_id?: any | null;
  property_id?: number;
  rank?: number;
  related_to_id?: number | null;
  related_to_name?: string | null;
  start_date?: string | null;
  task_group_id?: number | null;
  task_list_id?: number;
  task_list_name?: string;
  task_room_id?: number | null;
  task_type?: TaskType;
  title?: string;
  workflow_state?: WorkflowState;
  tags?: string[];
  editing?: boolean;
  file_resources?: FileResource[];
  todos?: Todo[];
  assigned_user?: User;
  contacts?: any;
  contact_ids?: any[];
  percent_complete?: number;
  num_open_children?: number;
  num_children?: number;
  num_closed_children?: number;
  predecessor_tasks?: Task[];
  followings?: any[];
  following_user_ids?: number[];
  isGroup?: boolean;
  duration?: any;
  duration_type?: DurationType;
  predecessor_summary?: string;
  predecessor_codes?: any;
  approvals?: Approval[];
  linked_from_task_id?: number;
  linked_to_task_id?: number;
  linked_from?: any[];
  linked_to?: any[];
  internal_value?: any;
  num_comments?: number;
  num_todos?: number;
  start?: string;
  end?: string;
  allDay?: boolean;
}

export interface TaskList {
  complete_task_count?: number;
  created_at?: string;
  created_by?: string;
  id: number;
  name: string;
  pusher_token?: any;
  rank?: number;
  related_to_id?: number;
  related_to_type?: string;
  task_count?: number;
  task_list_template_id?: number;
  task_list_template_name?: string;
  updated_at?: string;
  visibility?: string;
  definition?: TaskDefinition;
  tasks?: Task[];
}

export type TaskView = (TaskList & SavedView & TaskListTemplate) & {
  type: 'TaskList' | 'SavedView' | 'TaskListTemplate';
};

export interface TaskListResult {
  task_lists: TaskList[];
}

export interface TaskListTemplate {
  account_id?: number;
  associated_project_template_ids?: Array<any>;
  created_at?: string;
  definition?: TaskDefinition;
  id?: number;
  name?: string;
  task_list_id?: number;
  tasks?: Task[];
  updated_at?: string;
  copy_from_task_list_id?: number;
  description?: string;
  applicable_type?: RelatedToType;
  internal_value?: string;
  task_list?: TaskList;
}

export interface TaskStatus {
  key: WorkflowState;
  label: string;
  color?: string;
  icon?: string;
  disabled?: boolean;
}

export type TaskResult = { count: number; offset: number; next: string; previous: string; results: Task[] };

export enum TaskRelatedToModule {
  Project = RelatedToType.Project,
  Property = RelatedToType.Property,
  Account = RelatedToType.Account,
  Contact = RelatedToType.Contact,
  Company = RelatedToType.Company,
  AggregatedTasks = RelatedToType.AggregatedTasks,
  CustomObjectValue = 'CustomObjectValue',
  TaskListTemplate = 'TaskListTemplate',
}

export type TaskModuleRelatedToType =
  | RelatedToType.Project
  | RelatedToType.Property
  | RelatedToType.Account
  | RelatedToType.Contact
  | RelatedToType.Company
  | RelatedToType.CustomObject
  | 'TaskListTemplate';

export enum TaskViewType {
  List = 'list',
  Kanban = 'kanban',
  Gantt = 'gantt',
}
export interface TaskDefinition {
  fields?: string[];
  filters?: string;
  table?: { fields: string[] };
  dashboard?: { fields: string[] };
}
export interface TaskQueryFilter {
  fjson?: string;
  allow_templates?: boolean;
  page?: number;
  per_page?: number;
  format?: string;
  sort?: TaskSort[];
  q?: string;
  filterOptions?: { include_keydates: boolean };
  pagination?: { activePage: number; pageSize: number; totalItems?: number };
  select?: string[];
}

export interface TaskSort {
  field: string;
  order: 'asc' | 'desc';
}
