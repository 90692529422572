import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError, Subject, EMPTY, zip } from 'rxjs';
import { map, switchMap, take, tap, distinctUntilChanged, catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SetUsers } from 'app/state/user/actions';
import { Cacheable, CacheBuster } from 'ts-cacheable';
import {
  Account,
  SavedView,
  ProjectType,
  PropertyType,
  ProjectStage,
  FieldDef,
  InvestmentType,
  Team,
  Vehicle,
  SavedFilter,
  MarketStatus,
  Activity,
  PipelineTemplate,
  AccountIntegration,
  LoanPurpose,
  ProjectPhase,
  CallOutcome,
  MeetingOutcome,
  EngagementActivityType,
  SocialMediaSource,
} from 'app/core/models/account.types';
import { User, UserAssociation, Invitation } from 'app/core/models/user.types';
import { Category, Contact } from 'app/modules/contacts/contacts.types';
import { Permission } from '../models/permission';
import { Role } from '../models';
import { RelatedToType } from 'app/shared/enums';
import { Dictionary } from 'app/shared/models';
import { Company } from 'app/modules/companies/companies.types';
import { TeamsService } from '../../modules/settings/teams/services/teams.service';
import { PaginatedResource } from '@shared/models/paginated-resource';
import { EthanChat } from '@core/models/ethan.types';
import { TsRxjsOperators } from '@shared/Utils/custom-rxjs-operators.utils';
import { FeatureFlag } from '@core/enums/feature-flag';

const usersCacheBuster$ = new Subject<void>();
const teamsCacheBuster$ = new Subject<void>();
const fieldDefsCacheBuster$ = new Subject<void>();
const savedFiltersCacheBuster$ = new Subject<void>();
const invitationsCacheBuster$ = new Subject<void>();
const savedViewsCacheBuster$ = new Subject<void>();
const standardDropdownsCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private _subdomain: string;
  currentAccountSubject = new BehaviorSubject<Account>({} as Account);
  public currentAccount = this.currentAccountSubject.asObservable().pipe(distinctUntilChanged());
  private _hasSubdomain = false;
  private _userAssociations: BehaviorSubject<UserAssociation[] | null>;
  private _users: BehaviorSubject<User[] | null>;
  private _teams: BehaviorSubject<Team[] | null>;
  private _vehicles: BehaviorSubject<Vehicle[] | null>;
  private _invitations: BehaviorSubject<Invitation[] | null>;
  private _accountIntegrationsError: BehaviorSubject<HttpErrorResponse>;
  private _projectTypes: BehaviorSubject<ProjectType[] | null>;
  private _propertyTypes: BehaviorSubject<PropertyType[] | null>;
  private _projectStages: BehaviorSubject<ProjectStage[] | null>;
  private _projectStagesDeleted: BehaviorSubject<ProjectStage[] | null>;
  private _investmentTypes: BehaviorSubject<InvestmentType[] | null>;
  private _fieldDefs: BehaviorSubject<FieldDef[] | null>;
  private _defaultFieldDefs: BehaviorSubject<FieldDef[] | null>;
  private _savedFilters: BehaviorSubject<SavedFilter[] | null>;
  private _marketStatuses: BehaviorSubject<MarketStatus[] | null>;
  private _categories: BehaviorSubject<Category[] | null>;
  private _loanPurposes: BehaviorSubject<LoanPurpose[] | null>;
  _savedViews: BehaviorSubject<SavedView[] | null>;
  private _savedView: BehaviorSubject<SavedView | null>;
  private _projectPhases: BehaviorSubject<ProjectPhase[] | null>;
  private _ethanQuestions: BehaviorSubject<any[] | null>;
  private _callOutcomes: BehaviorSubject<Vehicle[] | null>;
  private _meetingOutcomes: BehaviorSubject<Vehicle[] | null>;
  private _engagementActivityTypes: BehaviorSubject<Vehicle[] | null>;
  private _socialMediaSources: BehaviorSubject<Vehicle[] | null>;
  private _getAccountTagsError$: BehaviorSubject<HttpErrorResponse>;
  private _ethanChats: BehaviorSubject<EthanChat[] | null>;

  constructor(
    private _httpClient: HttpClient,
    @Inject(Store) private store: Store,
  ) {
    this._userAssociations = new BehaviorSubject(null);
    this._users = new BehaviorSubject(null);
    this._accountIntegrationsError = new BehaviorSubject(null);
    this._projectTypes = new BehaviorSubject(null);
    this._propertyTypes = new BehaviorSubject(null);
    this._projectStages = new BehaviorSubject(null);
    this._projectStagesDeleted = new BehaviorSubject(null);
    this._investmentTypes = new BehaviorSubject(null);
    this._fieldDefs = new BehaviorSubject(null);
    this._defaultFieldDefs = new BehaviorSubject(null);
    this._teams = new BehaviorSubject(null);
    this._vehicles = new BehaviorSubject(null);
    this._savedFilters = new BehaviorSubject(null);
    this._invitations = new BehaviorSubject(null);
    this._marketStatuses = new BehaviorSubject(null);
    this._categories = new BehaviorSubject(null);
    this._loanPurposes = new BehaviorSubject(null);
    this._savedViews = new BehaviorSubject(null);
    this._savedView = new BehaviorSubject(null);
    this._projectPhases = new BehaviorSubject(null);
    this._ethanQuestions = new BehaviorSubject(null);
    this._callOutcomes = new BehaviorSubject(null);
    this._meetingOutcomes = new BehaviorSubject(null);
    this._engagementActivityTypes = new BehaviorSubject(null);
    this._socialMediaSources = new BehaviorSubject(null);
    this._getAccountTagsError$ = new BehaviorSubject(null);
    this._ethanChats = new BehaviorSubject(null);
  }

  get userAssociations$(): Observable<UserAssociation[]> {
    return this._userAssociations.asObservable();
  }

  get users$(): Observable<User[]> {
    return this._users.asObservable();
  }

  get invitations$(): Observable<Invitation[]> {
    return this._invitations.asObservable();
  }

  /**
   * Getter for account integrations network error
   */
  get accountIntegrationsError$(): Observable<HttpErrorResponse> {
    return this._accountIntegrationsError.asObservable();
  }

  /**
   * Getter for contacts
   */
  get projectTypes$(): Observable<ProjectType[]> {
    return this._projectTypes.asObservable();
  }

  /**
   * Getter for contacts
   */
  get propertyTypes$(): Observable<PropertyType[]> {
    return this._propertyTypes.asObservable();
  }

  /**
   * Getter for contacts
   */
  get projectStages$(): Observable<ProjectStage[]> {
    return this._projectStages.asObservable();
  }

  get projectPhases$(): Observable<ProjectPhase[]> {
    return this._projectPhases.asObservable();
  }

  get investmentTypes$(): Observable<InvestmentType[]> {
    return this._investmentTypes.asObservable();
  }

  get categories$(): Observable<Category[]> {
    return this._categories.asObservable();
  }

  get teams$(): Observable<Team[]> {
    return this._teams.asObservable();
  }

  get vehicles$(): Observable<Team[]> {
    return this._vehicles.asObservable();
  }

  get fieldDefs$(): Observable<FieldDef[]> {
    return this._fieldDefs.asObservable();
  }

  get defaultFieldDefs$(): Observable<FieldDef[]> {
    return this._defaultFieldDefs.asObservable();
  }

  get savedFilters$(): Observable<SavedFilter[]> {
    return this._savedFilters.asObservable();
  }

  get marketStatuses$(): Observable<MarketStatus[]> {
    return this._marketStatuses.asObservable();
  }

  get loanPurposes$(): Observable<LoanPurpose[]> {
    return this._loanPurposes.asObservable();
  }

  get savedViews$(): Observable<SavedView[]> {
    return this._savedViews.asObservable();
  }

  get savedView$(): Observable<SavedView> {
    return this._savedView.asObservable();
  }

  get ethanQuestions$(): Observable<any[]> {
    return this._ethanQuestions.asObservable();
  }

  get ethanChats$(): Observable<any[]> {
    return this._ethanChats.asObservable();
  }

  get savedViewValue(): SavedView {
    return this._savedView.value;
  }

  get callOutcomes$(): Observable<CallOutcome[]> {
    return this._callOutcomes.asObservable();
  }

  get meetingOutcomes$(): Observable<MeetingOutcome[]> {
    return this._meetingOutcomes.asObservable();
  }

  get engagementActivityTypes$(): Observable<EngagementActivityType[]> {
    return this._engagementActivityTypes.asObservable();
  }

  get socialMediaSources$(): Observable<SocialMediaSource[]> {
    return this._socialMediaSources.asObservable();
  }

  get accountTagsError$(): Observable<HttpErrorResponse> {
    return this._getAccountTagsError$.asObservable();
  }

  loadAccount(subdomain: string): Observable<Account> {
    return this.getAccount(subdomain).pipe(
      switchMap((account) => {
        this.setAccount(account);
        return zip(this.getUsers(), of(account));
      }),
      map(([_, account]) => account),
    );
  }

  getAccount(subdomain: string): Observable<Account> {
    return this._httpClient.get<{ account: Account }>(`accounts/by_subdomain/${subdomain}`).pipe(
      map(({ account }) => account),
      TsRxjsOperators.handleSubdomainTypoRedirect(),
    );
  }

  updateAccount(account: Partial<Account & { configuration?: any }>): Observable<Account> {
    return this._httpClient.put<any>(`accounts/${account.id}`, { account: account }).pipe(
      map((response) => {
        this.setAccount(response.account);
        return response.account;
      }),
    );
  }

  bustUsersCache(): void {
    usersCacheBuster$.next();
  }

  @Cacheable({
    cacheBusterObserver: usersCacheBuster$,
  })
  getUsers(): Observable<User[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`accounts/${account.id}/user_associations`).pipe(
          map((reponse) => {
            // Update the labels
            const userAssociationsSorted = this.getUserAssociationsSortedByStatus(reponse?.user_associations);
            this._userAssociations.next(userAssociationsSorted);

            const users = reponse?.user_associations.map((ua) => ua.user);
            this._users.next(users);

            // Return the deleted status
            return users;
          }),
        ),
      ),
    );
  }

  getUserAssociations(accountId: number): Observable<UserAssociation[]> {
    return this._httpClient
      .get<{ user_associations: UserAssociation[] }>(`accounts/${accountId}/user_associations`)
      .pipe(
        map((response) => {
          return response.user_associations;
        }),
      );
  }

  updateUserAssociation(userAssociation): Observable<UserAssociation> {
    let account = this.getCurrentAccount();
    return this.userAssociations$.pipe(
      take(1),
      switchMap((userAssociations) =>
        this._httpClient
          .put<any>(
            `accounts/${account.id}/user_associations/${userAssociation.id}?user_id=${userAssociation.user.id}`,
            { user_association: userAssociation },
          )
          .pipe(
            map((response) => {
              let updatedUserAssociation = response.user_association;

              // Find the index of the updated contact
              const index = userAssociations.findIndex((item) => item.id === updatedUserAssociation.id);
              // Update the contact
              userAssociations[index] = updatedUserAssociation;

              // Update the contacts
              const userAssociationsSorted = this.getUserAssociationsSortedByStatus(userAssociations);
              this._userAssociations.next(userAssociationsSorted);

              // Return the updated contact
              return updatedUserAssociation;
            }),
          ),
      ),
    );
  }

  deleteUserAssociation(userAssociation): Observable<UserAssociation> {
    let account = this.getCurrentAccount();
    return this.userAssociations$.pipe(
      take(1),
      switchMap((userAssociations) =>
        this._httpClient
          .delete<any>(
            `accounts/${account.id}/user_associations/${userAssociation.id}?user_id=${userAssociation.user.id}`,
          )
          .pipe(
            map((response) => {
              //[sc-9647]
              if (userAssociation.related_to_type == 'Account') {
                // Find the index of the deleted label within the labels
                const index = userAssociations.findIndex((item) => item.id === userAssociation.id);

                // Delete the label
                userAssociations[index] = response.user_association;
              } else {
                // Find the index of the deleted label within the labels
                const index = userAssociations.findIndex((item) => item.id === userAssociation.id);

                // Delete the label
                userAssociations.splice(index, 1);
              }

              const userAssociationsSorted = this.getUserAssociationsSortedByStatus(userAssociations);
              this._userAssociations.next(userAssociationsSorted);

              // Return the deleted status
              return response.user_association;
            }),
          ),
      ),
    );
  }

  toggleActiveUserAssociation(userAssociation): Observable<UserAssociation> {
    let account = this.getCurrentAccount();
    return this.userAssociations$.pipe(
      take(1),
      switchMap((userAssociations) =>
        this._httpClient
          .post<any>(
            `accounts/${account.id}/user_associations/${userAssociation.id}/toggle_active_user?user_id=${userAssociation.user.id}`,
            { user_association: userAssociation },
          )
          .pipe(
            map((response) => {
              //[sc-9647]
              if (userAssociation.related_to_type == 'Account') {
                // Find the index of the deleted label within the labels
                const index = userAssociations.findIndex((item) => item.id === userAssociation.id);

                // Delete the label
                userAssociations[index] = response.user_association;
              } else {
                // Find the index of the deleted label within the labels
                const index = userAssociations.findIndex((item) => item.id === userAssociation.id);

                // Delete the label
                userAssociations.splice(index, 1);
              }

              const userAssociationsSorted = this.getUserAssociationsSortedByStatus(userAssociations);
              this._userAssociations.next(userAssociationsSorted);

              const users = userAssociationsSorted.map((ua) => ua.user);
              this._users.next(users);
              this.store.dispatch(new SetUsers(users));
              return response.user_association;
            }),
          ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: invitationsCacheBuster$,
  })
  getInvitations(): Observable<Invitation[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`accounts/${account.id}/invitations`).pipe(
          map((reponse) => {
            // Update the labels
            this._invitations.next(reponse.invitations);

            // Return the deleted status
            return reponse.invitations;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: invitationsCacheBuster$,
  })
  sendInvitation(invitation: Invitation): Observable<Invitation> {
    let account = this.getCurrentAccount();
    return this.invitations$.pipe(
      take(1),
      switchMap((invitations) =>
        this._httpClient.post<any>(`accounts/${account.id}/invitations`, { invitation: invitation }).pipe(
          map((reponse) => {
            return reponse.invitation;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: invitationsCacheBuster$,
  })
  deleteInvitation(id: number): Observable<Invitation> {
    let account = this.getCurrentAccount();
    return this.invitations$.pipe(
      take(1),
      switchMap((invitations) =>
        this._httpClient.delete<any>(`invitations/${id}`).pipe(
          map((response) => {
            // Find the index of the deleted label within the labels
            const index = invitations.findIndex((item) => item.id === id);

            // Delete the label
            invitations.splice(index, 1);

            // Update the labels
            this._invitations.next(invitations);

            // Return the deleted status
            return response.invitation;
          }),
        ),
      ),
    );
  }

  resendInvitation(id: number): Observable<Invitation> {
    let account = this.getCurrentAccount();
    return this.invitations$.pipe(
      take(1),
      switchMap((invitations) =>
        this._httpClient.put<any>(`invitations/${id}/resend_invitation`, {}).pipe(
          map((response) => {
            if (response) {
              return response?.invitation;
            }
          }),
        ),
      ),
    );
  }

  setAccount(account: Account) {
    this.currentAccountSubject.next(account);
    this._hasSubdomain = true;
  }

  getCurrentAccount(): Account {
    return this.currentAccountSubject.value;
  }

  setSubdomain(subdomain) {
    this._subdomain = subdomain;
  }

  getSubdomain() {
    return this._subdomain;
  }

  doesHaveSubdomain() {
    return this._hasSubdomain;
  }

  getSavedFiltersValue(): SavedFilter[] {
    return this._savedFilters.value;
  }

  /**
   * Get countries
   */
  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getProjectStages(): Observable<ProjectStage[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`project_stages`).pipe(
          map((response) => {
            this._projectStages.next(response.project_stages);
            return response.project_stages;
          }),
        ),
      ),
    );
  }

  @Cacheable({ cacheBusterObserver: standardDropdownsCacheBuster$ })
  getStandardDropdowns(): Observable<any> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`standard_dropdowns`).pipe(
          map((response) => {
            const data = response.standard_dropdowns;
            this._projectPhases.next(data?.project_phases);
            this._vehicles.next(data?.vehicles);
            this._projectStages.next(data?.project_stages);
            this._projectStagesDeleted.next(data?.project_stages_deleted);
            this._propertyTypes.next(data?.property_types);
            this._investmentTypes.next(data?.investment_types);
            this._callOutcomes.next(data?.call_outcomes);
            this._categories.next(data?.categories);
            this._meetingOutcomes.next(data?.meeting_outcomes);
            this._engagementActivityTypes.next(data?.engagement_activity_types);
            this._marketStatuses.next(data?.market_statuses);
            this._loanPurposes.next(data?.loan_purposes);
            this._socialMediaSources.next(data?.social_media_sources);
            this._projectTypes.next(data?.project_types);
            return response.standard_dropdowns;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateProjectStage(projectStage: ProjectStage): Observable<ProjectStage> {
    let account = this.getCurrentAccount();

    const project_stage = { ...projectStage };
    delete project_stage.rank;

    return this.projectStages$.pipe(
      take(1),
      switchMap((projectStages) =>
        this._httpClient.put<any>(`project_stages/${projectStage.id}`, { project_stage }).pipe(
          map((response) => {
            let updatedProjectStage = response.project_stage;

            // Find the index of the updated contact
            const index = projectStages.findIndex((item) => item.id === updatedProjectStage.id);
            // Update the contact
            projectStages[index] = updatedProjectStage;

            // Update the contacts
            this._projectStages.next(projectStages);

            // Return the updated contact
            return updatedProjectStage;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createProjectStage(projectStage: ProjectStage): Observable<ProjectStage> {
    let account = this.getCurrentAccount();
    return this.projectStages$.pipe(
      take(1),
      switchMap((projectStages) =>
        this._httpClient.post<any>(`project_stages`, { project_stage: projectStage }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._projectStages.next([reponse.project_stage, ...projectStages]);

            // Return the new contact
            return reponse.project_stage;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getProjectPhases(): Observable<ProjectPhase[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`project_phases`).pipe(
          map((response) => {
            this._projectPhases.next(response.project_phases);
            return response.project_phases;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateProjectPhase(projectPhase: ProjectPhase): Observable<ProjectPhase> {
    let account = this.getCurrentAccount();

    const project_phase = { ...projectPhase };
    delete project_phase.rank;

    return this.projectPhases$.pipe(
      take(1),
      switchMap((projectPhases) =>
        this._httpClient.put<any>(`project_phases/${projectPhase.id}`, { project_phase }).pipe(
          map((response) => {
            let updatedProjectPhase = response.project_phase;

            // Find the index of the updated contact
            const index = projectPhases.findIndex((item) => item.id === updatedProjectPhase.id);
            // Update the contact
            projectPhases[index] = updatedProjectPhase;

            // Update the contacts
            this._projectPhases.next(projectPhases);

            // Return the updated contact
            return updatedProjectPhase;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createProjectPhase(projectPhase: ProjectPhase): Observable<ProjectPhase> {
    let account = this.getCurrentAccount();
    return this.projectPhases$.pipe(
      take(1),
      switchMap((projectPhases) =>
        this._httpClient.post<any>(`project_phases`, { project_phase: projectPhase }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._projectPhases.next([reponse.project_phase, ...projectPhases]);

            // Return the new contact
            return reponse.project_phase;
          }),
        ),
      ),
    );
  }

  /**
   * Get countries
   */
  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getProjectTypes(): Observable<ProjectType[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`project_types`).pipe(
          map((response) => {
            this._projectTypes.next(response.project_types);
            return response.project_types;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateProjectType(projectType: ProjectType): Observable<ProjectType> {
    let account = this.getCurrentAccount();

    const project_type = { ...projectType };
    delete project_type.rank;

    return this.projectTypes$.pipe(
      take(1),
      switchMap((projectTypes) =>
        this._httpClient.put<any>(`project_types/${projectType.id}`, { project_type }).pipe(
          map((response) => {
            let updatedProjectType = response.project_type;

            // Find the index of the updated contact
            const index = projectTypes.findIndex((item) => item.id === updatedProjectType.id);
            // Update the contact
            projectTypes[index] = updatedProjectType;

            // Update the contacts
            this._propertyTypes.next(projectTypes);

            // Return the updated contact
            return updatedProjectType;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createProjectType(projectType: ProjectType): Observable<ProjectType> {
    let account = this.getCurrentAccount();
    return this.projectTypes$.pipe(
      take(1),
      switchMap((projectTypes) =>
        this._httpClient.post<any>(`project_types`, { project_type: projectType }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._projectTypes.next([reponse.project_type, ...projectTypes]);

            // Return the new contact
            return reponse.project_type;
          }),
        ),
      ),
    );
  }

  /**
   * Get countries
   */
  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getPropertyTypes(): Observable<PropertyType[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`property_types`).pipe(
          map((response) => {
            this._propertyTypes.next(response.property_types);
            return response.property_types;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updatePropertyType(propertyType: PropertyType): Observable<PropertyType> {
    let account = this.getCurrentAccount();

    const property_type = { ...propertyType };
    delete property_type.rank;

    return this.propertyTypes$.pipe(
      take(1),
      switchMap((propertyTypes) =>
        this._httpClient.put<any>(`property_types/${propertyType.id}`, { property_type }).pipe(
          map((response) => {
            let updatedPropertyType = response.property_type;

            // Find the index of the updated contact
            const index = propertyTypes.findIndex((item) => item.id === updatedPropertyType.id);
            // Update the contact
            propertyTypes[index] = updatedPropertyType;

            // Update the contacts
            this._propertyTypes.next(propertyTypes);

            // Return the updated contact
            return updatedPropertyType;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createPropertyType(propertyType: PropertyType): Observable<PropertyType> {
    let account = this.getCurrentAccount();
    return this.propertyTypes$.pipe(
      take(1),
      switchMap((propertyTypes) =>
        this._httpClient.post<any>(`property_types`, { property_type: propertyType }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._propertyTypes.next([reponse.property_type, ...propertyTypes]);

            // Return the new contact
            return reponse.property_type;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getInvestmentTypes(): Observable<InvestmentType[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`investment_types`).pipe(
          map((response) => {
            this._investmentTypes.next(response.investment_types);
            return response.investment_types;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateInvestmentType(investmentType: InvestmentType): Observable<InvestmentType> {
    let account = this.getCurrentAccount();

    const investment_type = { ...investmentType };
    delete investment_type.rank;

    return this.investmentTypes$.pipe(
      take(1),
      switchMap((investmentTypes) =>
        this._httpClient
          .put<any>(`investment_types/${investmentType.id}`, {
            investment_type,
          })
          .pipe(
            map((response) => {
              let updatedInvestmentType = response.investment_type;

              // Find the index of the updated contact
              const index = investmentTypes.findIndex((item) => item.id === updatedInvestmentType.id);
              // Update the contact
              investmentTypes[index] = updatedInvestmentType;

              // Update the contacts
              this._investmentTypes.next(investmentTypes);

              // Return the updated contact
              return updatedInvestmentType;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createInvestmentType(investmentType: InvestmentType): Observable<InvestmentType> {
    let account = this.getCurrentAccount();
    return this.investmentTypes$.pipe(
      take(1),
      switchMap((investmentTypes) =>
        this._httpClient.post<any>(`investment_types`, { investment_type: investmentType }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._investmentTypes.next([reponse.investment_type, ...investmentTypes]);

            // Return the new contact
            return reponse.investment_type;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getMarketStatuses(): Observable<MarketStatus[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`market_statuses`).pipe(
          map((response) => {
            this._marketStatuses.next(response.market_statuses);
            return response.market_statuses;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateMarketStatus(marketStatus: MarketStatus): Observable<MarketStatus> {
    let account = this.getCurrentAccount();

    const market_status = { ...marketStatus };
    delete market_status.rank;

    return this.marketStatuses$.pipe(
      take(1),
      switchMap((marketStatuses) =>
        this._httpClient.put<any>(`market_statuses/${marketStatus.id}`, { market_status }).pipe(
          map((response) => {
            let updatedMarketStatus = response.market_status;

            // Find the index of the updated contact
            const index = marketStatuses.findIndex((item) => item.id === updatedMarketStatus.id);
            // Update the contact
            marketStatuses[index] = updatedMarketStatus;

            // Update the contacts
            this._marketStatuses.next(marketStatuses);

            // Return the updated contact
            return updatedMarketStatus;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createMarketStatus(marketStatus: MarketStatus): Observable<MarketStatus> {
    let account = this.getCurrentAccount();
    return this.marketStatuses$.pipe(
      take(1),
      switchMap((marketStatuses) =>
        this._httpClient.post<any>(`market_statuses`, { market_status: marketStatus }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._marketStatuses.next([reponse.market_status, ...marketStatuses]);

            // Return the new contact
            return reponse.market_status;
          }),
        ),
      ),
    );
  }

  /**
   * @deprecated use `TeamsService.getTeams` instead.
   */
  @Cacheable({
    cacheBusterObserver: teamsCacheBuster$,
  })
  getTeams(): Observable<Team[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<PaginatedResource<Team>>(`accounts/${account.id}/teams?page=1&per_page=1000`).pipe(
          map(({ results }) => {
            const flattenedTeams = TeamsService.flattenTeamsAndAddMetadata(results);

            this._teams.next(flattenedTeams);
            return flattenedTeams;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: teamsCacheBuster$,
  })
  updateTeam(team: Team): Observable<Team> {
    const payload = { ...team };
    delete payload.rank;

    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.put<any>(`accounts/${account.id}/teams/${team.id}`, { team: payload }).pipe(
          map((response) => {
            let updatedTeam = response.team;

            // Find the index of the updated contact
            const index = teams.findIndex((item) => item.id === updatedTeam.id);
            // Update the contact
            teams[index] = updatedTeam;

            // Update the contacts
            this._teams.next(teams);

            // Return the updated contact
            return updatedTeam;
          }),
        ),
      ),
    );
  }

  /**
   * @deprecated use `TeamsService.saveTeam` instead.
   */
  @CacheBuster({
    cacheBusterNotifier: teamsCacheBuster$,
  })
  createTeam(team: Team): Observable<Team> {
    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.post<any>(`accounts/${account.id}/teams`, { team: team }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._teams.next([reponse.team, ...teams]);

            // Return the new contact
            return reponse.team;
          }),
        ),
      ),
    );
  }

  addUserToTeam(team: Team, user: User): Observable<Team> {
    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.post<any>(`accounts/${account.id}/teams/${team.id}/add_user`, { user_id: user.id }).pipe(
          map((response) => {
            let updatedTeam = response.team;

            // Find the index of the updated contact
            const index = teams.findIndex((item) => item.id === updatedTeam.id);
            // Update the contact
            teams[index] = updatedTeam;

            // Update the contacts
            this._teams.next(teams);
            /// Return the new contact
            return response.team;
          }),
        ),
      ),
    );
  }

  removeUserFromTeam(team: Team, user: User): Observable<Team> {
    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.post<any>(`accounts/${account.id}/teams/${team.id}/remove_user`, { user_id: user.id }).pipe(
          map((response) => {
            let updatedTeam = response.team;

            // Find the index of the updated contact
            const index = teams.findIndex((item) => item.id === updatedTeam.id);
            // Update the contact
            teams[index] = updatedTeam;

            // Update the contacts
            this._teams.next(teams);
            /// Return the new contact
            return response.team;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: teamsCacheBuster$,
  })
  deleteTeam(id: number): Observable<any> {
    let account = this.getCurrentAccount();
    return this.teams$.pipe(
      take(1),
      switchMap((teams) =>
        this._httpClient.delete<any>(`accounts/${account.id}/teams/${id}`).pipe(
          map((response) => {
            // Find the index of the deleted label within the labels
            const index = teams.findIndex((item) => item.id === id);

            // Delete the label
            teams.splice(index, 1);

            // Update the labels
            this._teams.next(teams);

            // Return the deleted status
            return response.team;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getCategories(): Observable<Category[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`categories`).pipe(
          map((response) => {
            this._categories.next(response.categories);
            return response.categories;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createCategory(category: Category): Observable<Category> {
    let account = this.getCurrentAccount();
    return this.categories$.pipe(
      take(1),
      switchMap((categories) =>
        this._httpClient.post<any>(`categories`, { category: category }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._categories.next([reponse.category, ...categories]);

            // Return the new contact
            return reponse.category;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateCategory(category: Category): Observable<Category> {
    let account = this.getCurrentAccount();

    const payload = { ...category };
    delete payload.rank;

    return this.categories$.pipe(
      take(1),
      switchMap((categories) =>
        this._httpClient.put<any>(`categories/${category.id}`, { category: payload }).pipe(
          map((response) => {
            let updatedCategory = response.category;

            // Find the index of the updated contact
            const index = categories.findIndex((item) => item.id === updatedCategory.id);
            // Update the contact
            categories[index] = updatedCategory;

            // Update the contacts
            this._categories.next(categories);

            // Return the updated contact
            return updatedCategory;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getVehicles(): Observable<Team[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`vehicles`).pipe(
          map((response) => {
            this._vehicles.next(response.vehicles);
            return response.vehicles;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateVehicle(vehicle: Vehicle): Observable<Vehicle> {
    let account = this.getCurrentAccount();

    const payload = { ...vehicle };
    delete payload.rank;

    return this.vehicles$.pipe(
      take(1),
      switchMap((vehicles) =>
        this._httpClient.put<any>(`vehicles/${vehicle.id}`, { vehicle: payload }).pipe(
          map((response) => {
            let updatedVehicle = response.vehicle;

            // Find the index of the updated contact
            const index = vehicles.findIndex((item) => item.id === updatedVehicle.id);
            // Update the contact
            vehicles[index] = updatedVehicle;

            // Update the contacts
            this._vehicles.next(vehicles);

            // Return the updated contact
            return updatedVehicle;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createVehicle(vehicle: Vehicle): Observable<Vehicle> {
    let account = this.getCurrentAccount();
    return this.vehicles$.pipe(
      take(1),
      switchMap((vehicles) =>
        this._httpClient.post<any>(`vehicles`, { vehicle: vehicle }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._vehicles.next([reponse.vehicle, ...vehicles]);

            // Return the new contact
            return reponse.vehicle;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getLoanPurposes(): Observable<LoanPurpose[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`loan_purposes`).pipe(
          map((response) => {
            this._loanPurposes.next(response.loan_purposes);
            return response.loan_purposes;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateLoanPurpose(loanPurpose: LoanPurpose): Observable<LoanPurpose> {
    const loan_purpose = { ...loanPurpose };
    delete loan_purpose.rank;

    let account = this.getCurrentAccount();
    return this.loanPurposes$.pipe(
      take(1),
      switchMap((loanPurposes) =>
        this._httpClient.put<any>(`loan_purposes/${loanPurpose.id}`, { loan_purpose }).pipe(
          map((response) => {
            let updatedLoanPurpose = response.loan_purpose;

            // Find the index of the updated contact
            const index = loanPurposes.findIndex((item) => item.id === updatedLoanPurpose.id);
            // Update the contact
            loanPurposes[index] = updatedLoanPurpose;

            // Update the contacts
            this._loanPurposes.next(loanPurposes);

            // Return the updated contact
            return updatedLoanPurpose;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createLoanPurpose(loanPurpose: LoanPurpose): Observable<LoanPurpose> {
    let account = this.getCurrentAccount();
    return this.loanPurposes$.pipe(
      take(1),
      switchMap((loanPurposes) =>
        this._httpClient.post<any>(`loan_purposes`, { loan_purpose: loanPurpose }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._loanPurposes.next([reponse.loan_purpose, ...loanPurposes]);

            // Return the new contact
            return reponse.loan_purpose;
          }),
        ),
      ),
    );
  }

  /**
   * Get Field Defs
   */
  @Cacheable({
    cacheBusterObserver: fieldDefsCacheBuster$,
  })
  getFieldDefs(format: string = '', relatedToType?: string): Observable<FieldDef[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) => {
        const url = relatedToType
          ? `fields?format=${format}&related_to_type=${relatedToType}`
          : `fields?format=${format}`;
        return this._httpClient.get<{ data?: any; fields?: FieldDef[] }>(url).pipe(
          map((response) => {
            if (format == 'csv' || format == 'pdf') {
              return response.data;
            } else {
              if (response?.fields?.length) {
                const defaultFieldDefs = response.fields.filter(
                  (f) => f?.meta?.calc_code || f?.meta?.default_calc_code,
                );
                this._defaultFieldDefs.next(defaultFieldDefs);
              }
              this._fieldDefs.next(response.fields);
              return response.fields;
            }
          }),
        );
      }),
    );
  }

  getFieldDefsByAccountId(accountId: string): Observable<FieldDef[]> {
    return this._httpClient.get<{ fields: FieldDef[] }>(`fields`).pipe(map(({ fields }) => fields));
  }

  @Cacheable({
    cacheBusterObserver: savedFiltersCacheBuster$,
  })
  getSavedFilters(): Observable<SavedFilter[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`saved_filters`).pipe(
          map((response) => {
            this._savedFilters.next(response.saved_filters);
            return response.saved_filters;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedFiltersCacheBuster$,
  })
  saveFilter(savedFilter: SavedFilter): Observable<SavedFilter> {
    let account = this.getCurrentAccount();
    return this.savedFilters$.pipe(
      take(1),
      switchMap((savedFilters) =>
        this._httpClient.post<any>(`saved_filters`, { saved_filter: savedFilter }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._savedFilters.next([reponse.saved_filter, ...savedFilters]);

            // Return the new contact
            return reponse.saved_filter;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedFiltersCacheBuster$,
  })
  updateFilter(savedFilter: SavedFilter): Observable<SavedFilter> {
    let account = this.getCurrentAccount();
    return this.savedFilters$.pipe(
      take(1),
      switchMap((savedFilters) =>
        this._httpClient.put<any>(`saved_filters/${savedFilter.id}`, { saved_filter: savedFilter }).pipe(
          map((response) => {
            if (savedFilters) {
              let updatedFilter = response.saved_filter;
              // Find the index of the updated contact
              const index = savedFilters.findIndex((item) => item.id === updatedFilter.id);

              // Update the contact
              savedFilters[index] = updatedFilter;

              // Update the contacts
              this._savedFilters.next(savedFilters);
            }

            // Return the new contact
            return response.saved_filter;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedFiltersCacheBuster$,
  })
  deleteFilter(id: number): Observable<any> {
    let account = this.getCurrentAccount();
    return this.savedFilters$.pipe(
      take(1),
      switchMap((savedFilters) =>
        this._httpClient.delete<any>(`saved_filters/${id}`).pipe(
          map((response) => {
            // Find the index of the deleted label within the labels
            const index = savedFilters.findIndex((item) => item.id === id);

            // Delete the label
            savedFilters.splice(index, 1);

            // Update the labels
            this._savedFilters.next(savedFilters);

            // Return the deleted status
            return response.saved_filter;
          }),
        ),
      ),
    );
  }

  getSavedFilterById(id: number): Observable<SavedFilter> {
    return this._savedFilters.pipe(
      take(1),
      map((savedFilters) => {
        // Find the product
        const savedFilter = savedFilters.find((item) => item.id === id) || null;

        // Return the product
        return savedFilter;
      }),
      switchMap((savedFilter) => {
        if (!savedFilter) {
          return throwError('Could not found product with id of ' + id + '!');
        }

        return of(savedFilter);
      }),
    );
  }

  /**
   * Add CustomField
   */
  @CacheBuster({
    cacheBusterNotifier: fieldDefsCacheBuster$,
  })
  createCustomField(fieldDef: FieldDef): Observable<FieldDef> {
    let account = this.getCurrentAccount();
    return this.fieldDefs$.pipe(
      take(1),
      switchMap((fieldDefs) =>
        this._httpClient.post<{ custom_field: FieldDef }>(`custom_fields`, { custom_field: fieldDef }).pipe(
          map((response) => {
            this._fieldDefs.next([response?.custom_field, ...fieldDefs]);
            return response?.custom_field;
          }),
        ),
      ),
    );
  }

  updateCustomField(fieldDef: FieldDef): Observable<FieldDef> {
    const account = this.getCurrentAccount();
    return this.fieldDefs$.pipe(
      take(1),
      switchMap((fieldDefs) =>
        this._httpClient
          .put<{ custom_field: FieldDef }>(`custom_fields/${fieldDef.id}`, {
            custom_field: fieldDef,
          })
          .pipe(
            map((response) => {
              this._fieldDefs.next(fieldDefs.map((item) => (item.id === fieldDef.id ? response.custom_field : item)));
              return response.custom_field;
            }),
          ),
      ),
    );
  }

  deleteCustomField(fieldDef: FieldDef): Observable<FieldDef> {
    const account = this.getCurrentAccount();
    return this.fieldDefs$.pipe(
      take(1),
      switchMap((fieldDefs) =>
        this._httpClient.delete<any>(`custom_fields/${fieldDef.id}`).pipe(
          map(() => {
            const index = fieldDefs.findIndex((item) => item.id === fieldDef.id);

            fieldDefs.splice(index, 1);

            this._fieldDefs.next([...fieldDefs]);

            return fieldDef;
          }),
        ),
      ),
    );
  }

  updateStandardField(fieldDef: FieldDef): Observable<FieldDef> {
    const account = this.getCurrentAccount();
    return this.fieldDefs$.pipe(
      take(1),
      switchMap((fieldDefs) =>
        this._httpClient
          .put<{ field: FieldDef }>(`fields`, {
            standard_field_name: fieldDef.name,
            related_to_type: fieldDef.related_to_type,
            archived: fieldDef.archived,
            show_history: fieldDef.show_history,
            inherited: fieldDef.inherited,
          })
          .pipe(
            map((response) => {
              this._fieldDefs.next(fieldDefs.map((item) => (item.id === fieldDef.id ? response.field : item)));
              return response.field;
            }),
          ),
      ),
    );
  }

  getActivities(params: any): Observable<Activity[]> {
    return this._httpClient.get<{ activities: Activity[] }>(`activities`, { params: params }).pipe(
      take(1),
      map(({ activities }) => activities),
    );
  }

  deleteActivity(id: number): Observable<Activity> {
    return this._httpClient.delete<Activity>(`activities/${id}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getPipelineTemplates(): Observable<PipelineTemplate[]> {
    return this._httpClient.get<any>(`/pipeline_templates`).pipe(
      map((response) => {
        return response.templates;
      }),
    );
  }

  typeaheadSearch(query: string, searchIn: string = 'Market,Company'): Observable<any> {
    let account = this.getCurrentAccount();
    return this._httpClient.get<any>(`search/names?q=${query}&in=${searchIn}`).pipe(
      map((response) => {
        return response.result;
      }),
    );
  }

  getAccountIntegrations(): Observable<AccountIntegration[]> {
    let account = this.getCurrentAccount();
    return this._httpClient.get<any>(`integrations`).pipe(
      map((response) => response.account_integrations),
      catchError((err: HttpErrorResponse) => {
        this._accountIntegrationsError.next(err);
        return EMPTY;
      }),
    );
  }

  getClikToken(): Observable<any> {
    let account = this.getCurrentAccount();
    return this._httpClient.get<any>(`clikai/setup`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  @Cacheable({
    cacheBusterObserver: savedViewsCacheBuster$,
  })
  getSavedViews(): Observable<SavedView[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`saved_views`).pipe(
          map((response) => {
            this._savedViews.next(response.saved_views);
            return response.saved_views;
          }),
        ),
      ),
    );
  }

  getSavedViewById(id: string): Observable<SavedView> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`saved_views/${id}`).pipe(
          map((response) => {
            this._savedView.next(response.saved_view);
            return response.saved_view;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedViewsCacheBuster$,
  })
  createSavedView(savedView: SavedView): Observable<SavedView> {
    let account = this.getCurrentAccount();
    return this.savedViews$.pipe(
      take(1),
      switchMap((savedViews) =>
        this._httpClient.post<any>(`saved_views`, { saved_view: savedView }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._savedViews.next([reponse.saved_view, ...(savedViews || [])]);

            // Return the new contact
            return reponse.saved_view;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: savedViewsCacheBuster$,
  })
  updateSavedView(savedView: SavedView): Observable<SavedView> {
    let account = this.getCurrentAccount();
    return this.savedViews$.pipe(
      take(1),
      switchMap((savedViews) =>
        this._httpClient.put<any>(`saved_views/${savedView.id}`, { saved_view: savedView }).pipe(
          map((response) => {
            let updatedSavedView = response.saved_view;

            // Find the index of the updated contact
            const index = savedViews.findIndex((item) => item.id === updatedSavedView.id);

            // Update the contact
            savedViews[index] = updatedSavedView;

            // Update the contacts
            this._savedViews.next(savedViews);
            this._savedView.next(updatedSavedView);

            // Return the updated contact
            return updatedSavedView;
          }),
        ),
      ),
    );
  }

  deleteSavedView(savedViewId: number): Observable<SavedView> {
    return this._httpClient.delete<any>(`saved_views/${savedViewId}`).pipe(
      tap(() => {
        savedViewsCacheBuster$.next();
      }),
    );
  }

  bustSavedFiltersCacheBuster(): void {
    savedFiltersCacheBuster$.next();
  }

  bustSavedViewCache(): void {
    savedViewsCacheBuster$.next();
  }

  bustInvitationsCacheBuster(): void {
    invitationsCacheBuster$.next();
  }

  fieldDefsCacheBuster(): void {
    fieldDefsCacheBuster$.next();
  }

  bustStandardDropdownsCache(): void {
    standardDropdownsCacheBuster$.next();
  }

  getUserPermissionByAccountId(accountId: number): Observable<Permission[]> {
    return this._httpClient
      .get<{ permissions: Permission[] }>(`user/permissions`)
      .pipe(map(({ permissions }) => permissions));
  }

  getUser(accountId?: number): Observable<User> {
    const account = this.getCurrentAccount();
    accountId = accountId ?? Number(account.id);

    return zip([
      this._httpClient.get<{ user: User }>(`user`).pipe(map(({ user }) => user)),
      this.getUserAccountRoles(accountId),
    ]).pipe(
      take(1),
      map(([user, roles]) => {
        return { ...user, roles };
      }),
    );
  }

  getUserAccountRoles(accountId: number): Observable<Role[]> {
    return this._httpClient.get<{ role: Role }>(`user/account_role`).pipe(
      take(1),
      map(({ role }) => {
        if (!role) {
          throw new Error('Empty or unparseable response for user/account_role request');
        }
        return Array.isArray(role) ? role : [role];
      }),
      catchError((error) => {
        console.error(error);
        return of([] as Role[]);
      }),
    );
  }

  scheduleExport(objectType): Observable<any> {
    let account = this.getCurrentAccount();
    return this._httpClient.post<any>(`/accounts/${account.id}/schedule_export?object_type=${objectType}`, {}).pipe(
      tap((response) => {
        return response;
      }),
    );
  }

  getUserAssociationsSortedByStatus(userAssociations: UserAssociation[]): UserAssociation[] {
    return userAssociations?.sort((a, b) => {
      if (a.user?.active && !b.user?.active) {
        return -1;
      } else if (!a.user?.active && b.user?.active) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  getAccountTags(accountId: string, relatedToType?: RelatedToType, query?: string): Observable<Dictionary<number>> {
    let params: HttpParams = new HttpParams();
    if (relatedToType) params = params.set('related_to_type', relatedToType);
    if (query) params = params.set('query', query);

    return this._httpClient
      .get<{ tags: Dictionary<number> }>(`tags`, {
        params: params,
      })
      .pipe(
        map(({ tags }) => tags),
        catchError((error: HttpErrorResponse) => {
          this._getAccountTagsError$.next(error);
          return throwError(() => error);
        }),
      );
  }

  massUpdateTags(
    accountId: string,
    payload: {
      related_to_type: RelatedToType;
      related_to_ids: number[];
      added_tags: string[];
      removed_tags: string[];
    },
  ): Observable<Company[] | Contact[]> {
    return this._httpClient
      .patch<any>(`tags/mass_update`, {
        ...payload,
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getCallOutcomes(): Observable<CallOutcome[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`call_outcomes`).pipe(
          map((response) => {
            this._callOutcomes.next(response.call_outcomes);
            return response.call_outcomes;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateCallOutcome(callOutcome: CallOutcome): Observable<CallOutcome> {
    let account = this.getCurrentAccount();

    const call_outcome = { ...callOutcome };
    delete call_outcome.rank;

    return this.callOutcomes$.pipe(
      take(1),
      switchMap((callOutcomes) =>
        this._httpClient.put<any>(`call_outcomes/${callOutcome.id}`, { call_outcome }).pipe(
          map((response) => {
            let updatedCallOutcome = response.call_outcome;

            // Find the index of the updated contact
            const index = callOutcomes.findIndex((item) => item.id === updatedCallOutcome.id);
            // Update the contact
            callOutcomes[index] = updatedCallOutcome;

            // Update the contacts
            this._callOutcomes.next(callOutcomes);

            // Return the updated contact
            return updatedCallOutcome;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createCallOutcome(callOutcome: CallOutcome): Observable<CallOutcome> {
    let account = this.getCurrentAccount();
    return this.callOutcomes$.pipe(
      take(1),
      switchMap((callOutcomes) =>
        this._httpClient.post<any>(`call_outcomes`, { call_outcome: callOutcome }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._callOutcomes.next([reponse.call_outcome, ...callOutcomes]);

            // Return the new contact
            return reponse.call_outcome;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getMeetingOutcomes(): Observable<MeetingOutcome[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`meeting_outcomes`).pipe(
          map((response) => {
            this._meetingOutcomes.next(response.meeting_outcomes);
            return response.meeting_outcomes;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateMeetingOutcome(meetingOutcome: MeetingOutcome): Observable<MeetingOutcome> {
    let account = this.getCurrentAccount();

    const meeting_outcome = { ...meetingOutcome };
    delete meeting_outcome.rank;

    return this.meetingOutcomes$.pipe(
      take(1),
      switchMap((meetingOutcomes) =>
        this._httpClient
          .put<any>(`meeting_outcomes/${meetingOutcome.id}`, {
            meeting_outcome,
          })
          .pipe(
            map((response) => {
              let updatedMeetingOutcome = response.meeting_outcome;

              // Find the index of the updated contact
              const index = meetingOutcomes.findIndex((item) => item.id === updatedMeetingOutcome.id);
              // Update the contact
              meetingOutcomes[index] = updatedMeetingOutcome;

              // Update the contacts
              this._meetingOutcomes.next(meetingOutcomes);

              // Return the updated contact
              return updatedMeetingOutcome;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createMeetingOutcome(meetingOutcome: MeetingOutcome): Observable<MeetingOutcome> {
    let account = this.getCurrentAccount();
    return this.meetingOutcomes$.pipe(
      take(1),
      switchMap((meetingOutcomes) =>
        this._httpClient.post<any>(`meeting_outcomes`, { meeting_outcome: meetingOutcome }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._meetingOutcomes.next([reponse.meeting_outcome, ...meetingOutcomes]);

            // Return the new contact
            return reponse.meeting_outcome;
          }),
        ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getEngagementActivityTypes(): Observable<EngagementActivityType[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`engagement_activity_types`).pipe(
          map((response) => {
            this._engagementActivityTypes.next(response.engagement_activity_types);
            return response.engagement_activity_types;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateEngagementActivityType(engagementActivityType: EngagementActivityType): Observable<EngagementActivityType> {
    let account = this.getCurrentAccount();

    const engagement_activity_type = { ...engagementActivityType };
    delete engagement_activity_type.rank;

    return this.engagementActivityTypes$.pipe(
      take(1),
      switchMap((engagementActivityTypes) =>
        this._httpClient
          .put<any>(`engagement_activity_types/${engagementActivityType.id}`, {
            engagement_activity_type,
          })
          .pipe(
            map((response) => {
              let updatedEngagementActivityType = response.engagement_activity_type;

              // Find the index of the updated contact
              const index = engagementActivityTypes.findIndex((item) => item.id === updatedEngagementActivityType.id);
              // Update the contact
              engagementActivityTypes[index] = updatedEngagementActivityType;

              // Update the contacts
              this._engagementActivityTypes.next(engagementActivityTypes);

              // Return the updated contact
              return updatedEngagementActivityType;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createEngagementActivityType(engagementActivityType: EngagementActivityType): Observable<EngagementActivityType> {
    let account = this.getCurrentAccount();
    return this.engagementActivityTypes$.pipe(
      take(1),
      switchMap((engagementActivityTypes) =>
        this._httpClient
          .post<any>(`engagement_activity_types`, {
            engagement_activity_type: engagementActivityType,
          })
          .pipe(
            map((reponse) => {
              // Update the contacts with the new contact
              this._engagementActivityTypes.next([reponse.engagement_activity_type, ...engagementActivityTypes]);

              // Return the new contact
              return reponse.engagement_activity_type;
            }),
          ),
      ),
    );
  }

  @Cacheable({
    cacheBusterObserver: standardDropdownsCacheBuster$,
  })
  getSocialMediaSources(): Observable<SocialMediaSource[]> {
    return this.currentAccountSubject.pipe(
      take(1),
      switchMap((account) =>
        this._httpClient.get<any>(`social_media_sources`).pipe(
          map((response) => {
            this._socialMediaSources.next(response.social_media_sources);
            return response.social_media_sources;
          }),
        ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  updateSocialMediaSources(socialMediaSource: SocialMediaSource): Observable<SocialMediaSource> {
    let account = this.getCurrentAccount();

    const social_media_source = { ...socialMediaSource };
    delete social_media_source.rank;

    return this.socialMediaSources$.pipe(
      take(1),
      switchMap((socialMediaSources) =>
        this._httpClient
          .put<any>(`social_media_sources/${socialMediaSource.id}`, {
            social_media_source,
          })
          .pipe(
            map((response) => {
              let updatedSocialMediaSource = response.social_media_source;

              // Find the index of the updated contact
              const index = socialMediaSources.findIndex((item) => item.id === updatedSocialMediaSource.id);
              // Update the contact
              socialMediaSources[index] = updatedSocialMediaSource;

              // Update the contacts
              this._socialMediaSources.next(socialMediaSources);

              // Return the updated contact
              return updatedSocialMediaSource;
            }),
          ),
      ),
    );
  }

  @CacheBuster({
    cacheBusterNotifier: standardDropdownsCacheBuster$,
  })
  createSocialMediaSources(socialMediaSource: SocialMediaSource): Observable<SocialMediaSource> {
    let account = this.getCurrentAccount();
    return this.socialMediaSources$.pipe(
      take(1),
      switchMap((socialMediaSources) =>
        this._httpClient.post<any>(`social_media_sources`, { social_media_source: socialMediaSource }).pipe(
          map((reponse) => {
            // Update the contacts with the new contact
            this._socialMediaSources.next([reponse.social_media_source, ...socialMediaSources]);

            // Return the new contact
            return reponse.social_media_source;
          }),
        ),
      ),
    );
  }

  uploadAccountImage(file: File): Observable<Account> {
    const uploadData = new FormData();
    uploadData.append('image[attachment]', file);

    return this.currentAccount.pipe(
      take(1),
      switchMap(({ id }) => {
        return this._httpClient.post<any>(`accounts/${id}/image`, uploadData).pipe(
          map((response) => {
            this.setAccount(response.account);
            return response.account;
          }),
        );
      }),
    );
  }

  removeAccountImage(): Observable<Account> {
    return this.currentAccount.pipe(
      take(1),
      switchMap(({ id }) => {
        return this._httpClient.delete<any>(`accounts/${id}/image`).pipe(
          map((response) => {
            this.setAccount(response.account);
            return response.account;
          }),
        );
      }),
    );
  }

  deleteLinkedObject(id: number): Observable<any> {
    let account = this.getCurrentAccount();
    return this._httpClient.delete<Activity>(`linked_objects/${id}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  getFeatureFlags(): Observable<FeatureFlag[]> {
    let account = this.getCurrentAccount();
    return this._httpClient.get<any>(`accounts/${account.id}/feature_flags`).pipe(
      map((response) => {
        return response.feature_flags;
      }),
    );
  }

  toggleFeatureFlag(slug: string): Observable<FeatureFlag[]> {
    let account = this.getCurrentAccount();
    return this._httpClient.post<any>(`accounts/${account.id}/feature_flags/toggle`, { slug: slug }).pipe(
      map((response) => {
        return response.feature_flags;
      }),
    );
  }
}
