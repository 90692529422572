import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AccountState } from '@state/account/state';
import { TaskState } from '@tasks/state/state';
import { TaskListTemplate } from '@tasks/tasks.types';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskListTemplateService {
  private store = inject(Store);
  private httpClient = inject(HttpClient);
  private accountId: number;

  // TODO MJR, remove it once new apis is shipped
  get apiBaseUrl() {
    return this.store.selectSnapshot(TaskState.getApiBaseUrl);
  }

  constructor(private http: HttpClient) {
    // TODO MJR, remove it once new apis is shipped
    this.accountId = +this.store.selectSnapshot(AccountState.getAccount)?.id;
  }

  getTaskListTemplateById(taskTemplateId: number): Observable<TaskListTemplate> {
    return this.httpClient
      .get<{
        task_list_template: TaskListTemplate;
      }>(`task_list_templates/${taskTemplateId}?account_id=${this.accountId}`)
      .pipe(
        map(({ task_list_template }) => {
          return task_list_template;
        }),
      );
  }

  updateTaskListTemplate(taskTemplate: TaskListTemplate): Observable<TaskListTemplate> {
    return this.httpClient
      .put<{ task_list_template: TaskListTemplate }>(
        `task_list_templates/${taskTemplate.id}?account_id=${this.accountId}`,
        {
          task_list_template: taskTemplate,
        },
      )
      .pipe(map(({ task_list_template }) => task_list_template));
  }

  getTaskListTemplates(type): Observable<TaskListTemplate[]> {
    let params = { account_id: this.accountId };
    if (type) {
      params['applicable_type'] = type;
    }

    return this.httpClient.get<{ task_list_templates: TaskListTemplate[] }>(`task_list_templates`, { params }).pipe(
      map(({ task_list_templates }) => {
        return task_list_templates;
      }),
    );
  }
}
