export enum RelatedToType {
  Account = 'Account',
  Project = 'Project',
  Deal = 'Deal',
  Property = 'Property',
  Contact = 'Contact',
  Company = 'Company',
  Engagement = 'Engagement',
  PropertyTenant = 'PropertyTenant',
  PropertyUnit = 'PropertyUnit',
  DispositionGroupTransaction = 'DispositionGroupTransaction',
  Task = 'Task',
  AggregatedTasks = 'AggregatedTasks',
  LoanQuote = 'LoanQuote',
  FacilityReport = 'FacilityReport',
  TaskList = 'TaskList',
  FileResource = 'FileResource',
  User = 'User',
  CustomObject = 'CustomObject',
  CustomObjectValue = 'CustomObjects::Value',
  Unit = 'Unit',
  Lease = 'Lease',
  TimeSeries = 'TimeSeries',
  Note = 'Note',
  Offer = 'Offer',
  Trail = 'Trail',
  AssetManagement = 'AssetManagement',
  TaskListTemplate = 'TaskListTemplate',
  PropertyDeals = 'PropertyDeals',
}

export enum FileFormat {
  CSV = 'csv',
  PDF = 'pdf',
  XLSX = 'xlsx',
  DOCX = 'docx',
  PPTX = 'pptx',
}

export enum AccountIntegrationSlug {
  AppleConnect = 'apple_connect',
  Azure = 'azure',
  AzureConnect = 'azure_connect',
  Box = 'box',
  Connect = '_connect',
  Dropbox = 'dropbox',
  FacebookConnect = 'facebook_connect',
  GoogleConnect = 'google_connect',
  Onedrive = 'onedrive',
  Procore = 'procore',
  Slack = 'slack',
  Salesforce = 'salesforce',
  Zoom = 'zoom',
}

export enum InformativeSignoutCodes {
  NotAssociated = 'not_associated',
}
