import { Observable } from 'rxjs';
import { FieldDefGroup } from '.';

export const OperatorDisplayMap = Object.freeze({
  eq: 'Equals',
  gt: 'Greater Than',
  gte: 'Greater Than or Equals',
  lt: 'Less Than',
  lte: 'Less Than or Equals',
  contains: 'Contains',
  like: 'Like',
  in: 'In',
  not: 'Not',
  is: 'Is',
});

export type Operator = keyof typeof OperatorDisplayMap;

export type FieldType =
  | 'number'
  | 'money'
  | 'text'
  | 'percent'
  | 'select'
  | 'checkbox'
  | 'date'
  | 'datetime'
  | 'decimal'
  | 'textarea'
  | 'record'
  | 'url'
  | 'typeahead'
  | 'tags'
  | 'year'
  | 'id';

export interface FilterListItemModel {
  name: string;
  label: string;
  type: FieldType;
  meta: {
    filtering: { operators: Operator[]; functions?: string[] };
    options: string[] | { label: string; value: string }[];
    multi_select?: boolean;
    record_type?: string;
    scale?: number;
    group?: FieldDefGroup;
  };
}

export interface FilterListItemValueModel {
  fieldName: string;
  operator: Operator;
  value: any;
}

export interface FilterListItemChangeArgs extends FilterListItemValueModel {
  index: number;
}

export interface LookupContract {
  lookFor(model: LookupFilter): Observable<LookupResult[]>;
  createAndLink(model: LookupQuickAdd): Observable<any>;
  doesSupportCreate(relatedTo: string): boolean;
}

export interface LookupFilter {
  query: string;
  relatedTo: string;
}

export interface LookupResult {
  id: number;
  label: string;
}

export interface LookupQuickAdd {
  name: string;
  fieldName: string;
  recordType: string;
  relatedTo: string;
  linkedTo: { id: number; [key: string]: any };
  multiSelect: boolean;
}
