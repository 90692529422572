<ng-container #outlet [ngTemplateOutlet]="wrapper"></ng-container>

<ng-template #wrapper>
  @if (mode === 'context') {
    <div class="context-wrapper" [cdkContextMenuTriggerFor]="menu" [cdkContextMenuTriggerData]="{ items: items }">
      <ng-container *ngTemplateOutlet="content" />
    </div>
  } @else {
    <button class="flex" [cdkMenuTriggerFor]="menu" [cdkMenuTriggerData]="{ items: items }">
      <ng-container *ngTemplateOutlet="content" />

      <ts-icon
        *ngIf="!(hasCustomTrigger | async)"
        icon="more_vert"
        class="default-menu-trigger text-cool-gray-500 scale-110"
      ></ts-icon>
    </button>
  }
</ng-template>

<ng-template #content><ng-content></ng-content></ng-template>

<ng-template #menu let-items="items">
  <div
    cdkMenu
    (keydown.arrowUp)="onArrowUpOrDown()"
    (keydown.arrowDown)="onArrowUpOrDown()"
    (mouseover)="onMouseOver()"
    class="menu-wrapper"
  >
    <div class="bg-white rounded flex flex-col py-1 justify-start items-start border min-w-48 max-w-72">
      @for (item of items; track item.label) {
        @if (item?.children) {
          <div
            cdkMenuItem
            [cdkMenuTriggerFor]="menu"
            [cdkMenuTriggerData]="{ items: item.children }"
            class="w-full focus:bg-cool-gray-200"
          >
            <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: item }"></ng-container>
          </div>
        } @else {
          <div cdkMenuItem class="w-full focus:bg-cool-gray-200" (cdkMenuItemTriggered)="triggerAction(item)">
            <ng-container *ngTemplateOutlet="menuItem; context: { $implicit: item }"></ng-container>
          </div>
        }
      }
    </div>
  </div>
</ng-template>

<ng-template #menuItem let-item>
  <button
    tabindex="0"
    [disabled]="item.disabled && item?.disabled()"
    class="menu-item px-2 py-1 hover:bg-cool-gray-100 w-full"
  >
    <div class="flex flex-row justify-between gap-1">
      <div class="flex flex-row justify-start items-center gap-2 w-full">
        <ts-icon
          *ngIf="item?.icon"
          [icon]="item.icon"
          [color]="getIconColor(item?.variant)"
          class="w-fit-content text-cool-gray-500"
        ></ts-icon>
        <span class="truncate max-w-3/4" [ngClass]="item?.variant && 'text-' + item.variant">{{ item.label }}</span>
      </div>
      <ts-icon
        *ngIf="item.children && item.children.length > 0"
        icon="chevron_right"
        class="text-cool-gray-500"
      ></ts-icon>
    </div>
  </button>
</ng-template>
