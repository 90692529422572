<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- Modern -->
<modern-layout *ngIf="layout === 'modern'"></modern-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<treo-drawer
  [fixed]="true"
  name="customDrawer"
  position="right"
  mode="over"
  [transparentOverlay]="false"
  (openedChanged)="customDrawerOpenedChanged($event)"
  #customDrawer
  class="custom-drawer"
>
  <ng-template #childLoader></ng-template>
</treo-drawer>
