import { ChangeDetectorRef, Component, DestroyRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { environment } from '@env/environment';
import { User } from 'app/core/models/user.types';
import { UserService } from 'app/layout/common/user/user.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { EthanChat, EthanMessage } from '@core/models/ethan.types';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EthanState } from '../../../../state/ethan/ethan.state';
import {
  GetEthanChats,
  AskEthan,
  CreateEthanChat,
  DeleteEthanChat,
  GetEthanQuestions,
  UpdateEthanAnswer,
  SetSelectedChat,
} from '../../../../state/ethan/ethan.action';

import { EthanService } from '../../../../state/ethan/ethan.service';
import { calculateDialogPosition } from './floating-ethan-position.helper';
import { AccountFoldersService } from 'app/modules/folders/folders.service';
import { AccountState } from '@state/account/state';
import { FolderResource } from '@shared/modules/folders/folders.types';

@Component({
  selector: 'app-floating-ethan',
  templateUrl: './floating-ethan.component.html',
  styleUrls: ['./floating-ethan.component.scss'],
})
export class FloatingEthanComponent extends OnDestroyMixin implements OnInit {
  comment: Comment = {} as Comment;
  comments: Comment[] = [];
  public messages: any[] = [];
  public isLoading: boolean = false;
  public ethanQuestions: EthanMessage[] = [];
  public ethanStateQuestions: {
    [chatId: number]: { ethan_messages: EthanMessage[]; total: number; current_page: number; loading: boolean };
  } = {};
  public recentQuestion: any = null;
  public ethanForm: FormGroup;
  public reader: ReadableStreamDefaultReader<Uint8Array> | null = null;
  public streamData: string = '';
  public currentUser: User;
  public ethanChats: EthanChat[] = [];
  public searchControl = new FormControl();
  public newChatControl = new FormControl();
  public createNewInputVisible: boolean = false;
  public chatsLoading: boolean = true;

  public isFullScreen: boolean = false;
  private readonly CHAT_POSITION_KEY = 'chat-button-position';
  private readonly SELECTED_CHAT_KEY = 'ethan-selected-chat';
  private readonly DRAFT_MESSAGE_KEY = 'ethan-draft-message';
  private chatButtonPosition: { x: number; y: number };
  public selectedFile: File | null = null;
  public maxFileSize = 10 * 1024 * 1024; // 10MB max file size
  public folder: FolderResource;
  public ethanFolder: FolderResource;
  public selectedFileUrl: string = '';
  public fileUploading: boolean = false;
  public selectedChat: EthanChat | null = null;

  private scrollThreshold = 100; // pixels from top to trigger loading more

  @ViewChild('ethanChatContainer') ethanChatContainer!: ElementRef;
  @ViewChild('newChatInput') newChatInput!: ElementRef;
  @ViewChild('chatContent') chatContent!: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;

  ethanChats$: Observable<EthanChat[]> = this.store.select(EthanState.ethanChats);
  ethanQuestions$: Observable<{
    [chatId: number]: { ethan_messages: EthanMessage[]; total: number; current_page: number; loading: boolean };
  }> = this.store.select(EthanState.ethanQuestions);
  selectedChat$: Observable<EthanChat> = this.store.select(EthanState.selectedChat);

  constructor(
    private router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
    private readonly ethanService: EthanService,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly matDialogRef: MatDialogRef<FloatingEthanComponent>,
    private accountFoldersService: AccountFoldersService,
    private readonly destroyRef: DestroyRef,
  ) {
    super();
    this.ethanForm = this.formBuilder.group({
      question: [''],
    });
    const savedPosition = localStorage.getItem(this.CHAT_POSITION_KEY);
    this.chatButtonPosition = savedPosition ? JSON.parse(savedPosition) : { x: 24, y: window.innerHeight - 104 };
    this.accountFoldersService.accountId = this.store.selectSnapshot(AccountState.getAccount).id;
  }

  ngOnInit(): void {
    this.accountFoldersService
      .getFolder()
      .pipe(untilComponentDestroyed(this))
      .subscribe((folder: FolderResource) => {
        this.folder = folder;
      });
    // Restore selected chat from localStorage if exists
    const savedChat = localStorage.getItem(this.SELECTED_CHAT_KEY);
    if (savedChat) {
      this.store
        .dispatch(new SetSelectedChat(JSON.parse(savedChat)))
        .pipe(
          switchMap(() => this.store.dispatch(new GetEthanQuestions(JSON.parse(savedChat)?.id))),
          untilComponentDestroyed(this),
        )
        .subscribe(() => {
          this.scrollChatToBottom();
        });
    }

    this.ethanChats$.pipe(untilComponentDestroyed(this)).subscribe((ethanChats) => {
      this.ethanChats = ethanChats;
      this.chatsLoading = false;
      this.changeDetectorRef.markForCheck();
    });

    this.ethanQuestions$.pipe(untilComponentDestroyed(this)).subscribe((ethanQuestions) => {
      this.ethanStateQuestions = ethanQuestions;
      this.ethanQuestions = ethanQuestions[this.selectedChat?.id]?.ethan_messages || [];

      this.changeDetectorRef.markForCheck();
    });

    this.selectedChat$.pipe(untilComponentDestroyed(this)).subscribe((selectedChat) => {
      this.selectedChat = selectedChat;
      this.changeDetectorRef.markForCheck();
    });

    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchText) => this.store.dispatch(new GetEthanChats(searchText))),
        untilComponentDestroyed(this),
      )
      .subscribe(() => {
        this.createNewInputVisible = false;
        this.chatsLoading = false;
        this.changeDetectorRef.markForCheck();
      });

    this.userService.user$.pipe(untilComponentDestroyed(this)).subscribe((user: User) => {
      if (user) {
        this.currentUser = user;
      }
    });
    this.ethanForm.get('question').setValue(localStorage.getItem(this.DRAFT_MESSAGE_KEY));
    this.ethanForm
      .get('question')
      .valueChanges.pipe(untilComponentDestroyed(this))
      .subscribe((value) => {
        localStorage.setItem(this.DRAFT_MESSAGE_KEY, value);
      });
  }

  public closeEthan(): void {
    this.matDialogRef.close();
  }

  public openFullScreen(): void {
    this.router.navigate(['/ethan']);
    this.matDialogRef.close();
  }

  public async askQuestionToEthan(): Promise<void> {
    if (this.fileUploading) {
      return;
    }
    let question = this.ethanForm.get('question')?.value;
    this.ethanForm.get('question').setValue('');
    localStorage.removeItem(this.DRAFT_MESSAGE_KEY);

    if (question.trim()) {
      this.isLoading = true;

      this.store
        .dispatch(new AskEthan(question, this.selectedChat?.id, this.selectedFileUrl, this.selectedFile?.name))
        .pipe(
          untilComponentDestroyed(this),
          switchMap(() => {
            this.scrollChatToBottom();
            return this.ethanService.ethanPostStream(
              `${environment.ethanAssistant.url}/assistant/stream`,
              { input: question },
              this.currentUser,
              this.selectedChat?.id,
              this.selectedFile?.name ? [this.selectedFile.name] : [],
            );
          }),
        )
        .subscribe(
          async (stream) => {
            this.scrollChatToBottom();
            this.removeSelectedFile();
            this.reader = stream.getReader();
            const decoder = new TextDecoder();

            const readChunk = async () => {
              while (this.reader && this.isLoading) {
                try {
                  const { done, value } = await this.reader.read();
                  if (done) {
                    this.reader = null;
                    break;
                  }
                  const chunk = decoder.decode(value, { stream: true });
                  this.streamData += chunk;
                  this.ethanQuestions[this.ethanQuestions.length - 1].answer = this.streamData;
                } catch (error) {
                  console.error('Stream error:', error);
                  this.reader = null;
                  this.isLoading = false;
                  break;
                }
              }
              this.isLoading = false;
              this.store.dispatch(
                new UpdateEthanAnswer(
                  this.streamData,
                  this.selectedChat?.id,
                  this.ethanQuestions[this.ethanQuestions.length - 1]?.id,
                ),
              );
              this.streamData = '';
            };

            readChunk();
          },
          (error) => {
            console.error('Error:', error);
          },
        );
    }
  }

  public stopReading() {
    this.isLoading = false;
    if (this.reader) {
      this.reader.cancel();
      this.reader = null;
    }
  }

  public toggleScreen(chat_id: number): void {
    this.store.dispatch(new SetSelectedChat(this.ethanChats.find((ec) => ec.id === chat_id)));
    localStorage.setItem(this.SELECTED_CHAT_KEY, JSON.stringify(this.selectedChat));
    this.createNewInputVisible = false;

    this.store.dispatch(
      new GetEthanQuestions(
        this.selectedChat?.id,
        this.ethanStateQuestions[this.selectedChat?.id]?.current_page
          ? this.ethanStateQuestions[this.selectedChat?.id]?.current_page + 1
          : 1,
      ),
    );
  }

  public onBack(): void {
    this.store.dispatch(new SetSelectedChat(null));
    this.ethanQuestions = [];
    localStorage.setItem(this.SELECTED_CHAT_KEY, '');
    localStorage.setItem(this.DRAFT_MESSAGE_KEY, '');
    this.ethanForm.get('question').setValue(localStorage.getItem(this.DRAFT_MESSAGE_KEY));
  }

  public createNewChat() {
    const chatName = this.newChatControl.value.trim();
    this.store
      .dispatch(new CreateEthanChat(chatName))
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.createNewInputVisible = false;
        this.searchControl.reset();
      });
  }

  public onCreateNewClick() {
    const chatName = this.newChatControl?.value?.trim();
    if (this.createNewInputVisible && chatName) {
      this.createNewChat();
    } else {
      this.createNewInputVisible = true;
      setTimeout(() => {
        if (this.ethanChatContainer) {
          const element = this.ethanChatContainer.nativeElement;
          element.scrollTop = element.scrollHeight;
        }
      }, 0);
      setTimeout(() => {
        if (this.newChatInput) {
          this.newChatInput.nativeElement.focus();
        }
      }, 0);
    }
  }

  public hideInput() {
    this.createNewInputVisible = false;
  }

  public onDelete(chatId: number) {
    this.store
      .dispatch(new DeleteEthanChat(chatId))
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.ethanChats = this.ethanChats.filter((chat) => chat.id !== chatId);
        this.store.dispatch(new SetSelectedChat(null));
      });
  }
  private scrollChatToBottom(): void {
    try {
      if (this.chatContent) {
        const element = this.chatContent.nativeElement;
        element.scrollTop = 0;
      }
    } catch (err) {
      console.log(err);
    }
  }

  public async onFileSelected(event: Event, folderId?: number): Promise<void> {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];

      let fileResource = {
        folder_resource_id: folderId,
        name: file.name,
      };
      this.fileUploading = true;
      this.ethanFolder = this.folder.children.find((folder) => folder.name == 'Ethan');
      if (!this.ethanFolder) {
        this.ethanFolder = await this.accountFoldersService
          .createFolder({
            id: null,
            name: 'Ethan',
            parent_id: this.folder.id,
          })
          .toPromise();
      }

      this.accountFoldersService
        .createFile(this.ethanFolder.id, fileResource)
        .pipe(
          untilComponentDestroyed(this),
          switchMap((fileResource) =>
            this.accountFoldersService.uploadDocument(this.ethanFolder.id, fileResource.id, file),
          ),
          switchMap(async (document) => {
            this.selectedFileUrl = document.document_url;
            await this.ethanService.ethanSendDocument(
              this.selectedFile.name,
              this.selectedFileUrl,
              this.currentUser,
              this.selectedChat?.id,
            );
            return document;
          }),
        )
        .subscribe({
          next: () => {
            this.fileUploading = false;
          },
          error: (error) => {
            this.fileUploading = false;
            console.log('Error uploading file:', error);
          },
        });

      this.selectedFile = file;
      this.changeDetectorRef.markForCheck();
    }
  }

  public removeSelectedFile(): void {
    this.selectedFile = null;
    this.selectedFileUrl = '';
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
      this.fileInput.nativeElement.type = '';
      this.fileInput.nativeElement.type = 'file';
    }

    this.changeDetectorRef.markForCheck();
  }

  formatMarkdownWithCitations(markdown: string): string {
    if (!markdown) return '';

    const citations = new Map<string, string>();

    // Extract citations
    const citationRegex = /\[\^(\d+)\]:\s*(.*?)(?=\n|$)/g;
    const citationsText: RegExpMatchArray | null = markdown.match(citationRegex);

    citationsText?.forEach((citation: string) => {
      const match = citation.match(/\[\^(\d+)\]:\s*(.*)/);
      if (match) {
        citations.set(match[1], match[2].trim());
      }
    });

    // Remove citation definitions from main text
    let formattedText = markdown.replace(citationRegex, '');

    // Replace citation references with styled spans
    formattedText = formattedText.replace(/\[\^(\d+)\]/g, (_, num) => `<span class="citation-ref">[${num}]</span>`);

    // Add citations list at the end if there are any citations
    if (citations.size > 0) {
      formattedText += '\n\n<div class="citations-list">\n';
      citations.forEach((text, num) => {
        formattedText += `<p class="citation-item"><span class="citation-number">[${num}]</span> ${text}</p>\n`;
      });
      formattedText += '</div>';
    }

    return formattedText;
  }

  public onScroll(event: Event): void {
    const element = event.target as HTMLElement;

    // Check if user has scrolled near the top
    if (
      element.scrollHeight + element.scrollTop - element.clientHeight < this.scrollThreshold &&
      !this.ethanStateQuestions[this.selectedChat?.id]?.loading
    ) {
      this.loadMoreMessages();
    }
  }

  private loadMoreMessages(): void {
    // Don't load more if already loading
    if (this.ethanStateQuestions[this.selectedChat?.id]?.loading) {
      return;
    }

    if (this.selectedChat?.id) {
      this.ethanStateQuestions[this.selectedChat.id].loading = true;

      // Call your service to load more messages
      this.store.dispatch(
        new GetEthanQuestions(this.selectedChat.id, this.ethanStateQuestions[this.selectedChat.id].current_page + 1),
      );
    }
  }
}
