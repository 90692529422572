import {
  LicenseManager,
  CellEditingStoppedEvent,
  GridApi,
  GetContextMenuItemsParams,
  FirstDataRenderedEvent,
  SelectionChangedEvent,
  GridOptions,
} from 'ag-grid-enterprise';

import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridReadyEvent, CellClickedEvent, ColumnEvent } from 'ag-grid-community';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { TSIconModule } from '../ts-icon';
import { TableActionsRendererComponent } from './renderer-components/table-actions-renderer.component';
import { TsTableService } from '../services';
import { ColumnDef, TsTableOptions } from './ts-table.model';
import { TableActions } from '../cross-cutting/models/table-actions';
import { isEqual } from 'lodash';
import { TableOpenDetailsRendererComponent } from '@shared/components/ts-table-renderers/table-open-details-renderer.component';
import { environment } from '@env/environment';
import { BehaviorSubject, debounceTime, Observable, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Dictionary } from 'tsui/public-api';

LicenseManager.setLicenseKey(environment.agGridLicenseKey);

@Component({
  selector: 'ts-table',
  templateUrl: './ts-table.component.html',
  styleUrls: ['./ts-table.component.scss'],
  standalone: true,
  imports: [TSIconModule, AgGridAngular, AgGridModule],
})
export class TsTableComponent implements OnInit {
  @Input() readOnly: boolean = false;

  private _columnDefs: ColumnDef[] = [];
  @Input()
  set columnDefs(value: ColumnDef[]) {
    if (!isEqual(this._columnDefs, value)) {
      this._columnDefs = value;
      this.setColDefs();
    }
  }

  get columnDefs(): ColumnDef[] {
    return this._columnDefs;
  }

  private _gridOptions: GridOptions = {
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      return [];
    },
  };

  @Input()
  set gridOptions(value: GridOptions) {
    this._gridOptions = {
      ...this._gridOptions,
      ...value,
    };
  }

  get gridOptions(): GridOptions {
    return this._gridOptions;
  }

  @Input() set rowData(value: any[]) {
    if (!isEqual(this._rowData, value)) {
      this._rowData = value;
    }
  }

  get rowData() {
    return this._rowData;
  }

  private _rowData: any[] = [];

  @Input() set loading(val: boolean) {
    if (this.gridApi) {
      this.gridApi.setGridOption('loading', val);
    }
  }

  @Input() tsTableOptions: TsTableOptions = {};
  @Input() rowSelection = 'multiple';
  @Input() hasActionsColumn: boolean = false;
  @Input() hasCheckboxColumn: boolean = false;
  @Input() hasOpenDetailsColumn: boolean = false;
  @Input() checkboxSelection: boolean = false;
  @Input() headerCheckboxSelection: boolean = true;
  @Input() suppressDragLeaveHidesColumns: boolean = true;
  @Input() actions: TableActions[] = [];
  @Input() relatedToRedirectConfig: any = {};
  @Input() suppressRowClickSelection: boolean = true;
  @Input() data?: any;
  @Input() enableCellChangeFlash: boolean = false;
  @Input() checkboxColumnChanged: EventEmitter<boolean>;
  @Input() autoHeight: boolean = true;
  @Input() autoSizeAllColumns: boolean = false;
  @Input() getRowId;
  @Input() tooltipShowDelay = 0;
  @Input() optionsFilterFn: Dictionary<(options: any[]) => any[]> = {};
  @Input() clientSorting = false;

  onColumnMovedSubject$ = new Subject<ColumnEvent>();
  @Output() onColumnMove: Observable<ColumnEvent> = this.onColumnMovedSubject$.pipe(debounceTime(500));

  @Output() onRecordClick: EventEmitter<any> = new EventEmitter();
  @Output() cellClicked = new EventEmitter<CellClickedEvent>();
  @Output() cellEditingStopped = new EventEmitter<CellEditingStoppedEvent>();
  @Output() sortChanged: EventEmitter<any> = new EventEmitter();
  @Output() dragStopped: EventEmitter<any> = new EventEmitter();
  @Output() rowDragEnd: EventEmitter<any> = new EventEmitter();

  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
  @Output() openDetails: EventEmitter<any> = new EventEmitter();
  @Output() onFirstDataRendered: EventEmitter<any> = new EventEmitter();
  @Output() gridReady: EventEmitter<any> = new EventEmitter();
  @Output() cellValueChanged: EventEmitter<any> = new EventEmitter();
  @Output() columnResized: EventEmitter<any> = new EventEmitter();
  @Output() gridColumnsChanged: EventEmitter<any> = new EventEmitter();
  @Output() cellEditingStarted: EventEmitter<any> = new EventEmitter();

  colDefs: ColDef[] = [];
  private headerContainer: Element | null = null;
  private bodyContainer: Element | null = null;
  private wheelHandler = (e: WheelEvent) => {};
  readonly document = inject(DOCUMENT);
  private gridApi!: GridApi<any>;

  constructor(private tsTableService: TsTableService) {}

  static get ActionsColumnName(): string {
    return 'actions';
  }

  static getActionColumnDef(actions: TableActions[]): ColDef {
    return {
      headerName: '',
      field: TsTableComponent.ActionsColumnName,
      pinned: 'right',
      lockPinned: true,
      sortable: false,
      lockPosition: 'right',
      resizable: false,
      suppressMovable: true,
      width: 50,
      maxWidth: 50,
      suppressHeaderMenuButton: true,
      cellRenderer: TableActionsRendererComponent,
      cellRendererParams: {
        actions: actions,
      },
      cellStyle: {
        overflow: 'visible',
        'text-overflow': 'clip',
        'white-space': 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    };
  }

  ngOnInit(): void {
    this.setColDefs();
    this.checkboxColumnChanged?.subscribe((hasCheckbox) => {
      this.hasCheckboxColumn = hasCheckbox;
      this.setColDefs();
    });
  }

  handleRecordClick(event: any): void {
    this.onRecordClick.emit(event);
  }

  handleColumnMove(event: ColumnEvent): void {
    // When the user visits PAGE A with columns in the order C1, C2, C3,
    // and then goes to PAGE B with columns in the order C1, C3, C2,
    // ag-Grid triggers the 'columnMoved' event because the column order is different.
    // We only allow the event to propagate if it was triggered by the user.

    if (event.source === 'uiColumnMoved') {
      this.onColumnMovedSubject$.next(event);
    }
  }

  onDragStopped(event: any): void {
    this.dragStopped.emit(event);
  }

  onRowDragEnd(event: any): void {
    this.rowDragEnd.emit(event);
  }

  onCellEditingStarted(event: any): void {
    this.cellEditingStarted.emit(event);
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (
      event.newValue === undefined &&
      !(event.colDef.context.type === 'record' && !event.colDef.context.meta.multi_select)
    ) {
      event.node.setDataValue(event.colDef?.field, event.oldValue);
      return;
    }

    if (event.colDef?.field === 'tag_list') {
      this.cellEditingStopped.emit(event);
    }
    const normalizeValue = (value: any): any => {
      if (!isNaN(parseFloat(value)) && isFinite(value)) {
        return parseFloat(value);
      }
      return value;
    };

    const oldValueNormalized = normalizeValue(event.oldValue);
    const newValueNormalized = normalizeValue(event.newValue);
    if (Array.isArray(event.value)) {
      isEqual(event.oldValue, event.newValue) ? null : this.cellEditingStopped.emit(event);
    } else if (oldValueNormalized !== newValueNormalized) {
      Object.keys(event.data || {}).forEach((key) => (event.data[key] === undefined ? (event.data[key] = null) : null));
      this.cellEditingStopped.emit(event);
    }
  }

  onSortChanged(event: any) {
    this.sortChanged.emit(event);
  }

  setColDefs(): void {
    this.colDefs = this.tsTableService.convertFieldDefsToColDefs(
      this.columnDefs,
      this.relatedToRedirectConfig,
      this.data,
      this.readOnly,
      this.optionsFilterFn,
      this.clientSorting,
    );

    if (this.hasCheckboxColumn) {
      this.setCheckboxColumn();
    }

    this.setOpenDetailsColumn();
    this.setActionsColumn();
  }

  setCheckboxColumn(): void {
    if (this.hasCheckboxColumn && this.colDefs[0]?.field !== 'checkbox') {
      this.colDefs?.unshift({
        headerName: '',
        field: 'checkbox',
        width: 50,
        headerCheckboxSelection: this.headerCheckboxSelection,
        checkboxSelection: (params) => {
          return !params.data?.isCategory;
        },
        rowDrag: false,
        pinned: 'left',
        lockPinned: true,
        sortable: false,
        resizable: false,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        suppressSizeToFit: true,
      });
    }
  }

  setActionsColumn(): void {
    if (
      this.actions.length > 0 &&
      this.colDefs.length > 0 &&
      this.colDefs[this.colDefs.length - 1].field !== 'actions'
    ) {
      this.colDefs.push(TsTableComponent.getActionColumnDef(this.actions));
    }
  }

  setOpenDetailsColumn(): void {
    if (this.hasOpenDetailsColumn && !this.colDefs.some((col) => col.field === 'openDetails')) {
      this.colDefs.unshift({
        headerName: '',
        field: 'openDetails',
        pinned: 'left',
        lockPinned: true,
        sortable: false,
        resizable: false,
        suppressMovable: true,
        rowDrag: false,
        width: 50,
        maxWidth: 50,
        suppressHeaderMenuButton: true,
        cellRenderer: TableOpenDetailsRendererComponent,
        cellRendererParams: {
          onOpenDetails: (data) => this.onOpenDetails(data),
        },
        cellStyle: {
          overflow: 'visible',
          'text-overflow': 'clip',
          'white-space': 'nowrap',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      });
    }
  }

  onCellClicked(event: CellClickedEvent): void {
    this.cellClicked.emit(event);
  }

  sizeColumnsToFit(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  autoSizeColumns(): void {
    if (this.gridApi && this.autoSizeAllColumns) {
      this.gridApi.autoSizeAllColumns();
    }
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectionChanged.emit(selectedRows);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridReady.emit(params);
    this.handleScroll();
  }

  onOpenDetails(data: any): void {
    this.openDetails.emit(data);
  }

  firstDataRendered(params: FirstDataRenderedEvent): void {
    this.autoSizeColumns();
    this.onFirstDataRendered.emit(params);
  }

  onCellValueChanged(event: any): void {
    this.cellValueChanged.emit(event);
  }

  onColumnResized(event: any) {
    if (event.finished) {
      this.columnResized.emit(event);
    }
  }

  onVirtualColumnsChanged(event): void {
    this.autoSizeColumns();
  }

  // This method is used to handle horizontal and vertical scrolling of the table, because currently, ag-grid only supports horizontal scrolling
  // in content areas. So, if you try to scroll when hovering the header or the empty area under the table (in case of tables with few rows), the table won't be scrollable.
  // This method is a workaround to fix this issue.
  // https://github.com/ag-grid/ag-grid/issues/4613
  // https://github.com/ag-grid/ag-grid/issues/2634
  handleScroll() {
    const verticalScrollingContainer = this.document.getElementsByClassName('ag-body-viewport')[0];
    const horizontalScrollingContainer = this.document.getElementsByClassName('ag-center-cols-viewport')[0];

    this.headerContainer = this.document.getElementsByClassName('ag-header-container')[0];
    this.bodyContainer = this.document.getElementsByClassName('ag-body')[0];
    if (this.headerContainer && verticalScrollingContainer && horizontalScrollingContainer) {
      this.wheelHandler = (e: WheelEvent) => {
        e.preventDefault();
        if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
          horizontalScrollingContainer.scrollLeft += e.deltaX;
        } else {
          verticalScrollingContainer.scrollTop += e.deltaY;
        }
      };
      this.headerContainer.addEventListener('mousewheel', this.wheelHandler);
      this.bodyContainer.addEventListener('mousewheel', this.wheelHandler);
    }
  }
}
