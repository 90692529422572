import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core';
import { CdkContextMenuTrigger, CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { TSIconModule } from '../ts-icon';
import { AsyncPipe, NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

export interface TsMenuItem {
  label: string;
  icon?: string;
  disabled?: (...args) => boolean;
  action?: () => void | Promise<void>;
  children?: TsMenuItem[];
  variant?: 'primary' | 'danger';
}

@Component({
  selector: 'ts-menu',
  standalone: true,
  imports: [
    CdkMenuItem,
    CdkMenu,
    TSIconModule,
    NgIf,
    NgClass,
    NgTemplateOutlet,
    CdkMenuTrigger,
    AsyncPipe,
    CdkContextMenuTrigger,
  ],
  templateUrl: './ts-menu.component.html',
  styles: `
    :host {
      .context-wrapper {
        all: inherit;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsMenuComponent implements AfterViewInit {
  @Input({ required: true }) set items(val: TsMenuItem[]) {
    this._items = val;

    this.rerender();
  }
  @Input() mode: 'button' | 'context' = 'button';

  @ViewChild(CdkMenuTrigger, { read: ElementRef }) trigger: ElementRef;
  @ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
  @ViewChild('wrapper', { read: TemplateRef }) wrapperRef: TemplateRef<any>;
  @ViewChildren(CdkMenuItem, { read: ElementRef }) menuItems: ElementRef[];

  readonly hasCustomTrigger = new BehaviorSubject<boolean>(false);
  readonly renderer = inject(Renderer2);
  _items: TsMenuItem[] = [];

  get items() {
    return this._items;
  }

  ngAfterViewInit() {
    const hasCustomTrigger = this.trigger?.nativeElement?.childElementCount > 1;
    this.hasCustomTrigger.next(hasCustomTrigger);
  }

  getIconColor(variant: 'primary' | 'danger') {
    if (variant === 'danger') {
      return 'red';
    }

    if (variant === 'primary') {
      return 'orange';
    }

    return undefined;
  }

  triggerAction(item: any) {
    if (item.action) {
      // hack: setTimeout is needed to prevent the menu from closing before the action is executed on keyboard events
      // some actions that open dialogs won't work without this
      setTimeout(() => {
        item.action();
      }, 100);
    }
  }

  // Solution for  [cdkMenuMatTriggerData] not responding to changes
  // ref: https://github.com/angular/components/issues/26256
  rerender() {
    this.outletRef?.clear();
    this.outletRef?.createEmbeddedView(this.wrapperRef);
  }

  onMouseOver() {
    this.menuItems.forEach((item) => {
      this.renderer.addClass(item.nativeElement, 'hover:bg-gray-100');
      this.renderer.removeClass(item.nativeElement, 'focus:bg-cool-gray-200');
    });
  }

  onArrowUpOrDown() {
    this.menuItems.forEach((item) => {
      this.renderer.addClass(item.nativeElement, 'focus:bg-cool-gray-200');
      this.renderer.removeClass(item.nativeElement, 'hover:bg-gray-100');
    });
  }
}
