import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SavedView } from 'app/core/models';
import { map, Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export default class SavedViewService extends BaseService {
  constructor(protected readonly httpClient: HttpClient) {
    super(httpClient);
  }

  get(): Observable<SavedView[]> {
    return this.httpClient.get<any>(`saved_views`).pipe(map(({ saved_views }) => saved_views));
  }

  create(savedView: Omit<SavedView, 'id'>): Observable<SavedView> {
    return this.httpClient
      .post<any>(`saved_views`, { saved_view: savedView })
      .pipe(map(({ saved_view }) => saved_view));
  }

  update(savedView: SavedView): Observable<SavedView> {
    return this.httpClient
      .put<any>(`saved_views/${savedView.id}`, { saved_view: savedView })
      .pipe(map(({ saved_view }) => saved_view));
  }

  delete(savedViewId: string): Observable<SavedView> {
    return this.httpClient.delete<any>(`saved_views/${savedViewId}`);
  }
}
