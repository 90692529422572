export namespace MessagesUtils {
  export const Actions = {
    LOAD: (scope: string, entity: string) => `[${scope}] Load ${entity}`,
    CREATE: (scope: string, entity: string) => `[${scope}] Create ${entity}`,
    UPDATE: (scope: string, entity: string) => `[${scope}] Update ${entity}`,
    DELETE: (scope: string, entity: string) => `[${scope}] Delete ${entity}`,
    IMPORT: (scope: string, entity: string) => `[${scope}] Import ${entity}`,
    CUSTOM: (scope: string, message: string) => `[${scope}] ${message}`,
  };

  export const Success = {
    CREATE: (entity: string) => `${entity} created successfully.`,
    UPDATE: (entity: string) => `${entity} updated successfully.`,
    DELETE: (entity: string) => `${entity} deleted successfully.`,
    LOAD: (entity: string) => `${entity} loaded successfully.`,
    IMPORT: (entity: string) => `${entity} imported successfully.`,
  };

  export const Error = {
    CREATE: (entity: string) => `Failed to create ${entity}. Please try again.`,
    UPDATE: (entity: string) => `Failed to update ${entity}. Please try again.`,
    DELETE: (entity: string) => `Failed to delete ${entity}. Please try again.`,
    LOAD: (entity: string) => `Failed to load ${entity}. Please try again.`,
    IMPORT: (entity: string) => `Failed to import ${entity}. Please try again.`,
  };
}
