import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { Company, CompanyPagination } from './companies.types';
import { Project, ProjectPagination, Property } from 'app/modules/projects/projects.types';
import { LoanQuote } from '../projects/details/loans/loans.types';
import { Contact } from '../contacts/contacts.types';
import { PropertyPagination } from '../properties/properties.types';
import { Task } from 'app/shared/modules/legacy-tasks/tasks.types';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  private _company: BehaviorSubject<Company | null>;
  private _companies: BehaviorSubject<Company[] | null>;
  private _pagination: BehaviorSubject<CompanyPagination | null>;

  private _accountId: string;

  constructor(private _httpClient: HttpClient) {
    this._company = new BehaviorSubject(null);
    this._companies = new BehaviorSubject(null);
    this._pagination = new BehaviorSubject(null);
  }

  get company$(): Observable<Company> {
    return this._company.asObservable();
  }

  get companies$(): Observable<Company[]> {
    return this._companies.asObservable();
  }

  get pagination$(): Observable<CompanyPagination> {
    return this._pagination.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  getCompanies(
    page: number = 1,
    size: number = 20,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'asc',
    search: string = '',
    filters: any = {},
    csv = false,
    dontEmitValues = false,
  ): Observable<{ pagination: CompanyPagination; companies: Company[] }> {
    const { name = '', website = '' } = filters;
    //set the page number to 1 if 0 as can happen in pagination
    if (page < 1) {
      page = 1;
    }
    return this._httpClient
      .get<{ pagination: CompanyPagination; companies: Company[] }>(`companies`, {
        params: {
          page: '' + page,
          per_page: '' + size,
          sort: sort,
          order: order,
          q: search,
          name: name,
          website: website,
        },
      })
      .pipe(
        map((response: any) => {
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          let companies = response.results;
          if (!dontEmitValues) {
            this._pagination.next(pagination);
            this._companies.next(companies);
          }

          return {
            pagination,
            companies,
          };
        }),
      );
  }

  search(
    filter: {
      fjson: string;
      sort?: string;
      order?: string;
      q?: string;
      project_id?: number;
      tags?: string;
      select?: string[];
    },
    page: number,
    pageSize: any,
    csv = false,
    savedViewId?: number,
  ): Observable<{ results?: Company[]; data?: string & { message: string } }> {
    if (page < 1) {
      page = 1;
    }

    if (!filter.project_id) {
      delete filter.project_id;
    }

    let params = {
      ...filter,
      page,
      per_page: pageSize,
    };

    if (savedViewId) {
      params = Object.assign(params, { saved_view_id: savedViewId });
    }

    let url = `companies/search${csv ? '.csv' : ''}`;

    if (!csv && filter?.select) {
      url += `?select=${filter.select.join(',')}`;
    }

    return this._httpClient.post(url, params).pipe(
      tap((response: any) => {
        if (csv && response) {
          return response?.data;
        }
        const pagination = {
          length: response?.count,
          size: pageSize,
          page: page - 1,
          lastPage: response?.previous,
          startIndex: page * pageSize,
          endIndex: Math.min(pageSize * (page + 1), response?.count),
        };

        let companies = response?.results;
        this._pagination.next(pagination);
        this._companies.next(companies);
      }),
    );
  }

  searchCompany(query: string): Observable<Company[] | null> {
    return this._httpClient
      .get<Company[] | null>('companies/search', {
        params: { query },
      })
      .pipe(
        tap((companies) => {
          this._companies.next(companies);
        }),
      );
  }

  getCompanyById(id: string): Observable<Company> {
    return this._httpClient.get<any>(`companies/${id}`).pipe(
      map((response) => {
        this._company.next(response.company);
        return response.company;
      }),
    );
  }

  createCompany(company: Company): Observable<Company> {
    return this.companies$.pipe(
      take(1),
      switchMap((companies) =>
        this._httpClient.post<{ company: Company }>(`companies`, { company: company }).pipe(
          map(({ company }) => {
            if (companies) {
              this._companies.next([company, ...companies]);
            }

            return company;
          }),
        ),
      ),
    );
  }

  updateCompany(company: Company): Observable<Company> {
    return this.companies$.pipe(
      take(1),
      switchMap((companies: Array<Company>) =>
        this._httpClient
          .patch<{ company: Company }>(`companies/${company.id}`, {
            company,
          })
          .pipe(
            map((response) => {
              let updatedCompany = response.company;

              if (companies) {
                // Find the index of the updated company
                const index = companies.findIndex((item) => item.id === updatedCompany.id);

                // Update the company
                companies[index] = updatedCompany;

                // Update the companies
                this._companies.next(companies);
              }
              this._company.next(updatedCompany);
              // Return the updated company
              return updatedCompany;
            }),
            switchMap((updatedCompany) =>
              this.company$.pipe(
                take(1),
                filter((item) => item && item.id === updatedCompany.id),
                tap(() => {
                  // Update the company if it's selected
                  this._company.next(updatedCompany);

                  // Return the updated company
                  return updatedCompany;
                }),
              ),
            ),
          ),
      ),
    );
  }

  deleteCompany(id: string): Observable<boolean> {
    return this.companies$.pipe(
      take(1),
      switchMap((companies) =>
        this._httpClient.delete(`companies/${id}`).pipe(
          map((isDeleted: boolean) => {
            if (companies) {
              // Find the index of the deleted company
              const index = companies.findIndex((item) => item.id === id);

              // Delete the company
              companies.splice(index, 1);

              // Update the companies
              this._companies.next(companies);
            }

            // Return the deleted status
            return isDeleted;
          }),
        ),
      ),
    );
  }

  uploadAvatar(companyId: string, file: File): Observable<Company> {
    const uploadData = new FormData();
    uploadData.append('image[attachment]', file);

    return this._httpClient.post<{ company: Company }>(`companies/${companyId}/avatar`, uploadData).pipe(
      map(({ company }) => {
        this._company.next(company);
        return company;
      }),
    );
  }

  deleteAvatar(companyId: string): Observable<Company> {
    return this._httpClient.delete<{ company: Company }>(`companies/${companyId}/avatar`).pipe(
      map(({ company }) => {
        this._company.next(company);
        return company;
      }),
    );
  }

  /**
   * Get projects related to a company
   */
  getProjects(
    companyId: string,
    page: number = 1,
    size: number = 10,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'desc',
  ): Observable<{ pagination: ProjectPagination; projects: Project[] }> {
    return this._httpClient
      .get<{ count: number; next: string; offset: number; previous: string; projects: Project[] }>(
        `companies/${companyId}/projects`,
        {
          params: {
            page: page,
            per_page: size,
            sort: sort,
            order: order,
          },
        },
      )
      .pipe(
        map((response) => {
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const projects = response.projects;

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          return {
            projects,
            pagination,
          };
        }),
      );
  }

  getProperties(
    companyId: string,
    page: number = 1,
    size: number = 10,
    sort = 'name',
    order: 'asc' | 'desc' | '' = 'desc',
  ): Observable<{ pagination: PropertyPagination; properties: Property[] }> {
    return this._httpClient
      .get<{ count: number; next: string; offset: number; previous: string; properties: Property[] }>(
        `companies/${companyId}/properties`,
        {
          params: {
            page: page,
            per_page: size,
            sort: sort,
            order: order,
          },
        },
      )
      .pipe(
        map((response) => {
          const lastPage = Math.max(Math.ceil(response.count / size), 1);
          const begin = page * size;
          const end = Math.min(size * (page + 1), response.count);

          const properties = response.properties;

          const pagination = {
            length: response.count,
            size: size,
            page: page - 1,
            lastPage: lastPage - 1,
            startIndex: begin,
            endIndex: end,
          };

          return {
            properties,
            pagination,
          };
        }),
      );
  }

  getContacts(companyId: string): Observable<Contact[]> {
    return this._httpClient
      .get<{ contacts: Contact[] }>(`companies/${companyId}/contacts`)
      .pipe(map(({ contacts }) => contacts));
  }

  getLoanQuotes(companyId: string): Observable<LoanQuote[]> {
    return this._httpClient
      .get<{ loan_quotes: LoanQuote[] }>(`companies/${companyId}/loan_quotes`)
      .pipe(map(({ loan_quotes }) => loan_quotes));
  }

  createTask(companyId: string, taskListId: number, task: Task): Observable<Task> {
    const body = { task };
    return this._httpClient.post<{ task: Task }>(`companies/${companyId}/task_lists/${taskListId}/tasks`, body).pipe(
      map((response) => {
        return response.task;
      }),
    );
  }
}
