import { AccountService, AuthService } from './core';
import { Observable, of, switchMap, tap } from 'rxjs';
import { getSubdomain } from './shared/Utils/common.utils';
import { Permission } from './core/enums';
import { Store } from '@ngxs/store';
import { AccountActions } from './state/account/actions';
import { SetPermissions, SetUser, SetUsers } from './state/user/actions';
import { ObjectsManagerActions } from './modules/settings/objects-manager/state/actions';
import { LicenseManager } from 'ag-grid-enterprise';
import { GetEthanChats } from './state/ethan/ethan.action';
import { FeatureFlag } from './core/enums';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-064514}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TermSheet}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TermSheet}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{TermSheet}_need_to_be_licensed___{TermSheet}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{2_August_2025}____[v3]_[01]_MTc1NDA4OTIwMDAwMA==a6ae0c4c52035689fb1c57902d2bf306',
);

export function appInitializer(
  authService: AuthService,
  accountService: AccountService,
  store: Store,
): () => Observable<any> {
  return () => {
    const subdomain = getSubdomain();

    if (!subdomain) {
      return of(null);
    }

    // SC-29360 - Clearing the old task filters from local storage
    localStorage.removeItem('projectTasksQueryFilters');
    localStorage.removeItem('propertyTasksQueryFilters');
    localStorage.removeItem('globalTaskQueryFilters');

    return authService.check().pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return accountService.getAccount(subdomain).pipe(
            tap((account) => {
              accountService.setAccount(account);
              return store.dispatch(new AccountActions.SetAccount(account));
            }),
            switchMap((account) => {
              return account.features.includes(FeatureFlag.ETHAN) ? store.dispatch(new GetEthanChats()) : of(null);
            }),
            switchMap(() => {
              return store.dispatch(new ObjectsManagerActions.Get());
            }),
            switchMap(() => {
              return accountService.getUser();
            }),
            switchMap((user) => {
              store.dispatch(new SetUser(user));
              return accountService.getUsers();
            }),
            tap((users) => {
              store.dispatch(new SetUsers(users));
            }),
          );
        }

        return of(null);
      }),
    );
  };
}
