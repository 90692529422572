export enum FeatureFlag {
  ACCOUNT_HOOKS = 'account_hooks',
  ARCHIVE_PROJECT = 'archive_project',
  ASSET_MANAGEMENT = 'asset_management_accounting',
  AUTOMATIONS = 'automations',
  COMPLETED_AT_FOR_PREDECESSORS = 'completed_at_for_predecessors',
  CUSTOM_OBJECTS = 'custom_objects',
  DISPOSITIONS_TAB = 'dispositions_tab',
  EMAIL_ENGAGEMENTS = 'email_engagements',
  ETHAN = 'ethan',
  FACILITY_REPORTS = 'facility_reports',
  INSIGHTS_ANALYTICS = 'insights_analytics',
  KANBAN = 'kanban',
  LEASES = 'leases',
  LINKED_TASKS = 'linked_tasks',
  LOAN_QUOTES = 'loan_quotes',
  NESTED_FILTERS = 'nested_filters',
  NEW_PERMISSIONS = 'new_permissions',
  NEW_TABLE = 'new_table',
  PIPELINE_MAP = 'pipeline_map',
  PROPERTIES = 'properties',
  REPORTS_TAB = 'reports_tab',
  SORT_VISUALIZATION_KEYS = 'sort_visualization_keys',
  SUMMARY_FORMULAS = 'summary_formulas',
  TABLE_REPORT_VISUALIZATION = 'table_report_visualization',
  TASKS_CUSTOM_FIELDS = 'tasks_custom_fields',
  TASK_APPROVAL_FLOW = 'task_approval_flow',
}
