export function calculateDialogPosition(
  chatButtonPosition: { x: number; y: number },
  dialogWidth: number = 450,
  dialogHeight: number = 600,
  padding: number = 24,
  buttonWidth: number = 50,
  buttonHeight: number = 50,
) {
  // Calculate distances to edges
  const distanceToLeft = chatButtonPosition.x;
  const distanceToRight = window.innerWidth - (chatButtonPosition.x + buttonWidth);
  const distanceToTop = chatButtonPosition.y;
  const distanceToBottom = window.innerHeight - (chatButtonPosition.y + buttonHeight);

  // Find the nearest edge
  const distances = [
    { edge: 'left', distance: distanceToLeft },
    { edge: 'right', distance: distanceToRight },
    { edge: 'top', distance: distanceToTop },
    { edge: 'bottom', distance: distanceToBottom },
  ];

  const nearestEdge = distances.reduce((prev, curr) => (curr.distance < prev.distance ? curr : prev));

  let position: { top?: string; left?: string } = {};

  // Position dialog based on nearest edge
  if (nearestEdge.edge === 'left') {
    position.left = `${chatButtonPosition.x + buttonWidth + padding}px`;
    position.top = `${chatButtonPosition.y - dialogHeight / 2}px`;
  } else if (nearestEdge.edge === 'right') {
    position.left = `${chatButtonPosition.x - dialogWidth - padding}px`;
    position.top = `${chatButtonPosition.y - dialogHeight / 2}px`;
  } else if (nearestEdge.edge === 'top') {
    position.top = `${chatButtonPosition.y + padding}px`;
    position.left = `${chatButtonPosition.x - dialogWidth / 2 + buttonWidth / 2}px`;
  } else if (nearestEdge.edge === 'bottom') {
    position.top = `${chatButtonPosition.y - dialogHeight - padding - buttonHeight}px`;
    position.left = `${chatButtonPosition.x - dialogWidth / 2 + buttonWidth / 2}px`;
  }

  // Ensure position values are numeric for comparisons
  const leftValue = parseFloat(position.left);
  const topValue = parseFloat(position.top);

  // Ensure dialog stays within viewport bounds
  if (leftValue < padding) {
    position.left = `${padding}px`;
  } else if (leftValue + dialogWidth > window.innerWidth - padding) {
    position.left = `${window.innerWidth - dialogWidth - padding}px`;
  }

  if (topValue < padding) {
    position.top = `${padding}px`;
  } else if (topValue + dialogHeight > window.innerHeight - padding) {
    position.top = `${window.innerHeight - dialogHeight - padding}px`;
  }

  return position;
}
