import { inject, Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { SavedView } from 'app/core/models';
import { mergeMap, tap } from 'rxjs';

import { SavedViewsActions } from './action';
import { AccountService } from '@core/services';
import SavedViewService from '@services/saved-view.service';

import { PermissionUtils } from '@core/utils/permission.utils';
import { SavedViewsSidebarService } from '@shared/services/saved-views-sidebar.service';
import { RelatedToType } from '@shared/enums';

export interface SavedViewsStateModel {
  savedViews: SavedView[];
}

@State<SavedViewsStateModel>({
  name: new StateToken('savedViewsState'),
  defaults: {
    savedViews: null,
  },
})
@Injectable()
export class SavedViewsState {
  protected accountService = inject(AccountService);
  protected savedViewService = inject(SavedViewService);
  protected store = inject(Store);

  @Selector()
  static getViews(state: SavedViewsStateModel) {
    return state?.savedViews;
  }

  static getAllowedSortedRelatedToTypeViews(relatedToType: string) {
    return createSelector([SavedViewsState.getViews], (savedViews: SavedView[]): SavedView[] => {
      return (
        savedViews
          ?.filter((savedView) => savedView?.related_to_type === relatedToType)
          .filter(PermissionUtils.canView)
          .sort(SavedViewsSidebarService.sortSavedViews) || []
      );
    });
  }
  static getLastViewFromType(type: RelatedToType) {
    return createSelector([SavedViewsState], (state: SavedViewsStateModel) => {
      const [lastView] = state.savedViews
        .filter((savedView) => savedView.related_to_type === type)
        .sort((a, b) => b.id - a.id);
      return lastView;
    });
  }

  @Action(SavedViewsActions.LoadViews)
  getSavedViews(context: StateContext<SavedViewsStateModel>) {
    return this.savedViewService.get().pipe(
      tap((savedViews) => {
        context.setState({ ...context.getState(), savedViews });
      }),
    );
  }

  @Action(SavedViewsActions.AddView)
  addSavedView(context: StateContext<SavedViewsStateModel>, { view }) {
    return this.savedViewService.create(view).pipe(
      tap((savedView) => {
        context.patchState({
          savedViews: [...context.getState().savedViews, savedView],
        });
      }),
      mergeMap(() => {
        return context.dispatch(new SavedViewsActions.LoadViews());
      }),
    );
  }

  @Action(SavedViewsActions.UpdateView)
  updateSavedView(context: StateContext<SavedViewsStateModel>, { view }) {
    return this.savedViewService.update(view).pipe(
      mergeMap(() => {
        return context.dispatch(new SavedViewsActions.LoadViews());
      }),
    );
  }

  @Action(SavedViewsActions.DeleteView)
  deleteSavedView(context: StateContext<SavedViewsStateModel>, { savedViewId }) {
    return this.savedViewService.delete(savedViewId).pipe(
      mergeMap((data) => {
        return context.dispatch(new SavedViewsActions.LoadViews());
      }),
    );
  }
}
