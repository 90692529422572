import { AsyncPipe, DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { Dictionary, RedirectItem } from '../../cross-cutting/models/common';
import { Observable, of, switchMap } from 'rxjs';

export type RecordCellRenderedParams = ICellRendererParams & {
  relatedToRedirectConfig: Dictionary<RedirectItem>;
  recordType: string;
};

type SelectLink = { id: string; url: Observable<string>; label: string; deferred?: boolean };

@Component({
  standalone: true,
  imports: [AsyncPipe, RouterModule, DatePipe],
  template: `
    @if (params?.value && params?.recordType === 'Engagement') {
      <span class="underline truncate w-25">
        {{ params.value?.engagement_type }} on {{ params.value?.event_date | date: 'MMM dd, y' }}
      </span>
    } @else {
      @for (item of items; track item; let last = $last) {
        @if (item.deferred) {
          <a (click)="deferredRedirect(item)">{{ item.label }}</a
          >{{ last ? null : ', ' }}
        } @else {
          <a [routerLink]="item.url | async">{{ item.label }}</a
          >{{ last ? null : ', ' }}
        }
      }
    }
  `,
  styles: [
    `
      a {
        text-decoration: underline;
      }
    `,
  ],
})
export class TsAgMultiSelectRenderer implements ICellRendererAngularComp {
  items: any[] = [];
  params: RecordCellRenderedParams;

  constructor(private readonly router: Router) {}

  agInit(params: RecordCellRenderedParams): void {
    this.params = params;
    this.items = this.getItems(params.value);
  }

  refresh(params: RecordCellRenderedParams): boolean {
    this.items = this.getItems(params.value);
    return true;
  }

  deferredRedirect(item: SelectLink): void {
    item.url.subscribe((url) => this.router.navigateByUrl(url));
  }

  private getItems(value: any): any[] {
    if (Array.isArray(value)) {
      return value.map((item) => this.recordStringToLink(item));
    }

    if (typeof value === 'string') {
      return [this.recordStringToLink(value)];
    }

    return [];
  }

  private recordStringToLink(recordString: string): SelectLink {
    const [id, label] = [
      recordString.substring(0, recordString.indexOf('|')),
      recordString.substring(recordString.indexOf('|') + 1),
    ];

    const redirectConfig = this.params.relatedToRedirectConfig[`${this.params.recordType}`];
    let url: Observable<string> = null;

    if (redirectConfig) {
      url = redirectConfig.route(Number(id)).pipe(switchMap((pattern) => of(pattern.replace(':id', id))));
    }

    return { id, url, label, deferred: redirectConfig?.deferred };
  }
}
