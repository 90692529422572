<div class="flex flex-col w-full h-full gap-1" matAutocompleteOrigin>
  <p class="label text-sm" *ngIf="label">{{ label }}</p>
  <div
    class="ts-input w-full border border-cool-gray-300 min-h-ts-field-height px-2 py-0 flex flex-row align-center gap-2 relative"
    [ngClass]="{
      rounded: roundedBorders,
      slim: variant === 'slim',
      large: size === 'lg',
      sm: size === 'sm',
    }"
  >
    <ts-icon [ngStyle]="{ width: 'fit-content' }" *ngIf="icon" [icon]="icon" color="black"></ts-icon>
    <input
      class="w-full"
      [readonly]="readonly"
      [attr.data-cy]="dataCy"
      [autofocus]="autofocus"
      [type]="inputType"
      [tabIndex]="tabindex"
      [placeholder]="placeholder"
      [formControl]="control"
      [autocomplete]="autocomplete"
      [min]="min"
      [max]="max"
      [step]="step"
      [matAutocompletePosition]="'auto'"
      [matAutocomplete]="matAutocompleteComponentRef"
      [matAutocompleteDisabled]="!autoCompleteComponentRef"
      (blur)="blur.emit()"
    />
    <ts-icon
      class="cursor-pointer absolute top-1/2 left-full transform -translate-y-1/2 -translate-x-6 scale-90 focus:outline-black w-fit-content"
      tabindex="0"
      *ngIf="clearable && control?.value"
      icon="ts_close"
      color="light-gray"
      (click)="control.setValue('')"
      (keydown.enter)="control.setValue('')"
    ></ts-icon>
    <ng-content select="[ts-autocomplete]"></ng-content>
  </div>

  <div *ngIf="control?.errors && errorMessages">
    <ng-container *ngFor="let error of control?.errors | keyvalue">
      <p class="mb-1 text-sm text-danger" *ngIf="errorMessages[error.key]?.length > 0">
        {{ errorMessages[error.key] }}
      </p>
    </ng-container>
  </div>
</div>
