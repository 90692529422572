import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserAssociation } from 'app/core/models/user.types';
import { UserService } from 'app/layout/common/user/user.service';
import { AccountService, AuthService } from 'app/core';
import { Account } from 'app/core/models/account.types';
import { FeatureFlag, NewPermission, Permission } from 'app/core/enums';
import { Store } from '@ngxs/store';
import { AccountState } from '@state/account/state';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
  @Input()
  showAvatar: boolean;

  @Input()
  isTaskCollaborator: boolean = false;

  // Private
  private _unsubscribeAll: Subject<void>;
  private _user: User;
  private _account: Account;
  userRole: string;

  public isCurrentUserAdmin: boolean = false;
  public currentUser: User;
  accountUserAssociations: UserAssociation[];
  userAssociations: UserAssociation[];

  /**
   * Constructor
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {Router} _router
   * @param {UserService} _userService
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    private _accountService: AccountService,
    private readonly authService: AuthService,
    private readonly store: Store,
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();

    // Set the defaults
    this.showAvatar = true;

    this._accountService.currentAccount.subscribe((accountData) => {
      this._account = accountData;
    });

    this._accountService.userAssociations$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((userAssociations: UserAssociation[]) => {
        this.accountUserAssociations = userAssociations;
        this.checkIfAdmin();
      });

    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      if (user) {
        this.currentUser = user;
        this.checkIfAdmin();
      }
      this._changeDetectorRef.markForCheck();
    });

    this._userService.role$.pipe(takeUntil(this._unsubscribeAll)).subscribe((role: string) => {
      if (role) {
        this.userRole = role;
      }
      this._changeDetectorRef.markForCheck();
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter and getter for user
   *
   * @param value
   */
  @Input()
  set user(value: User) {
    // Save the user
    this._user = value;

    // Store the user in the service
    this._userService.user = value;
  }

  get user(): User {
    return this._user;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
      this._user = user;

      //identify user for analytics
      let userTraits: Object;
      if (this._account) {
        userTraits = {
          name: user.full_name,
          email: user.email,
          company: {
            id: this._account.id,
            name: this._account.name,
            subdomain: this._account.subdomain,
          },
        };
        analytics.identify(user.id, userTraits);
      } else {
        userTraits = {
          name: user.full_name,
          email: user.email,
        };
        analytics.identify(user.id, userTraits);
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions

    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
  checkIfAdmin(): void {
    if (this.currentUser && this.accountUserAssociations) {
      let foundUserAssociations = this.accountUserAssociations.filter((ua) => {
        return ua.user?.id == this.currentUser.id;
      });
      if (foundUserAssociations && foundUserAssociations.length > 0) {
        this.isCurrentUserAdmin = true;
      }
    }
  }

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status): void {
    // Update the user data
    this.user.status = status;

    // Update the user on the server
    this._userService.update(this.user);
  }

  goToProfile(where = '/profile'): void {
    this._router.navigate([where]);
  }

  goToSettings(): void {
    this._router.navigate(['/settings']);
  }

  switchWorkspace(): void {
    this._router.navigate(['/accounts']);
  }

  /**
   * Sign out
   */
  signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  canAccessSettings(): boolean {
    if (this.store.selectSnapshot(AccountState.isFeatureEnabled(FeatureFlag.NEW_PERMISSIONS))) {
      return this.authService.hasAnyPermission(
        NewPermission.SETTINGS_MANAGE_WORKSPACE,
        NewPermission.SETTINGS_MANAGE_USERS,
        NewPermission.SETTINGS_MANAGE_TEAMS,
        NewPermission.SETTINGS_MANAGE_PERMISSIONS,
        NewPermission.SETTINGS_MANAGE_ROLES,
        NewPermission.SETTINGS_MANAGE_DROPDOWNS,
        NewPermission.SETTINGS_MANAGE_OBJECTS_MANAGER,
        NewPermission.SETTINGS_MANAGE_FIELDS,
        NewPermission.SETTINGS_MANAGE_TASK_TEMPLATES,
        NewPermission.SETTINGS_MANAGE_SHEETS,
        NewPermission.SETTINGS_MANAGE_APPS,
        NewPermission.SETTINGS_MANAGE_CODE,
        NewPermission.SETTINGS_MANAGE_IMPORTS,
        NewPermission.SETTINGS_MANAGE_AUTOMATIONS,
        NewPermission.SETTINGS_MANAGE_EXPORTS,
        NewPermission.SETTINGS_MANAGE_ACCOUNT_HOOKS,
        NewPermission.SETTINGS_MANAGE_LAYOUTS,
        NewPermission.SETTINGS_MANAGE_AUDIT,
      );
    }

    return this.authService.hasAnyPermission(
      Permission.SETTINGS_ACCESS,
      Permission.SETTINGS_USERS,
      Permission.SETTINGS_PERMISSIONS,
      Permission.SETTINGS_DROPDOWNS,
      Permission.SETTINGS_FIELDS,
      Permission.SETTINGS_TASK_TEMPLATES,
      Permission.SETTINGS_TASK_ROLES,
      Permission.SETTINGS_SHEETS,
      Permission.SETTINGS_APPS,
      Permission.SETTINGS_CODE,
      Permission.SETTINGS_IMPORTS,
      Permission.SETTINGS_OBJECT_MANAGER,
      Permission.SETTINGS_AUTOMATIONS,
    );
  }
}
