import { AsyncPipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-enterprise';
import { BehaviorSubject, debounceTime, noop, switchMap, tap } from 'rxjs';
import { TsPipesModule } from '../../cross-cutting/pipes/ts-pipes.module';
import { LOOKUP_SERVICE_TOKEN } from '../../cross-cutting/util';
import { LookupContract, LookupResult } from '../../cross-cutting/models';
import { TSIconModule } from '../../ts-icon/ts-icon.module';
import { TsButtonModule } from '../../ts-button/ts-button.module';
import { MatTooltipModule } from '@angular/material/tooltip';

export type RecordCellEditorParams = ICellEditorParams & {
  multiSelect: boolean;
  recordType: string;
  options?: LookupResult[];
};

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    MatProgressSpinnerModule,
    TsPipesModule,
    FormsModule,
    ReactiveFormsModule,
    TSIconModule,
    TsButtonModule,
    MatTooltipModule,
  ],
  template: `
    <div class="container" [style]="{ width: width }">
      @if (!params.options?.length) {
        <input placeholder="Search..." autocomplete="off" [formControl]="recordSearch" autofocus />
      }
      <ul>
        @for (selected of selectedLookupResults | async; track selected) {
          <li class="selected">
            {{ selected.label }}
            <ts-icon (click)="remove(selected)" icon="ts_trash-alt"></ts-icon>
          </li>
        }
        @if (lookupLoading | async) {
          <mat-progress-spinner class="spinner" diameter="24" mode="indeterminate"> </mat-progress-spinner>
        }
        @for (
          result of lookupResults | async | tsFilterBy: [] : (selectedLookupResults | async) : hideSelected;
          track result
        ) {
          <li (click)="select(result)">{{ result.label }}</li>
        }
        @if ((emptyResults | async) && canCreate) {
          <li class="p-2">
            <ts-button
              [loading]="addLoading | async"
              (click)="create()"
              [matTooltip]="'Add a record named &quot;' + recordSearch.value + '&quot;'"
              matTooltipPosition="right"
              matTooltipShowDelay="500"
              >{{ (addLoading | async) ? 'Adding' : 'Add ' + params.colDef.headerName }}</ts-button
            >
          </li>
        }
      </ul>
    </div>
  `,
  styles: [
    `
      .container {
        background: white;
        padding: 12px 0px 0px 0px;
        height: 220px;

        input {
          padding: 4px 16px 8px 16px;
          border-bottom: 1px solid #e2e8f0;
          width: 100%;
        }

        ul {
          overflow: auto;
          height: 100%;
          background: white;

          li {
            padding: 8px 16px;
            font-size: 10pt;

            &.selected {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 500;
            }

            &:hover:not(.p-2) {
              background: #f5f5f5;
              cursor: pointer;
            }

            ts-button {
              button {
                width: 100%;
              }
            }
          }
        }
      }
    `,
  ],
})
export class TsAgMultiSelectEditor implements ICellEditorAngularComp {
  recordSearch = new FormControl();
  lookupLoading = new BehaviorSubject<boolean>(false);
  lookupResults = new BehaviorSubject<LookupResult[]>([]);
  emptyResults = new BehaviorSubject<boolean>(false);
  selectedLookupResults = new BehaviorSubject<LookupResult[]>([]);
  lastSearch = '';
  params: RecordCellEditorParams = null;
  width = 'auto';
  addLoading = new BehaviorSubject<boolean>(false);
  canCreate = false;

  constructor(@Inject(LOOKUP_SERVICE_TOKEN) private lookupService: LookupContract) {}

  agInit(params: RecordCellEditorParams): void {
    this.params = params;
    this.width = params.column.getActualWidth() + 'px';

    if (Array.isArray(params.value)) {
      this.selectedLookupResults.next(params.value.map((item) => this.recordStringToLink(item)));
    }

    if (typeof params.value === 'string') {
      this.selectedLookupResults.next([this.recordStringToLink(params.value)]);
    }

    if (params.options?.length) {
      this.lookupResults.next(params.options);
    } else {
      this.recordSearch.valueChanges
        .pipe(
          tap(() => this.emptyResults.next(false)),
          debounceTime(300),
          tap(() => this.lookupLoading.next(true)),
          switchMap((value: string) => {
            this.lastSearch = value;
            return this.lookupService.lookFor({ query: value, relatedTo: params.recordType });
          }),
          tap((result: LookupResult[]) => {
            this.emptyResults.next(result.length === 0);
            this.lookupResults.next([...this.selectedLookupResults.value, ...result]);
            this.lookupLoading.next(false);
          }),
        )
        .subscribe(noop);

      this.recordSearch.setValue('');
    }

    this.canCreate = this.lookupService.doesSupportCreate(this.params.colDef.context.related_to_type);
  }

  isPopup?(): boolean {
    return true;
  }

  getPopupPosition?(): 'over' | 'under' | undefined {
    return 'under';
  }

  remove(result: LookupResult): void {
    this.selectedLookupResults.next(this.selectedLookupResults.value.filter(({ id }) => id !== result.id));
    const firstIndex = this.lookupResults.value.findIndex(({ id }) => id === result.id);
    this.lookupResults.next(this.lookupResults.value.filter((_, index) => index !== firstIndex));
  }

  select(result: LookupResult): void {
    if (!this.params.multiSelect) {
      this.selectedLookupResults.next([result]);
      this.params.stopEditing();
      return;
    }

    this.selectedLookupResults.next([...this.selectedLookupResults.value, result]);
  }

  getValue(): any {
    if (!this.params.multiSelect) {
      const [first] = this.selectedLookupResults.value.map(({ id, label }) => `${id}|${label}`);
      return first;
    }

    return this.selectedLookupResults.value.map(({ id, label }) => `${id}|${label}`);
  }

  hideSelected(value: LookupResult[], selectedLookupResults: LookupResult[]): LookupResult[] {
    return value.filter(({ id }) => !selectedLookupResults.map((result) => result.id).includes(id));
  }

  create(): void {
    const multiSelect = this.params.colDef.context.meta.multi_select;
    const recordType = this.params.colDef.context.meta.record_type;
    const fieldName = this.params.colDef.context.name;
    const relatedTo = this.params.colDef.context.related_to_type;

    if (multiSelect) {
      this.params.node.data[fieldName] = this.selectedLookupResults.value.map((x) => `${x.id}|${x.label}`);
    }

    this.addLoading.next(true);

    this.lookupService
      .createAndLink({
        name: this.recordSearch.value,
        fieldName,
        recordType,
        relatedTo,
        linkedTo: this.params.node.data,
        multiSelect,
      })
      .pipe(tap(() => this.addLoading.next(false)))
      .subscribe(noop);
  }

  private recordStringToLink(recordString: string): LookupResult {
    return {
      id: Number(recordString.substring(0, recordString.indexOf('|'))),
      label: recordString.substring(recordString.indexOf('|') + 1),
    };
  }
}
