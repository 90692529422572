import { Component, Input } from '@angular/core';
import { TSIconModule } from '../ts-icon/ts-icon.module';
import { NgIf } from '@angular/common';
import { TsButtonModule } from 'tsui';
import { AuthorizeInput } from '@shared/directives';
import { DirectivesModule } from '@shared/directives/directives.module';

@Component({
  selector: 'ts-empty-state',
  standalone: true,
  imports: [TSIconModule, NgIf, TsButtonModule, DirectivesModule],
  templateUrl: './ts-empty-state.component.html',
  styles: `
    :host {
      .icons-grid {
        max-width: fit-content;

        ts-icon {
          width: 40px;
          height: 40px;
          justify-content: center;
        }
      }
    }
  `,
})
export class TsEmptyStateComponent {
  @Input({ required: true }) icon: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() buttonConfig: { label: string; actionFn: () => void };
  @Input() authorizeButton: AuthorizeInput = true;
}
