<div class="ts-table-container ag-theme-quartz">
  <ag-grid-angular
    [rowData]="rowData"
    [rowSelection]="rowSelection"
    [columnDefs]="colDefs"
    [suppressDragLeaveHidesColumns]="suppressDragLeaveHidesColumns"
    [suppressRowClickSelection]="suppressRowClickSelection"
    [gridOptions]="gridOptions"
    [getRowId]="getRowId"
    [tooltipShowDelay]="tooltipShowDelay"
    (cellClicked)="onCellClicked($event)"
    (cellEditingStopped)="onCellEditingStopped($event)"
    (sortChanged)="onSortChanged($event)"
    (gridReady)="onGridReady($event)"
    (columnMoved)="handleColumnMove($event)"
    (dragStopped)="onDragStopped($event)"
    (rowDragEnd)="onRowDragEnd($event)"
    (firstDataRendered)="firstDataRendered($event)"
    (cellValueChanged)="onCellValueChanged($event)"
    (selectionChanged)="onSelectionChanged($event)"
    (gridColumnsChanged)="gridColumnsChanged.emit($event)"
    (virtualColumnsChanged)="onVirtualColumnsChanged($event)"
    (columnResized)="onColumnResized($event)"
    (cellEditingStarted)="onCellEditingStarted($event)"
    [domLayout]="autoHeight ? 'autoHeight' : 'normal'"
    class="ag-theme-quartz"
    [animateRows]="false"
  >
  </ag-grid-angular>
</div>
