import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  input,
  InputSignal,
  Output,
  signal,
  viewChild,
  WritableSignal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MatCalendar,
  MatDatepickerModule,
  MatRangeDateSelectionModel,
} from '@angular/material/datepicker';
import { TsButtonModule } from '../ts-button';
import moment from 'moment';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

export interface TsDateRange {
  id?: string;
  label?: string;
  range: {
    start: moment.Moment;
    end: moment.Moment;
  };
}

@Component({
  selector: 'ts-date-range',
  standalone: true,
  imports: [MatDatepickerModule, ReactiveFormsModule, MatMomentDateModule, TsButtonModule],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TsDateRangeComponent, multi: true },
    MatRangeDateSelectionModel,
    DefaultMatCalendarRangeStrategy,
  ],
  templateUrl: './ts-date-range.component.html',
  styles: `
    :host {
      display: block;
      min-width: 30rem;

      .mat-calendar-controls {
        margin: 0;
      }
    }
  `,
})
export class TsDateRangeComponent implements ControlValueAccessor {
  value = input<TsDateRange | undefined>(undefined);
  enableReset = input(false);

  @Output() rangeChanged = new EventEmitter<TsDateRange>();

  valueEffect = effect(
    () => {
      if (this.value()) {
        this.selectedPreset.set(
          this.presets().find(
            (preset) =>
              preset.range.start.isSame(this.value().range.start) && preset.range.end.isSame(this.value().range.end),
          ),
        );

        this.selectRange(this.value().range);
      }
    },
    { allowSignalWrites: true },
  );
  presets: InputSignal<TsDateRange[]> = input([]);
  hasPresets = computed(() => {
    return this.presets().length > 0;
  });
  selectedPreset: WritableSignal<TsDateRange> = signal(null);
  selectedDateRange: DateRange<moment.Moment> | undefined;

  private selectionModel = inject(MatRangeDateSelectionModel);
  private selectionStrategy = inject(DefaultMatCalendarRangeStrategy);

  calendar = viewChild<MatCalendar<any>>('calendar');

  onChange = (value: DateRange<moment.Moment> | undefined) => {};

  onTouched = () => {};

  writeValue(value: DateRange<moment.Moment> | undefined): void {
    this.selectedDateRange = value;
  }

  registerOnChange(fn: (value: DateRange<moment.Moment> | undefined) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  protected _rangeChanged(selectedDate: moment.Moment): void {
    this.selectedPreset.set(null);

    const selection = this.selectionModel.selection;
    const newSelection = this.selectionStrategy.selectionFinished(selectedDate, selection);
    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<moment.Moment>(newSelection.start, newSelection.end);

    this.onChange(this.selectedDateRange);
    this.onTouched();

    this.rangeChanged.emit({
      range: {
        start: newSelection.start,
        end: newSelection.end,
      },
    });
  }

  protected selectPreset(preset?: TsDateRange): void {
    this.selectedPreset.set(preset);

    this.selectRange(preset?.range);
    this.rangeChanged.emit({ ...this.selectedPreset() });
  }

  private selectRange(range: { start: moment.Moment; end: moment.Moment } = null): void {
    this.selectedDateRange = new DateRange(range?.start, range?.end);
    this.onChange(this.selectedDateRange);
    this.onTouched();

    if (range?.start && this.calendar()) {
      this.calendar()._goToDateInView(range.start, 'month');
    } else {
      this.calendar()._goToDateInView(moment(), 'month');
    }
  }
}
