import { Parcel, BoundingBox, FilterForm, LatLng, PropertyUseCode, Layer, LayerCategory } from '../research.types';

import { State, Action, StateContext, StateToken, Selector, Store, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Research } from './actions';
import { StateBase } from '../../../state/state-base';
import { ResearchService, TileServerItem } from '../research.service';
import { tap } from 'rxjs';
import { defaultMapLayers } from '../constants/default-map-layers';
import { ObjectsManagerStateModel } from '../../settings/objects-manager/state/state';

export interface ResearchStateModel {
  activeFilter: [FilterForm, BoundingBox];
  parcels: Parcel[];
  listParcels: Parcel[];
  selectedParcel: Parcel;
  currentBoundingBox: BoundingBox;
  currentLocation: LatLng;
  propertyUseCodes: PropertyUseCode[];
  geoData: any;
  availableLayers: Layer[];
  selectedLayersIds: string[];
}

const initialState: ResearchStateModel = {
  activeFilter: null,
  parcels: [],
  currentBoundingBox: null,
  currentLocation: null,
  selectedParcel: null,
  listParcels: [],
  propertyUseCodes: [],
  geoData: null,
  availableLayers: [...defaultMapLayers],
  selectedLayersIds: [],
};

@State<ResearchStateModel>({
  name: new StateToken<ResearchStateModel>('researchState'),
  defaults: initialState,
})
@Injectable()
export class ResearchState extends StateBase {
  constructor(
    protected store: Store,
    private researchService: ResearchService,
  ) {
    super(store);
  }

  @Selector()
  static geoData(state: ResearchStateModel): any {
    return state.geoData;
  }

  @Selector()
  static loadedParcels(state: ResearchStateModel): Parcel[] {
    return state.parcels;
  }

  @Selector()
  static propertyUseCodes(state: ResearchStateModel): PropertyUseCode[] {
    return state.propertyUseCodes;
  }

  @Selector()
  static listParcels(state: ResearchStateModel): Parcel[] {
    return state.listParcels;
  }

  @Selector()
  static currentLocation(state: ResearchStateModel): LatLng {
    return state.currentLocation;
  }

  @Selector()
  static currentBoundingBox(state: ResearchStateModel): BoundingBox | null {
    return state.currentBoundingBox ?? null;
  }

  @Selector()
  static activeFilter(state: ResearchStateModel): [FilterForm, BoundingBox] | null {
    return state.activeFilter ?? null;
  }

  @Selector()
  static selectedParcel(state: ResearchStateModel): Parcel {
    return state.selectedParcel;
  }

  @Selector()
  static availableLayers(state: ResearchStateModel): Layer[] {
    return state.availableLayers;
  }

  @Selector()
  static selectSelectedLayersIds(state: ResearchStateModel): string[] {
    return state.selectedLayersIds;
  }

  @Selector()
  static selectedSelectedLayersIdAsMap(state: ResearchStateModel): { [id: string]: boolean } {
    return state.selectedLayersIds.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {});
  }

  @Selector([ResearchState.selectedSelectedLayersIdAsMap])
  static selectedLayers(state: ResearchStateModel, selectedLayersIdMap: { [id: string]: boolean }): Layer[] {
    return state.availableLayers.filter((layer) => selectedLayersIdMap[layer.id]);
  }

  @Selector([ResearchState.availableLayers])
  static selectAvailableLayersGroupedByType(state: ResearchStateModel, availableLayers: Layer[]): LayerCategory[] {
    const layersMap = {};

    availableLayers.forEach((layer) => {
      if (!layersMap[layer.category]) {
        layersMap[layer.category] = {
          category: layer.category,
          layers: [layer],
        };
      } else {
        layersMap[layer.category].layers.push(layer);
      }
    });

    return Object.values(layersMap);
  }

  static selectSelectedIdsFromCategory(category: string) {
    return createSelector(
      [ResearchState, ResearchState.selectAvailableLayersGroupedByType, ResearchState.selectedSelectedLayersIdAsMap],
      (
        state: ObjectsManagerStateModel,
        layersByCategory: LayerCategory[],
        selectedIdsMap: {
          [id: string]: boolean;
        },
      ): string[] => {
        return layersByCategory
          .find((layerCategory) => layerCategory.category === category)
          ?.layers.reduce((acc, layer) => {
            if (selectedIdsMap[layer.id]) {
              acc.push(layer.id);
            }

            return acc;
          }, []);
      },
    );
  }

  @Selector([ResearchState.activeFilter])
  static currentFilterAppliedFieldsCount(state: ResearchStateModel, activeFilter: [FilterForm, BoundingBox]): string {
    return activeFilter
      ? Object.values(activeFilter[0])
          .filter((v) => v !== null)
          .length.toString()
      : '0';
  }

  @Action(Research.LoadParcels)
  loadParcels(ctx: StateContext<ResearchStateModel>, { filter }: Research.LoadParcels) {
    return this.researchService
      .getParcelsByBBox({
        ...ctx.getState().currentBoundingBox,
        ...filter,
      })
      .pipe(
        tap((response) => {
          const parcels = response ?? [];

          ctx.patchState({ parcels, activeFilter: [filter, ctx.getState().currentBoundingBox] });
        }),
      );
  }

  @Action(Research.SetParcels)
  setParcels(ctx: StateContext<ResearchStateModel>, { bboxParcels }: Research.SetParcels) {
    ctx.patchState({ parcels: bboxParcels });
  }

  @Action(Research.LoadParcelsByLatLng)
  loadParcelsByLatLng(ctx: StateContext<ResearchStateModel>, { latLng }: Research.LoadParcelsByLatLng) {
    this.store.dispatch(new Research.SetCurrentLocation(latLng));

    return this.researchService.getParcelByCoords(latLng).pipe(
      tap((response: Parcel) => {
        ctx.patchState({ listParcels: response ? [response] : [] });
      }),
    );
  }

  @Action(Research.UpdateBoundingBox)
  updateBoundingBox(ctx: StateContext<ResearchStateModel>, { boundingBox }: Research.UpdateBoundingBox) {
    ctx.patchState({ currentBoundingBox: boundingBox });
  }

  @Action(Research.SetCurrentLocation)
  setCurrentLocation(ctx: StateContext<ResearchStateModel>, { latLng }: Research.LoadParcelsByLatLng) {
    ctx.patchState({ currentLocation: latLng });
  }

  @Action(Research.GetAndSelectParcel)
  getAndSelectParcel(ctx: StateContext<ResearchStateModel>, { geoId, mode }: Research.GetAndSelectParcel) {
    return this.researchService.getParcelById(geoId).pipe(
      tap((parcel: Parcel) => {
        if (mode === 'list') {
          ctx.patchState({ listParcels: [parcel] });
        } else if (mode === 'selected') {
          ctx.patchState({ selectedParcel: parcel });
        } else {
          ctx.patchState({ listParcels: [parcel] });
          ctx.patchState({ selectedParcel: parcel });
        }
      }),
    );
  }

  @Action(Research.ClearFilterAndParcels)
  clearFilterAndParcels(ctx: StateContext<ResearchStateModel>) {
    ctx.patchState({ activeFilter: null, parcels: [] });
  }

  @Action(Research.SetPropertyUseCodes)
  setPropertyUseCodes(ctx: StateContext<ResearchStateModel>) {
    return this.researchService.getPropertyUseCodes().pipe(
      tap((propertyUseCodes) => {
        ctx.patchState({ propertyUseCodes });
      }),
    );
  }

  @Action(Research.LoadGeoData)
  loadGeoData(ctx: StateContext<ResearchStateModel>, { latLng, geography, radiusMiles }: Research.LoadGeoData) {
    return this.researchService.getGeoData(latLng, geography, radiusMiles).pipe(
      tap((response) => {
        ctx.patchState({ geoData: response });
      }),
    );
  }

  @Action(Research.SetSelectedLayersIds)
  setSelectedLayersIds(ctx: StateContext<ResearchStateModel>, { selectedLayersIds }: Research.SetSelectedLayersIds) {
    ctx.patchState({ selectedLayersIds });
  }

  @Action(Research.LoadAvailableLayers)
  loadAvailableLayers(ctx: StateContext<ResearchStateModel>) {
    return this.researchService.getAvailableLayers().pipe(
      tap((availableLayers) => {
        const formattedLayers = availableLayers.map(this.formatTileServerItemToLayer);
        ctx.patchState({ availableLayers: [...defaultMapLayers, ...formattedLayers] });
      }),
    );
  }

  private formatTileServerItemToLayer({ id, category, minZoom, layer, label, layerType }: TileServerItem): Layer {
    const snakeCaseToTitleCase = (str: string) => {
      return str
        .split('_')
        .map((word) => word.replace(word[0], word[0].toUpperCase()))
        .join(' ');
    };

    return {
      id,
      name: id,
      label: label || snakeCaseToTitleCase(id),
      category: snakeCaseToTitleCase(category),
      minZoom: minZoom,
      type: 'vector',
      attribute: layer,
      active: true,
      layerType,
    };
  }
}
