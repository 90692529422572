import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AccountService } from 'app/core';
import { ProjectsService } from './projects.service';
import { FieldDef } from 'app/core/models/account.types';
import { Project, ProjectPagination, Pipeline } from './projects.types';
import { FoldersTabService } from 'app/shared/modules/folders/folders.service';
import { AggregatedTasksService } from '../aggregated-tasks/aggregated-tasks.service';
import { TaskStatus } from 'app/shared/modules/legacy-tasks/tasks.types';
import { Store } from '@ngxs/store';
import { Field } from 'app/state/fields/actions';
import { TimeSeriesChartService } from 'app/shared/modules/time-series-charts/time-series-charts.service';
import { RelatedToType } from 'app/shared/enums';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FieldDefsState } from '@state/fields/state';
import { Dictionary } from '@shared/models';
import { Module } from '@core/enums/permission';
import { AccountState } from '@state/account/state';
import { TaskRolesService } from '../settings/task-roles/services/task-roles.service';

@Injectable({
  providedIn: 'root',
})
export class PipelinesResolver {
  constructor(
    private _projectsService: ProjectsService,
    private _accountService: AccountService,
  ) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
    });
  }

  resolve(): Observable<Pipeline[]> {
    return this._projectsService.getPipelines();
  }
}

@Injectable({
  providedIn: 'root',
})
export class PipelinesPipelineResolver {
  constructor(
    private _router: Router,
    private _projectsService: ProjectsService,
    private _foldersTabService: FoldersTabService,
    private _accountService: AccountService,
    private readonly snackbar: MatSnackBar,
  ) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
      this._foldersTabService.accountId = accountData.id;
    });
  }

  resolve(route: ActivatedRouteSnapshot): Observable<Pipeline> {
    const pipelineId = route.paramMap.get('id') || route.parent?.paramMap.get('id');

    if (!pipelineId) {
      this._router.navigate(['/projects']);
    }

    return this._projectsService.getPipelineById(pipelineId).pipe(
      tap((pipeline) => {
        if ((pipeline as unknown) === EMPTY) this._router.navigate(['/projects']);
      }),
      catchError(() => {
        this.snackbar.open('The requested View was not found.', '', { duration: 3000, panelClass: 'error-snackbar' });
        this._router.navigate(['/projects']);
        return EMPTY;
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsResolver {
  constructor(
    private _projectsService: ProjectsService,
    private _accountService: AccountService,
  ) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
    });
  }

  resolve(): Observable<{ pagination: ProjectPagination; projects: Project[] }> {
    return this._projectsService.getProjects();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsProjectResolver {
  constructor(
    private _router: Router,
    private _projectsService: ProjectsService,
    private _accountService: AccountService,
    private readonly store: Store,
  ) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
    // Extract to a Util file.
    let depth = 0;
    let projectId = null;
    let aux = route;
    while (!(projectId = aux.paramMap.get('project_id')) && depth < 5) {
      aux = aux.parent;
      depth++;
    }
    const account = this.store.selectSnapshot(AccountState.getAccount);

    return this._projectsService.getProjectById(projectId, 'json', false, account.id).pipe(
      // Error here means the requested contact is not available
      catchError((error) => {
        // Log the error
        console.error(error);

        // Get the parent url
        const parentUrl = state.url.split('/').slice(0, -1).join('/');

        // Navigate to there
        this._router.navigateByUrl(parentUrl);

        // Throw an error
        return throwError(error);
      }),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsFieldDefsResolver {
  constructor(
    private readonly accountService: AccountService,
    private readonly store: Store,
  ) {}

  resolve(): Observable<FieldDef[]> {
    return this.accountService.getFieldDefs().pipe(tap((fieldDefs) => this.store.dispatch(new Field.Set(fieldDefs))));
  }
}

@Injectable({
  providedIn: 'root',
})
export class FieldDefsResolver {
  constructor(
    private readonly accountService: AccountService,
    private readonly store: Store,
    private readonly timeSeriesChartService: TimeSeriesChartService,
  ) {
    const account = this.accountService.getCurrentAccount();

    this.timeSeriesChartService.accountId = account.id;
    this.timeSeriesChartService.relatedToType = RelatedToType.Project;
  }

  resolve(route: ActivatedRouteSnapshot): Observable<FieldDef[]> {
    return forkJoin([this.accountService.getFieldDefs(), this.timeSeriesChartService.getTimeSeriesFields(false)]).pipe(
      map(([fieldDefs, timeSeriesFieldDefs]) => {
        if (route.data.module === Module.REPORTS) {
          fieldDefs = fieldDefs.filter(
            (field) => field.meta?.reporting?.hidden === undefined || field.meta?.reporting?.hidden === false,
          );
        }

        return [
          ...fieldDefs,
          ...timeSeriesFieldDefs.map((field) => ({
            ...field,
            name: `${field.name}(cf_name: "${field.name}")`,
            usedForTimeSeries: true,
          })),
        ];
      }),
      tap((fieldDefs) => this.store.dispatch(new Field.Set(fieldDefs))),
    );
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProjectDataResolver {
  private dataObservables: Dictionary<Observable<any>>;

  constructor(
    private _accountService: AccountService,
    private _projectsService: ProjectsService,
    private readonly aggregatedTasksService: AggregatedTasksService,
    private readonly store: Store,
    private readonly taskRolesService: TaskRolesService,
  ) {
    this._accountService.currentAccount.subscribe((accountData) => {
      this._projectsService.accountId = accountData.id;
      this.aggregatedTasksService.accountId = accountData.id;
    });

    this.dataObservables = {
      users: this._accountService.getUsers(),
      teams: this._accountService.getTeams(),
      savedFilters: this._accountService.getSavedFilters(),
      pipelines: this._projectsService.getPipelines(),
      project_templates: this._projectsService.getProjectTemplates(),
      standard_dropdowns: this._accountService.getStandardDropdowns(),
      workflow_states: this.aggregatedTasksService.getTaskStatuses().pipe(
        map((taskStatuses: TaskStatus[]) =>
          taskStatuses.map(({ key, label }) => ({
            id: key,
            name: label,
          })),
        ),
      ),
      task_list_templates: this.aggregatedTasksService.getTaskListTemplates(),
      task_roles: this.taskRolesService.getByAccountId(+this.store.selectSnapshot(AccountState.getAccount).id),
    };
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    let observablesToSubscribe: Observable<any>[];

    if (route.data?.optionsToLoadEnabled) {
      const optionsToLoad = this.store
        .selectSnapshot(FieldDefsState.getFieldDefs)
        .filter(({ related_to_type, type }) => related_to_type == route.data.relatedToType && type === 'select')
        .map(({ meta }) => meta.option_name);

      observablesToSubscribe = Object.keys(this.dataObservables).map((key) => {
        if (optionsToLoad.includes(key)) {
          return this.dataObservables[key];
        }
        return of([]);
      });
    } else {
      observablesToSubscribe = Object.keys(this.dataObservables).map((key) => this.dataObservables[key]);
    }

    return forkJoin(observablesToSubscribe).pipe(
      map((data) => {
        let options = {
          users: data[0],
          teams: data[1],
          savedFilters: data[2],
          pipelines: data[3],
          project_templates: data[4],
          workflow_states: data[6],
          task_list_templates: data[7],
          task_roles: data[8],
          ...data[5],
        };

        // We should find a better place to dispatch this action
        this.store.dispatch(new Field.SetDropdownOptions(options));

        return options;
      }),
    );
  }
}
