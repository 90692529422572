import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsSelectComponent } from './ts-select.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TsPipesModule } from '../cross-cutting/pipes/ts-pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TsSelectedPipe } from '../pipes/ts-selected.pipe';
import { TSIconModule } from '../ts-icon/ts-icon.module';
import { LabelForValuePipe } from '../pipes/label-for-option.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    ScrollingModule,
    TsPipesModule,
    MatTooltipModule,
    TsSelectedPipe,
    TSIconModule,
    LabelForValuePipe,
  ],
  declarations: [TsSelectComponent],
  exports: [TsSelectComponent],
})
export class TsSelectModule {}
