import { SavedView } from 'app/core/models';
import { MessagesUtils } from '@shared/Utils/messages.utils';

export namespace SavedViewsActions {
  export class LoadViews {
    static readonly type = MessagesUtils.Actions.LOAD('Saved Views', 'Saved Views');
  }

  export class AddView {
    static readonly type = MessagesUtils.Actions.CREATE('Saved Views', 'View');

    constructor(public view: SavedView) {}
  }

  export class UpdateView {
    static readonly type = MessagesUtils.Actions.UPDATE('Saved Views', 'View');

    constructor(public view: SavedView) {}
  }

  export class DeleteView {
    static readonly type = MessagesUtils.Actions.DELETE('Saved Views', 'View');

    constructor(public savedViewId: number) {}
  }
}
