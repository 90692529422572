import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AccountState } from '@state/account/state';
import { TaskRole } from 'app/modules/settings/task-roles/task-roles.types';
import { catchError, map, Observable, of, Subject } from 'rxjs';
import { Task, TaskList, TaskResult } from '@tasks/tasks.types';
import { TaskState } from '@tasks/state/state';
import { TaskExportUtilsService } from '@tasks/services/task-export-utils.service';
import { Approval } from '@shared/modules/approvals/approvals.types';
import { Activity } from '@core/models';
import { TsSnackbarService } from 'tsui';
import { Cacheable } from 'ts-cacheable';
import { TaskRolesService } from 'app/modules/settings/task-roles/services/task-roles.service';

const taskRolesCache$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private httpClient = inject(HttpClient);
  private accountId;
  private snackBar = inject(TsSnackbarService);

  constructor(
    private store: Store,
    private taskExportUtilsService: TaskExportUtilsService,
    private readonly taskRolesService: TaskRolesService,
  ) {
    this.accountId = this.store.selectSnapshot(AccountState.getAccountId);
  }

  // Get the base URL for the module
  // it will be removed when the backend is finished
  get apiBaseUrl() {
    // if (this.moduleBaseUrl?.includes('custom_objects')) {
    //   return `${this.moduleBaseUrl}/${this.moduleId}`;
    // } else {
    return this.store.selectSnapshot(TaskState.getApiBaseUrl);
    // }
  }

  createTask(taskListId: number, task: Task): Observable<Task> {
    task.workflow_state = task.workflow_state ?? 'not_started';
    const url = `${this.apiBaseUrl}/task_lists/${taskListId}/tasks?account_id=${this.accountId}`;

    return this.httpClient.post<any>(url, { task }).pipe(map((response) => response.task));
  }

  @Cacheable({
    cacheBusterObserver: taskRolesCache$,
  })
  getTaskRolesByType(typeName?: string): Observable<TaskRole[]> {
    return this.taskRolesService.getByAccountId(this.accountId).pipe(
      map((task_roles) => {
        const taskRoles = typeName ? task_roles.filter((tr) => tr.applicable_type === typeName) : task_roles;
        return taskRoles;
      }),
    );
  }

  getTaskDetailById(taskListId: number, taskId: number, select: string[] = []): Observable<Task> {
    let url = `accounts/${this.accountId}/task_lists/${taskListId}/tasks/${taskId}`;

    if (select) {
      url += `?select=${select.join(',')}`;
    }

    return this.httpClient.get<{ task: Task }>(url).pipe(
      map((response) => {
        return response.task;
      }),
    );
  }

  duplicateTask(taskListId: number, templateId: number, task: Task): Observable<Task> {
    let url = `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${task.id}/duplicate?account_id=${this.accountId}`;

    // if (templateId) {
    //   url = `${url}&task_list_template_id=${templateId}`;
    // }

    return this.httpClient.post<any>(url, { task }).pipe(map((response: { task: Task }) => response.task));
  }

  updateTaskById(task: Task, templateId = undefined): Observable<Task> {
    let url = `${this.apiBaseUrl}/task_lists/${task.task_list_id}/tasks/${task.id}`;

    // if (templateId) {
    //   url = `${url}?task_list_template_id=${templateId}`;
    // }

    return this.httpClient.put<any>(url, { task }).pipe(map((response: { task: Task }) => response.task));
  }

  bulkUpdateTasks(tasks): Observable<Task[]> {
    let url = `task_bulk_action/update`;

    return this.httpClient.put<any>(url, { tasks }).pipe(map((response) => response.tasks));
  }

  bulkDeleteTasks(tasks): Observable<Task[]> {
    let url = `task_bulk_action/delete`;

    return this.httpClient.request<any>('delete', url, { body: { tasks } });
  }

  bulkDuplicateTasks(tasks): Observable<Task[]> {
    let url = `task_bulk_action/duplicate`;

    return this.httpClient.post<any>(url, { tasks }).pipe(map((response) => response.tasks));
  }

  removeApproverFromTask(taskListId: number, taskId: number, approval: Approval): Observable<Approval> {
    let url = `task_lists/${taskListId}/tasks/${taskId}/approvals/${approval.id}?account_id=${this.accountId}`;
    url = `${this.apiBaseUrl}/${url}`;

    return this.httpClient.delete<any>(url).pipe(map((response) => response.approval));
  }

  addApproverToTask(taskListId: number, taskId: number, approval: Approval): Observable<Approval> {
    let url = `task_lists/${taskListId}/tasks/${taskId}/approvals?account_id=${this.accountId}`;
    url = `${this.apiBaseUrl}/${url}`;

    const payload = {
      approval: {
        assigned_user_id: approval.assigned_user.id,
        task_id: taskId,
      },
    };

    return this.httpClient.post<any>(url, payload).pipe(map((response) => response.approval));
  }

  recalculateTaskDates(taskList: TaskList | { id: number }, templateId?: number): Observable<Task[]> {
    let url = `${this.apiBaseUrl}/task_lists/${taskList.id}/calculate_dates?account_id=${this.accountId}`;
    if (templateId) {
      url = `${url}&task_list_template_id=${templateId}`;
    }

    return this.httpClient.post<any>(url, {});
  }

  getTasks(
    taskListId: number,
    templateId?: number,
    filters: any = {},
    format: string = '',
    select: string = null,
  ): Observable<Task[]> {
    let url = `${this.apiBaseUrl}/task_lists/${taskListId}/tasks`;
    if (templateId) {
      url = `${url}?task_list_template_id=${templateId}`;
    }

    const {
      workflowStateIn = [],
      assignedUserIdIn = [],
      dueDateGteq = '',
      dueDateLteq = '',
      taskTypeIn = [],
    } = filters;

    if (taskTypeIn.length > 0) {
      taskTypeIn.push('section');
    }

    const params = {
      workflow_state_in: workflowStateIn.join(','),
      assigned_user_id_in: assignedUserIdIn.join(','),
      due_date_gteq: dueDateGteq,
      due_date_lteq: dueDateLteq,
      task_type_in: taskTypeIn.join(','),
      format: format,
      group: filters.groupBy,
      sort: filters.sort,
    };

    if (select) {
      params['select'] = select;
    }

    return this.httpClient.get<any>(url, { params }).pipe(
      map((response) => {
        if (format && response && (format == 'csv' || format == 'pdf')) {
          if (response?.data?.message) {
            this.taskExportUtilsService.onExportResultWithMessage(response.data.message);
          }

          return response.data;
        }
        return response.tasks;
      }),
    );
  }

  deleteTask(taskListId: number, templateId: number, id: number, updateSubject = true): Observable<any> {
    const url = `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${id}?account_id=${this.accountId}`;
    // `${this.apiBaseUrl}/task_lists/${taskListId}/tasks/${id}?task_list_template_id=${templateId}`

    return this.httpClient.delete<any>(url).pipe(
      map((reponse) => {
        return reponse.task;
      }),
    );
  }

  updateTasksOrders(taskList: TaskList | { id: number }, tasks: Task[], templateId?: number): Observable<Task[]> {
    let url = `${this.apiBaseUrl}/task_lists/${taskList.id}/tasks/update_order?account_id=${this.accountId}`;
    if (templateId) {
      url = `${url}&task_list_template_id=${templateId}`;
    }

    let rows = [];
    for (let task of tasks) {
      rows.push({ task_id: task.id, rank: task.rank, parent_id: task.parent_id });
    }

    return this.httpClient.post<any>(url, { tasks: rows }).pipe(
      map((response) => {
        return response.results;
      }),
    );
  }

  unlinkTask(id: number): Observable<any> {
    return this.httpClient.delete<Activity>(`linked_objects/${id}`).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  // TODO MJR
  // Remove/Update this method when BE is shifted to the new API
  search(filter: {
    fjson: string;
    sort: { field: string; order: string }[];
    q: string;
    include_keydates?: boolean;
    select?: string;
    format?: string;
  }): Observable<{ count?: number; tasks?: Task[]; data?: { message?: string } }> {
    return this.httpClient.post(`accounts/${this.accountId}/tasks/search`, filter).pipe(
      map((response: TaskResult & { data: { message: string } }) => {
        if (filter.format && response) {
          this.snackBar.open(response.data.message, 'info');
          return response;
        }
        return {
          count: response.count,
          // include all task_type in the search result filter.
          tasks: response.results.filter(
            ({ task_type }) =>
              ['task', 'section', 'key_date'].includes(task_type) ||
              (filter?.include_keydates ? task_type === 'key_date' : false),
          ),
        };
      }),
      catchError((error) => {
        if (filter.format) {
          this.snackBar.open('Error while exporting', 'error');
        }
        return of({ count: 0, tasks: [] });
      }),
    );
  }
}
