import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { TsSnackbarService } from 'tsui';
import { Actions, Store, ofActionCompleted, ofActionErrored } from '@ngxs/store';
import { Folders } from '../state/actions';
import { AccountState } from 'app/state/account/state';
import { filter, tap, switchMap } from 'rxjs';
import { Account } from 'app/core/models/account.types';
import { FileResource, Document } from '../folders.types';
import { ConfirmService } from 'app/shared/components/widgets/dialog/confirm/confirm.service';
import { AccountFoldersService } from 'app/modules/folders/folders.service';

@Component({
  selector: 'folders-tab-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.scss'],
})
export class FoldersTabViewFileComponent extends OnDestroyMixin implements OnInit {
  // Is a WOPI file being edited?
  editWopiFile: boolean;
  // Is the right sidebar expanded?
  expandRightSidebar: boolean;
  selectedFile: FileResource;
  selectedDocument: Document;
  documentVersionsDataSource: MatTableDataSource<any>;
  documentVersionsColumns: string[];
  account: Account;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { fileResource: FileResource },
    public matDialogRef: MatDialogRef<FoldersTabViewFileComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private store: Store,
    private readonly actions$: Actions,
    private confirmService: ConfirmService,
    private readonly snackBar: TsSnackbarService,
  ) {
    super();
    this.documentVersionsDataSource = new MatTableDataSource();
    this.documentVersionsColumns = ['version', 'created_by', 'created_at', 'actions'];
    this.account = this.store.selectSnapshot(AccountState.getAccount);
  }

  ngOnInit(): void {
    this.editWopiFile = false;
    this.expandRightSidebar = true;
    this.selectedFile = this.data.fileResource;
    this.loadFile();
    this.setupStateActions();
  }

  private setupStateActions(): void {
    // Fallback to when GetFileById endpoint fails.
    this.actions$
      .pipe(
        untilComponentDestroyed(this),
        ofActionErrored(Folders.GetFileById),
        tap(() => {
          this.snackBar.open('Unable to open the file. Please try again later.', 'error');
          this.discard();
        }),
      )
      .subscribe();

    this.actions$
      .pipe(untilComponentDestroyed(this), ofActionCompleted(Folders.GetFileById))
      .subscribe(() => this.store.dispatch(new Folders.GetFolder(this.selectedFile.folder_resource_id)));
  }

  private loadFile(): void {
    this.store
      .dispatch(new Folders.GetFileById(this.selectedFile.id))
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        const fileResource: FileResource = this.store.selectSnapshot((state) => state.folderState.selectedFile);
        if (fileResource) {
          this.documentVersionsDataSource.data = fileResource.documents.sort((a, b) => (a.id < b.id ? 1 : -1));
          this.selectedFile = fileResource;
          this.setSelectedDocument();
        }

        this._changeDetectorRef.markForCheck();
      });
  }

  setSelectedDocument(document?: Document): void {
    if (document) {
      this.selectedDocument = document;
    } else if (this.selectedFile.documents?.length > 0) {
      this.selectedDocument = this.selectedFile.documents[0];
    }
  }

  toggleWopiFile(): void {
    this.editWopiFile = !this.editWopiFile;

    if (this.selectedFile?.wopi?.errors) {
      return;
    }

    if (this.editWopiFile) {
      // TODO: This is probably not the best way to accomplish posting to the form.
      const form = window.document.getElementById('officeForm') as HTMLFormElement;

      if (form) {
        form.submit();
      }
    }
  }

  downloadDocument(document: Document): void {
    window.open(document.original_url, '_blank');
  }

  deleteDocument(document: Document): void {
    this.confirmService
      .openConfirmationModal()
      .pipe(
        untilComponentDestroyed(this),
        filter((result: boolean) => result),
        switchMap(() =>
          this.store.dispatch(
            new Folders.DeleteDocument(this.selectedFile.folder_resource_id, this.selectedFile.id, document.id),
          ),
        ),
      )
      .subscribe(() => {
        this.loadFile();
      });
  }

  deleteFile(): void {
    this.confirmService
      .openConfirmationModal()
      .pipe(
        untilComponentDestroyed(this),
        filter((result: boolean) => result),
        switchMap(() =>
          this.store.dispatch(new Folders.DeleteFile(this.selectedFile.folder_resource_id, this.selectedFile.id)),
        ),
      )
      .subscribe(() => {
        this.discard();
      });
  }

  onFileChanged(event: any) {
    const document: File = event.target.files[0];
    this.store
      .dispatch(new Folders.CreateFileDocument(this.selectedFile.folder_resource_id, this.selectedFile.id, document))
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        event.target.value = '';
        this.loadFile();
      });
  }

  openOnline(document) {
    window.open(document?.links?.edit?.link?.webUrl, '_blank');
  }

  discard(): void {
    this.matDialogRef.close();
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
