import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AuthUtils } from 'app/core/utils/auth.utils';
import { ApiService } from './api.service';
import { AuthenticationResult, Invitation, User } from 'app/core/models/user.types';
import { environment } from '@env/environment';
import { Module, ModuleNewPermission, ModulePermission, NewPermission, Permission } from '../enums/permission';
import { SignInResponse } from '../models/user.types';
import { CreateWorkspaceData, CreateWorkspaceResponse } from 'app/modules/auth/sign-up/sign-up.types';
import { Store } from '@ngxs/store';
import { UserState } from '@state/user/state';
import { AccountState } from '@state/account/state';
import { FeatureFlag } from '@core/enums/feature-flag';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Private
  private _authenticated: boolean;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(
    private _httpClient: HttpClient,
    private _apiService: ApiService,
    private _cookieService: CookieService,
    private store: Store,
  ) {
    // Set the defaults
    this._authenticated = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter and getter for access token
   */
  set accessToken(token: string) {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    this._cookieService.set('access_token', token, date, '/', environment.cookieDomain);
  }

  get assignedPermissions(): (Permission | NewPermission | string)[] {
    return this.store.selectSnapshot(UserState.getUserPermissionNames);
  }

  get accessToken(): string {
    return this._cookieService.get('access_token');
  }

  get hasPermissionsAssigned(): boolean {
    return this.assignedPermissions?.length > 0;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Send Forgot Password link
   */
  forgotPassword(credentials: { email: string }): Observable<any> {
    return this._apiService.directPost('/api/forgot_password', credentials).pipe(
      switchMap((response: any) => {
        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<AuthenticationResult> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._apiService.directPost('/api/authenticate', credentials).pipe(
      switchMap((response: AuthenticationResult) => {
        // Store the access token in the local storage
        this.accessToken = response.auth_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Sign Up
   *
   * @param credentials
   */
  signUp(credentials: {
    full_name: string;
    email: string;
    password: string;
    account_name: string;
    invite_token?: string;
  }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError('User is already logged in.');
    }

    return this._apiService.directPost('/api/register', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.auth_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Reset Passsword
   */
  resetPassword(credentials: {
    password: string;
    confirm_password: string;
    reset_password_token: string;
  }): Observable<any> {
    return this._apiService.directPost('/api/reset_password', credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.auth_token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Return a new observable with the response
        return of(response);
      }),
    );
  }

  /**
   * Is invitation valid
   */
  isInvitationValid(invitationToken: string): Observable<boolean> {
    return this._httpClient.get(`invitations/${invitationToken}`).pipe(
      switchMap((response: { invitation: Invitation }) => {
        return of(response?.invitation?.email && !response?.invitation?.used);
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  /**
   * Get an invitation
   */
  getInvitation(invitationToken: string): Observable<Invitation> {
    // Renew token
    return this._httpClient.get(`invitations/${invitationToken}`).pipe(
      switchMap((response: any) => {
        return of(response.invitation);
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    //localStorage.removeItem('access_token');
    this._cookieService.delete('access_token', '/', environment.cookieDomain);

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
   * Check the authentication status
   */
  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      return of(true);
    }

    // Check the access token availability
    if (!this.accessToken) {
      return of(false);
    }

    // Check the access token expire date
    if (AuthUtils.isTokenExpired(this.accessToken)) {
      return of(false);
    }

    //RRS TODO
    return of(true);
  }

  signInWithTToken(ttoken: string): Observable<SignInResponse> {
    return this._apiService
      .directPost(`${environment.baseUrl}/integrations/oauth_connect/authenticate`, { ttoken }, false)
      .pipe<SignInResponse>(
        tap(({ auth_token }) => {
          this.accessToken = auth_token;
          this._authenticated = true;
        }),
      );
  }

  signInWithSamlTToken(ttoken: string): Observable<SignInResponse> {
    return this._apiService
      .directPost(`${environment.baseUrl}/integrations/saml/authenticate`, { ttoken }, false)
      .pipe<SignInResponse>(
        tap(({ auth_token }) => {
          this.accessToken = auth_token;
          this._authenticated = true;
        }),
      );
  }

  sendEmailOtp(full_name: string, email: string): Observable<boolean> {
    return this._httpClient.post('onboarding/send_email_otp', { full_name, email }).pipe(
      switchMap(() => {
        return of(true);
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  authenticateEmailOtp(email: string, email_otp: string): Observable<{ auth_token: string; user: User }> {
    return this._httpClient.post('onboarding/authenticate_email_otp', { email, email_otp }).pipe(
      switchMap((response: { auth_token: string; user: User }) => {
        return of(response);
      }),
      catchError(() => {
        return of(null);
      }),
    );
  }

  validateWorkspace(value: string, type: 'name' | 'subdomain'): Observable<boolean> {
    const body = { [type]: value };
    return this._httpClient.post('onboarding/workspace_availability', body).pipe(
      catchError(() => {
        return of(false);
      }),
      switchMap((response: { name?: string; subdomain?: string }) => {
        return of(response[type] === 'available');
      }),
    );
  }

  createWorkspace(createWorkspaceData: CreateWorkspaceData): Observable<CreateWorkspaceResponse> {
    return this._httpClient.post<CreateWorkspaceResponse>('onboarding/create_workspace', createWorkspaceData).pipe(
      switchMap((response) => {
        if (response?.user) {
          // Store the access token in the local storage
          this.accessToken = response.user.auth_token;

          // Set the authenticated flag to true
          this._authenticated = true;
        }

        return of(response);
      }),
    );
  }

  /**
   * @deprecated Use UserState.hasPermission instead
   */
  hasPermission(permission: Permission | NewPermission | string, onlyOne = false): boolean {
    return this.store.selectSnapshot(UserState.hasPermission(permission, onlyOne));
  }

  hasAnyPermission(...permissions: Permission[] | NewPermission[]): boolean {
    return permissions.some((permission) => this.hasPermission(permission));
  }

  hasAnyPermissionInModule(module: Module): boolean {
    if (this.store.selectSnapshot(AccountState.isFeatureEnabled(FeatureFlag.NEW_PERMISSIONS))) {
      return ModuleNewPermission[module].some((permission) => this.hasPermission(permission));
    }
    return ModulePermission[module].some((permission) => this.hasPermission(permission));
  }

  isGuest(): boolean {
    return this.assignedPermissions?.length === 0;
  }
}
