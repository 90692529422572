import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import {
  MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
  MatLegacyAutocompleteModule as MatAutocompleteModule,
} from '@angular/material/legacy-autocomplete';
import { Overlay } from '@angular/cdk/overlay';

import { TreoAutogrowModule } from 'tsui/@treo';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PipesModule } from 'app/shared/pipes/pipes.module';
import { EditableModule } from '../editable/editable.module';
import { DirectivesModule } from 'app/shared/directives/directives.module';
import { FilterTableComponent } from './filter-table/filter-table.component';
import { FieldDefsPanelComponent } from './field-defs-panel/field-defs-panel.component';
import { FloatingEthanComponent } from './floating-ethan/floating-ethan.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { MarkdownModule } from 'ngx-markdown';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  TsPageHeaderModule,
  TsPaginatorModule,
  TsBreadcrumbModule,
  TsButtonModule,
  TSIconModule,
  TsBadgeModule,
  TsOverlayModule,
  TsFilterModule,
  TsInputComponent,
} from 'tsui';
import { TsPipesModule } from 'tsui/lib/cross-cutting/pipes/ts-pipes.module';
import { TsTableComponent } from 'tsui/lib/ts-table';

const TSUI_MODULES = [
  TsPipesModule,
  TsPageHeaderModule,
  TsPaginatorModule,
  TsBreadcrumbModule,
  TsButtonModule,
  TSIconModule,
  TsBadgeModule,
  TsOverlayModule,
  TsFilterModule,
  TsInputComponent,
];

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [FilterTableComponent, FieldDefsPanelComponent, FloatingEthanComponent],
  imports: [
    CommonModule,
    FormsModule,
    WidgetsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDividerModule,
    RouterModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressBarModule,
    MatRadioModule,
    TsTableComponent,
    MatRippleModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    TreoAutogrowModule,
    MatTooltipModule,
    MatToolbarModule,
    ...TSUI_MODULES,
    EditableModule,
    DragDropModule,
    MatSortModule,
    DirectivesModule,
    MatPaginatorModule,
    MatTableModule,
    PipesModule,
    CurrencyMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MarkdownModule,
    MatProgressSpinnerModule,
  ],
  exports: [FilterTableComponent, FieldDefsPanelComponent, FloatingEthanComponent],
  providers: [
    provideNgxMask(),
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => {
        return () => overlay.scrollStrategies.block();
      },
      deps: [Overlay],
    },
  ],
})
export class OrganismModule {}
