<mat-form-field class="flex-auto gt-xs:pr-3 w-full" (click)="edit()" (focus)="edit()">
  <mat-label>
    <span
      class="block text-ellipsis overflow-hidden pointer-events-auto"
      [matTooltip]="fieldDef.label"
      tooltipIfEllipsis
      >{{ fieldDef.label }}</span
    >
  </mat-label>
  <div class="w-full">
    @if (fieldDef.name === 'address_string') {
      <input
        matInput
        google-place
        placeholder="Enter a location"
        [mask]="mask$ | async"
        [(ngModel)]="value"
        (keyup.enter)="$event.preventDefault()"
        (blur)="$event.preventDefault()"
        [disabled]="isDisabled"
        (ngModelChange)="onNgModelChange()"
        (onSelectParts)="onSetAddress($event)"
        data-cy="form-panel-text-form-field"
      />
    } @else {
      <input
        #inputControl
        matInput
        [mask]="mask$ | async"
        [(ngModel)]="value"
        (keyup.enter)="onBlur($event)"
        (blur)="onBlur($event)"
        [disabled]="isDisabled"
        (ngModelChange)="onNgModelChange()"
        data-cy="form-panel-text-form-field"
      />
    }
  </div>
</mat-form-field>
