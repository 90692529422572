import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { TaskState } from '@tasks/state/state';
import { TaskList, TaskListResult, Task } from '@tasks/tasks.types';
import { AccountState } from '@state/account/state';
import { Project } from 'app/modules/projects/projects.types';

@Injectable({
  providedIn: 'root',
})
export class TaskListService {
  private store = inject(Store);
  private httpClient = inject(HttpClient);
  private accountId: number;

  // TODO MJR, remove it once new apis is shipped
  get apiBaseUrl() {
    return this.store.selectSnapshot(TaskState.getApiBaseUrl);
  }

  constructor(private http: HttpClient) {
    // TODO MJR, remove it once new apis is shipped
    this.accountId = +this.store.selectSnapshot(AccountState.getAccount)?.id;
  }

  getTaskLists(): Observable<TaskList[]> {
    const url = `${this.apiBaseUrl}/task_lists?account_id=${this.accountId}`;
    return this.httpClient.get<TaskListResult>(url).pipe(
      map(({ task_lists }) => {
        return task_lists;
      }),
    );
  }

  getTaskListById(taskListId: string, templateId?: number, include_tasks = false): Observable<TaskList> {
    let url = `${this.apiBaseUrl}/task_lists/${taskListId}?account_id=${this.accountId}`;
    if (templateId) {
      url = `${url}&task_list_template_id=${templateId}`;
    }

    include_tasks = include_tasks && this.apiBaseUrl.includes('projects'); // Only include tasks for projects
    url = include_tasks ? `${url}&include_tasks=true` : url;

    return this.httpClient.get<{ task_list: TaskList; project?: Project }>(url).pipe(
      map((response) => {
        if (include_tasks) {
          const tasks = this.deepTaskListProjectTransform(response.task_list, response?.project);
          response.task_list.tasks = tasks;
        }
        return response.task_list;
      }),
    );
  }

  getTaskListByIdAndSelect<T>(taskListId: number, select: string[]): Observable<T> {
    const url = `${this.apiBaseUrl}/task_lists/${taskListId}?select=${select.join(',')}`;
    return this.httpClient.get(url).pipe(map((response: any) => response.task_list));
  }

  updateTaskList(taskList: TaskList, templateId?: number, updateSubject = true): Observable<TaskList> {
    const url = (taskList: TaskList) => {
      // TODO MJR, remove it once new apis is shipped
      if (templateId) {
        return `${this.apiBaseUrl}/task_lists/${taskList.id}?task_list_template_id=${templateId}`;
      } else {
        return `${this.apiBaseUrl}/task_lists/${taskList.id}?account_id=${this.accountId}`;
      }
    };

    return this.httpClient
      .put<any>(url(taskList), {
        task_list: taskList,
      })
      .pipe(
        map((reponse) => {
          return reponse.task_list;
        }),
      );
  }

  createTaskList(taskList: TaskList): Observable<TaskList> {
    // const url = this.isGlobalTasks
    //   ? `${this.apiBaseUrl}/task_lists`
    //   : `${this.apiBaseUrl}/task_lists?account_id=${this._accountId}`;

    const url = `${this.apiBaseUrl}/task_lists?account_id=${this.accountId}`;

    return this.httpClient.post<any>(url, { task_list: taskList }).pipe(
      map((reponse) => {
        return reponse.task_list;
      }),
    );
  }

  deleteTaskList(id: number): Observable<any> {
    // const url = this.isGlobalTasks
    //   ? `${this.apiBaseUrl}/task_lists/${id}`
    //   : `${this.apiBaseUrl}/task_lists/${id}?account_id=${this._accountId}`;

    const url = `${this.apiBaseUrl}/task_lists/${id}?account_id=${this.accountId}`;

    return this.httpClient.delete<any>(url).pipe(
      map((reponse) => {
        return reponse.task_list;
      }),
    );
  }

  private deepTaskListProjectTransform(taskList: TaskList, project: Project): Task[] {
    return (
      taskList?.tasks?.reduce?.((acc, task) => {
        const transformedTask = this.setTaskAttributes(task, taskList, project);
        return [...acc, transformedTask];
      }, []) || []
    );
  }

  private setTaskAttributes(task: Task, taskList: TaskList, project: Project): Task {
    task.project = project;
    task.project_id = +project?.id;
    task.task_list_id = taskList.id;
    task.task_list_name = taskList.name;

    task.children = task.children?.map((child) => {
      return this.setTaskAttributes(child, taskList, project);
    });

    return task;
  }
}
