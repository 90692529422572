import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { TsSnackbarService } from 'tsui';
import { BehaviorSubject, Observable, throwError, catchError, map, switchMap, take } from 'rxjs';
import { FolderResource, FileResource } from 'app/shared/modules/folders/folders.types';

@Injectable({
  providedIn: 'root',
})
export class AccountFoldersService {
  private _folder: BehaviorSubject<FolderResource | null>;
  private _file: BehaviorSubject<FileResource | null>;
  private _accountId: string;

  constructor(
    private _httpClient: HttpClient,
    private snackBar: TsSnackbarService,
  ) {
    this._folder = new BehaviorSubject(null);
    this._file = new BehaviorSubject(null);
  }

  get folder$(): Observable<FolderResource> {
    return this._folder.asObservable();
  }

  get file$(): Observable<FileResource> {
    return this._file.asObservable();
  }

  set accountId(val: string) {
    this._accountId = val;
  }

  searchFolders(
    search: string = '',
  ): Observable<{ folder_resources: FolderResource[]; file_resources: FileResource[] }> {
    return this._httpClient.post<{ folder_resources: FolderResource[]; file_resources: FileResource[] }>(
      `accounts/${this._accountId}/folder_resources/search`,
      { search: search },
    );
  }

  getFolder(folderId?: number): Observable<FolderResource> {
    const url = folderId
      ? `accounts/${this._accountId}/folder_resources/${folderId}`
      : `accounts/${this._accountId}/folder_resources`;

    return this._httpClient.get<{ folder_resource: FolderResource }>(url).pipe(
      map(({ folder_resource }) => {
        this._folder.next(folder_resource);
        return folder_resource;
      }),
    );
  }

  getFile(folderId: number, fileResourceId: number): Observable<FileResource> {
    return this._httpClient
      .get<any>(`accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileResourceId}`)
      .pipe(
        map(({ file_resource }) => {
          this._file.next(file_resource);
          return file_resource;
        }),
      );
  }

  createFolder(folderResource: FolderResource): Observable<FolderResource> {
    return this.folder$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .post<any>(`accounts/${this._accountId}/folder_resources`, {
            folder_resource: folderResource,
          })
          .pipe(
            map((reponse) => {
              this._folder.next(reponse.folder_resource);
              return reponse.folder_resource;
            }),
          ),
      ),
    );
  }

  updateFolder(folderResource: FolderResource): Observable<FolderResource> {
    return this.folder$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .put<any>(`accounts/${this._accountId}/folder_resources/${folderResource.id}`, {
            folder_resource: folderResource,
          })
          .pipe(map(({ folder_resource }) => folder_resource)),
      ),
    );
  }

  createFile(folderId: number, fileResource: FileResource): Observable<FileResource> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .post<any>(`accounts/${this._accountId}/folder_resources/${folderId}/file_resources`, {
            file_resource: fileResource,
          })
          .pipe(
            map((response) => {
              let newFile = response.file_resource;
              if (folder) {
                let files = [newFile, ...folder.file_resources];
                let newFolder = folder;
                newFolder.file_resources = files;
                this._folder.next(newFolder);
              }
              return newFile;
            }),
          ),
      ),
    );
  }

  deleteFile(folderId: number, fileResourceId: number): Observable<any> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .delete<any>(`accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileResourceId}`)
          .pipe(
            map((response) => {
              if (folder) {
                let files = folder.file_resources;
                const index = files.findIndex((item) => item.id === fileResourceId);
                files.splice(index, 1);
                folder.file_resources = files;
                this._folder.next(folder);
              }
              response.file_resource;
            }),
            catchError((err: HttpErrorResponse) => {
              this.snackBar.open(
                err.error?.file_resource?.errors || err.error?.error?.message || 'Unable to delete file',
                'error',
              );
              return throwError(() => err);
            }),
          ),
      ),
    );
  }

  updateFile(folderId: number, fileResource: FileResource): Observable<FileResource> {
    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .put<any>(`accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileResource.id}`, {
            file_resource: fileResource,
          })
          .pipe(
            map((response) => {
              let newFile = response.file_resource;
              let files = [newFile, ...folder.file_resources];
              let newFolder = folder;
              newFolder.file_resources = files;
              this._folder.next(newFolder);
              return newFile;
            }),
          ),
      ),
    );
  }

  uploadDocument(folderId: number, fileId: number, document: any): Observable<FileResource> {
    const uploadData = new FormData();
    uploadData.append('document[attachment]', document);

    return this.folder$.pipe(
      take(1),
      switchMap((folder) =>
        this._httpClient
          .post<any>(
            `accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileId}/upload_document`,
            uploadData,
          )
          .pipe(
            map((response) => {
              let updatedFileResource = response.file_resource;

              if (folder) {
                const index = folder.file_resources.findIndex((item) => item.id === updatedFileResource.id);
                folder.file_resources[index] = updatedFileResource;
                this._folder.next(folder);
              }

              return updatedFileResource;
            }),
          ),
      ),
    );
  }

  deleteDocument(folderId: number, fileId: number, documentId: number): Observable<any> {
    return this.folder$.pipe(
      take(1),
      switchMap(() =>
        this._httpClient
          .delete<any>(
            `accounts/${this._accountId}/folder_resources/${folderId}/file_resources/${fileId}/destroy_document?document_id=${documentId}`,
          )
          .pipe(
            map((response) => {
              return response;
            }),
          ),
      ),
    );
  }

  getAllFiles(excludeDocuments: boolean = false): Observable<FileResource[]> {
    return this._httpClient
      .get<any>(`accounts/${this._accountId}/file_resources?exclude_documents=${excludeDocuments}`)
      .pipe(map(({ file_resources }) => file_resources));
  }
}
