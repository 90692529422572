<div class="flex flex-row justify-start w-full h-full gap-6">
  @if (hasPresets()) {
    <div class="w-1/3 flex flex-col gap-2 pt-4 pl-2">
      @for (preset of presets(); track preset.label) {
        <ts-button
          variant="secondary"
          [active]="selectedPreset() === preset"
          (click)="selectPreset(preset)"
          (keydown.enter)="selectPreset(preset)"
          >{{ preset.label }}</ts-button
        >
      }
      @if (enableReset()) {
        <ts-button variant="secondary" (click)="selectPreset()" (keydown.enter)="selectPreset()">Reset</ts-button>
      }
    </div>
  }

  <div class="w-full">
    <mat-calendar #calendar [(selected)]="selectedDateRange" (selectedChange)="_rangeChanged($event)"></mat-calendar>
  </div>
</div>
