import { InjectionToken } from '@angular/core';
import { LookupContract } from './models';

export const randomId = () => (+new Date() * Math.random()).toString(36).substring(0, 6);

export const LOOKUP_SERVICE_TOKEN = new InjectionToken<LookupContract>('LookupService');

export const nonEditableStyle = (fieldDef) => {
  if (!fieldDef.tsTableOptions.editable || fieldDef.meta?.readonly) {
    return { background: '#fbfbfb' };
  }
  return fieldDef.tsTableOptions.cellStyle;
};
