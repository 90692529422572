import { Component, ElementRef, ViewChild, OnInit, DestroyRef } from '@angular/core';
import { FormFieldComponent } from '../form-field.types';
import { ResearchService } from '../../../../modules/research/research.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  templateUrl: './text-form-field.component.html',
  styleUrls: ['./text-form-field.component.scss'],
})
export class TextFormFieldComponent extends FormFieldComponent implements OnInit {
  @ViewChild('inputControl') inputControl: ElementRef;

  public editing: boolean = false;
  public value;

  private _originalValue;
  private _readonly;

  constructor(
    private readonly resarchService: ResearchService,
    private destroyRef: DestroyRef,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.value = this.model[this.fieldDef.name];
    this.updateMask();

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
    if (this.fieldDef.meta && this.fieldDef.meta.readonly) {
      this._readonly = this.fieldDef.meta.readonly;
    }
    if (this.readonly) {
      this._readonly = this.readonly;
    }
    this.readonly = this._readonly;
  }

  edit() {
    if (!this.editing && !this._readonly) {
      this.editing = true;
      setTimeout(() => {
        this.inputControl?.nativeElement?.focus?.();
      }, 300);
    }
  }

  onBlur($event: Event) {
    this.onSave();
    this.editing = false;
  }

  onSetAddress($event: any) {
    if ($event?.lat && $event.lng) {
      const lat = $event.lat;
      const long = $event.lng;
      this.resarchService
        .reverseGeocode(null, lat, long)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response) => {
          if (response.address) {
            this.update.emit({ [this.fieldDef.name]: response.address });
          }
        });
    }
  }

  /**
   * When the model is updated after save, re-run our logic
   * @param model
   */
  updateChangedModel(model: any): void {
    this.model = model;
    //this.value = this.model[this.fieldDef.name] * 100;
    this.value = this.model[this.fieldDef.name];

    // we want to track the original value, so that we don't hit save to many times
    this._originalValue = this.value;
  }

  onSave() {
    if (this._originalValue != this.value) {
      this.updateModel();
      this.update.emit({ [this.fieldDef.name]: this.value });
    }
  }

  updateModel() {
    this.model[this.fieldDef.name] = this.value;
    this._originalValue = this.value;
  }

  onNgModelChange() {
    this.updateMask();
  }

  private updateMask() {
    if (this.fieldMask) {
      this.mask$.next(this.fieldMask(this.value));
    }
  }
}
