<div class="header">
  <div class="title">Add View</div>
  <button class="close-button" mat-icon-button (click)="discard()" [tabIndex]="-1">
    <mat-icon [svgIcon]="'close'"></mat-icon>
  </button>
</div>

<form [formGroup]="addPipelineForm">
  <!-- Subject -->
  <mat-form-field class="subject">
    <mat-label>View Name</mat-label>
    <input matInput [formControlName]="'name'" data-cy="form-view-name-text-form-field" />
    <mat-error *ngIf="addPipelineForm.get('name').hasError('duplicateName')">
      This name already exists. Please choose a different name.
    </mat-error>
  </mat-form-field>

  <!-- Body -->

  <div class="actions justify-end">
    <div class="buttons">
      <!-- Discard -->
      <button class="discard-button" mat-button (click)="discard()">
        <span>Cancel</span>
      </button>

      <!-- Send -->
      <button
        class="send-button"
        mat-flat-button
        [color]="'primary'"
        [disabled]="addPipelineForm.disabled || addPipelineForm.pristine || addPipelineForm.invalid"
        (click)="save()"
        data-cy="save-view-button"
      >
        <span>Save</span>
      </button>
    </div>
  </div>
</form>
