import { Component, Pipe } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TsButtonModule } from '../../ts-button/ts-button.module';
import { TSIconModule } from '../../ts-icon/ts-icon.module';
import { TableActions } from '../../cross-cutting/models/table-actions';
import { NgClass } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  standalone: true,
  imports: [MatIconModule, TsButtonModule, TSIconModule, MatMenuModule, NgClass, MatTooltipModule],
  selector: 'button-renderer',
  template: `
    <ts-button
      [matMenuTriggerFor]="menu"
      class="h-full w-full flex flex-row justify-start items-center"
      type="icon"
      color="accent"
      icon="ts_ellipsis-h"
    ></ts-button>
    <mat-menu #menu="matMenu">
      @for (action of actions; track trackByFn) {
        <button
          mat-menu-item
          [matTooltip]="action?.displayTooltip ? action.displayTooltip(params) : undefined"
          [disabled]="action?.isDisabled ? action.isDisabled(params) : false"
          (click)="onActionClick(action)"
        >
          <div class="btn-content" [ngClass]="{ 'text-danger': action?.variant === 'danger' }">
            @if (action.icon) {
              <ts-icon
                outlined="true"
                [color]="action?.variant === 'danger' ? 'red' : 'black'"
                [icon]="getIcon(action, params.data)"
              ></ts-icon>
            }
            <span>{{ getLabel(action, params.data) }}</span>
          </div>
        </button>
      }
    </mat-menu>
  `,
  styles: [
    `
      .btn-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    `,
  ],
})
export class TableActionsRendererComponent implements ICellRendererAngularComp {
  actions: TableActions[] = [];
  params;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.actions = this.params.actions || [];
  }

  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.actions = this.params.actions || [];
    return true;
  }

  onActionClick(action: TableActions): void {
    if (action && typeof action.action === 'function') {
      action.action(this.params.data);
    }
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  getIcon(action: TableActions, data: any): string {
    return typeof action.icon === 'function' ? action.icon(data) : action.icon;
  }

  getLabel(action: TableActions, data: any): string {
    return typeof action.label === 'function' ? action.label(data) : action.label;
  }
}
