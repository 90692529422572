import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import {
  MatLegacySelect as MatSelect,
  MatLegacySelectChange as MatSelectChange,
} from '@angular/material/legacy-select';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BehaviorSubject, debounceTime, noop, switchMap, tap } from 'rxjs';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import {
  FilterListItemChangeArgs,
  FilterListItemModel,
  FilterListItemValueModel,
  LookupContract,
  LookupResult,
  Operator,
  OperatorDisplayMap,
} from '../cross-cutting/models';

@Component({
  selector: 'ts-filter-list-item',
  templateUrl: './ts-filter-list-item.component.html',
  styleUrls: ['./ts-filter-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsFilterListItemComponent extends OnDestroyMixin implements OnInit {
  @Input() index = -1;
  @Input() fields: FilterListItemModel[];
  @Input() set filter(value: FilterListItemValueModel) {
    this.filterValue = value;
    const fieldInfo = this.fields.find(({ name }) => name === value.fieldName);
    if (fieldInfo?.type === 'record') {
      this.selectedLookupResults = (value.value ?? []).map(([id, label]) => ({ id, label }));
      this.form.patchValue({
        ...value,
        value: this.selectedLookupResults.map(({ id }) => id),
      });
    } else {
      this.form.patchValue(value);
    }
  }
  @Input() lookupContract: LookupContract;
  @Input() hasGroups = true;
  @Output() filterDefChanged = new EventEmitter<FilterListItemChangeArgs>();
  @Output() filterRemoved = new EventEmitter<number>();

  filterValue: FilterListItemValueModel;
  form = new FormGroup({
    fieldName: new FormControl(null as string),
    operator: new FormControl(null as Operator),
    value: new FormControl(null),
  });
  recordSearch = new FormControl();
  lookupLoading = new BehaviorSubject<boolean>(false);
  lookupResults = new BehaviorSubject<LookupResult[]>([]);
  selectedLookupResults: LookupResult[] = [];

  private get fieldNameControl(): AbstractControl {
    return this.form.get('fieldName');
  }

  get operator(): AbstractControl {
    return this.form.get('operator');
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setupFormChangesListeners();
  }

  onValueInputBlur(event: Event & { target: { value: string } }): void {
    const field = this.fields.find((x) => x.name === this.filterValue.fieldName);

    if (field.name === 'id' || field.name.endsWith('.id')) {
      const valueAsArray = JSON.parse(JSON.stringify(event.target.value.split(','))).filter((x) => x);
      this.updateFilterValue(valueAsArray);
      return;
    }

    if (field?.type === 'money') {
      this.updateFilterValue(this.form.get('value').value);
      return;
    }

    this.updateFilterValue(event.target.value);
  }

  onValueSelectBlur(select: MatSelect): void {
    if (this.filterValue.operator === 'in' && !Array.isArray(select.value)) {
      this.updateFilterValue([select.value]);
      return;
    }

    if (this.fields.find(({ name }) => name === this.filterValue.fieldName)?.type === 'record') {
      this.updateFilterValue(this.selectedLookupResults.map(({ id, label }) => [id, label]));
      return;
    }

    this.updateFilterValue(select.value);
  }

  onValueDateBlur(event: MatDatepickerInputEvent<Moment>): void {
    this.updateFilterValue(event.value.format('YYYY-MM-DD'));
  }

  onValueSelectOpen(select: MatSelect): void {
    this.recordSearch.setValue('');
  }

  onValueSelectChange(event: MatSelectChange): void {
    this.selectedLookupResults = this.lookupResults.value
      .filter(({ id }) => event.value.map((y) => y).includes(id))
      .reduce((p, c) => (!p.find(({ id }) => id === c.id) ? [...p, c] : p), []);
  }

  getMaskForNumberDecimal(field: FilterListItemModel): string {
    if (!field.meta.scale) {
      return '0*';
    }

    return `0*.${Array(field.meta.scale).fill(0).join('')}`;
  }

  mutateOperatorListFn(value: Operator[]): { name: Operator; label: string }[] {
    return value?.map((x) => ({ name: x, label: OperatorDisplayMap[x] }));
  }

  innerFilterFn(options: any[], filter: any): any[] {
    if (filter?.innerFilter) {
      return filter.innerFilter(options);
    }

    return options;
  }

  private updateFilterValue(value: any): void {
    this.filterDefChanged.emit({ ...this.filterValue, index: this.index, value });
  }

  private setupFormChangesListeners(): void {
    this.fieldNameControl.valueChanges
      .pipe(
        untilComponentDestroyed(this),
        tap((fieldName: string) => {
          if (fieldName !== this.filterValue.fieldName) {
            this.filterDefChanged.emit({
              fieldName,
              operator: this.fields.find((x) => x.name === fieldName)?.meta.filtering.operators[0],
              index: this.index,
              value: null,
            });
          }
        }),
      )
      .subscribe(noop);

    this.operator.valueChanges
      .pipe(
        untilComponentDestroyed(this),
        tap((operator: Operator) => {
          this.filterDefChanged.emit({ ...this.filterValue, value: null, operator, index: this.index });
        }),
      )
      .subscribe(noop);

    this.recordSearch.valueChanges
      .pipe(
        untilComponentDestroyed(this),
        debounceTime(300),
        tap(() => this.lookupLoading.next(true)),
        switchMap((value: string) => {
          const fieldInfo = this.fields.find(({ name }) => name === this.filterValue.fieldName);
          return this.lookupContract.lookFor({ query: value, relatedTo: fieldInfo.meta.record_type });
        }),
        tap((result: LookupResult[]) => {
          this.lookupResults.next([...this.selectedLookupResults, ...result]);
          this.lookupLoading.next(false);
        }),
      )
      .subscribe(noop);
  }

  hideSelected(value: LookupResult[], selectedLookupResults: LookupResult[]): LookupResult[] {
    return value.filter(({ id }) => !selectedLookupResults.map((result) => result.id).includes(id));
  }
}
