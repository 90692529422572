import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tsSelected',
  standalone: true,
})
export class TsSelectedPipe implements PipeTransform {
  transform(fieldValue: unknown, source: unknown | unknown[]): unknown {
    if (Array.isArray(source)) {
      return source.includes(fieldValue);
    }

    return fieldValue === source;
  }
}
