import { Approval } from '@shared/modules/approvals/approvals.types';
import {
  TaskModuleRelatedToType,
  Task,
  TaskType,
  Todo,
  TaskList,
  TaskViewType,
  TaskView,
  TaskQueryFilter,
  TaskSort,
} from '@tasks/tasks.types';
import { User } from '@core/models';
import { Contact } from 'app/modules/contacts/contacts.types';

export namespace TaskActions {
  export class SetRelatedTo {
    static readonly type = '[Task] Set Related to Type and Related to Type Id';

    constructor(
      public relatedToType: TaskModuleRelatedToType,
      public relatedToTypeId: number,
      public customObjectName?: string,
    ) {}
  }
  export class SetTaskTemplateId {
    static readonly type = '[Task] Set TaskTemplateId';

    constructor(public taskTemplateId: number) {}
  }

  export class SetViewType {
    static readonly type = '[Task] Set View Type';

    constructor(public viewType: TaskViewType) {}
  }

  export class GetTaskDetail {
    static readonly type = '[Task] Get Task Detail By Id';

    constructor(
      public taskListId: number,
      public taskId: number,
    ) {}
  }

  export class DuplicateTask {
    static readonly type = '[Task] Duplicate Task';

    constructor(
      public taskListId: number,
      public task: Task,
      public templateId: number,
    ) {}
  }

  export class UpdateTask {
    static readonly type = '[Task] Update Task By Id';

    constructor(
      public task: Task,
      public templateId?: number,
      public recalculateTaskList: boolean = true,
    ) {}
  }

  export class UnlinkTask {
    static readonly type = '[Task] Unlink Task';

    constructor(public task: Task) {}
  }

  export class CreateTask {
    static readonly type = '[Task] Create Task';

    constructor(public model: { taskType: TaskType; parentId?: number; rank?: number }) {}
  }

  export class CreateTodo {
    static readonly type = '[Task] Create Todo';

    constructor() {}
  }

  export class DeleteTodo {
    static readonly type = '[Task] Delete Todo';

    constructor(
      public task: Task,
      public todo: Todo,
    ) {}
  }
  export class AddApprover {
    static readonly type = '[Task] Add approver';

    constructor(
      public task: Task,
      public approval: Approval,
    ) {}
  }

  export class RemoveApprover {
    static readonly type = '[Task] Remove approver';

    constructor(
      public task: Task,
      public approval: Approval,
    ) {}
  }

  export class AddFollower {
    static readonly type = '[Task] Add User as Follower';

    constructor(
      public task: Task,
      public user: User,
    ) {}
  }

  export class RemoveFollower {
    static readonly type = '[Task] Remover User from follower list';

    constructor(
      public task: Task,
      public user: User,
    ) {}
  }

  export class AddContact {
    static readonly type = '[Task] Add Contact';

    constructor(
      public task: Task,
      public contact: Partial<Contact>,
    ) {}
  }

  export class RemoveContact {
    static readonly type = '[Task] Remove Contact list';

    constructor(
      public task: Task,
      public contact: Partial<Contact>,
    ) {}
  }

  export class CreateSubTask {
    static readonly type = '[Task] Create Sub Task';

    constructor() {}
  }

  export class DeleteTask {
    static readonly type = '[Task] Delete';

    constructor(
      public taskListId: number,
      public taskId: number,
      public templateId?: number,
    ) {}
  }

  export class UpdateTaskView {
    static readonly type = '[Task] Update Task View';

    constructor(public taskView: TaskView) {}
  }

  export class GetSidebarItems {
    static readonly type = '[Task] Get All Sidebar Items';

    constructor() {}
  }

  export class CreateTaskList {
    static readonly type = '[Task] Create TaskList';

    constructor(public taskList: TaskList) {}
  }
  export class GetTaskLists {
    static readonly type = '[Task] Get All TaskLists';

    constructor() {}
  }

  export class GetTaskListById {
    static readonly type = '[Task] Get Task List By Id';

    constructor(
      public taskListId: number,
      public templateId: number,
      public includeTasks: boolean,
    ) {}
  }

  export class DeleteTaskList {
    static readonly type = '[Task] Delete Task List By Id';

    constructor(public taskListId: number) {}
  }

  export class SetTaskList {
    static readonly type = '[Task] Update State with Task List';

    constructor(public taskList: TaskList) {}
  }

  export class SetTaskView {
    static readonly type = '[Task] current task view';

    constructor(public taskView: TaskView) {}
  }

  export class UndoTaskViewChanges {
    static readonly type = '[Task] Undo Task View Changes';

    constructor() {}
  }

  export class UpdateTaskList {
    static readonly type = '[Task] Update Task List';

    constructor(public taskList: TaskList) {}
  }

  export class RecalculateTaskListDates {
    static readonly type = '[Task] Recalculate Task List Dates';

    constructor(public taskListId: number) {}
  }

  export class ClearTaskList {
    static readonly type = '[Task] Clear Task list state';

    constructor() {}
  }

  export class SetPage {
    static readonly type = '[Task] Set Current Page';

    constructor(public page: number) {}
  }

  export class GetTasks {
    static readonly type = '[Task] Get Tasks By Tas List Id';

    constructor(
      public taskListId: number,
      public filters: any,
    ) {}
  }

  export class FilterTasks {
    static readonly type = '[Task] Filter Tasks';

    constructor(
      public queryFilters: TaskQueryFilter,
      public newTaskModule?: boolean,
    ) {}
  }

  export class SetSelectedTasks {
    static readonly type = '[Task] Set Selected Tasks';

    constructor(public tasks: Task[]) {}
  }

  export class BulkDeleteSelectedTasks {
    static readonly type = '[Task] Bulk Delete Selected Tasks';

    constructor() {}
  }

  export class BulkDuplicateSelectedTasks {
    static readonly type = '[Task] Bulk Duplicate Selected Tasks';

    constructor() {}
  }

  export class BulkUpdateSelectedTasks {
    static readonly type = '[Task] Bulk Update Selected Tasks';

    constructor(public newTaskParams: Partial<Task>) {}
  }

  export class ReorderColumns {
    static readonly type = '[Task] Reorder Columns';

    constructor(public columns: string[]) {}
  }

  export class ReorderTasks {
    static readonly type = '[Task] Reorder Tasks';

    constructor(
      public taskListId: number,
      public tasks: Task[],
      public templateId?: number,
    ) {}
  }

  export class ClearFilter {
    static readonly type = '[Task] Clear searched ids';
    constructor() {}
  }

  export class ClearCurrentTask {
    static readonly type = '[Task] Clear Current Task';
  }

  export class UpdateCurrentTaskNums {
    static readonly type = '[Task] Update Current Task Nums';
  }

  export namespace TaskList {
    export class ToggleVisibility {
      static readonly type = '[Task List] Toggle Visibility';

      constructor(public id: number) {}
    }
  }
}
