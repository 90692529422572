import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TaskRole, TaskRoleCreate, TaskRoleCreateResponse, TaskRoleGetResponse } from '../task-roles.types';
import { Observable, Subject } from 'rxjs';
import { Cacheable, CacheBuster } from 'ts-cacheable';

const taskRolesBuster = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
export class TaskRolesService {
  constructor(private readonly httpClient: HttpClient) {}

  @Cacheable({ maxAge: 120000, cacheBusterObserver: taskRolesBuster })
  @CacheBuster({ cacheBusterNotifier: taskRolesBuster })
  getByAccountId(accountId: number): Observable<TaskRole[]> {
    return this.httpClient.get<TaskRoleGetResponse>(`accounts/${accountId}/task_roles`).pipe(
      map((response: TaskRoleGetResponse) => {
        return response.task_roles || [];
      }),
    );
  }

  delete(taskRoleId: number, accountId: number): Observable<void> {
    taskRolesBuster.next();
    return this.httpClient.delete<void>(`accounts/${accountId}/task_roles/${taskRoleId}`);
  }

  create(taskRole: TaskRoleCreate): Observable<TaskRole> {
    taskRolesBuster.next();
    const { accountId } = taskRole;
    return this.httpClient
      .post<TaskRoleCreateResponse>(`accounts/${accountId}/task_roles`, { task_role: taskRole })
      .pipe(map((response: TaskRoleCreateResponse) => response.task_role));
  }
}
