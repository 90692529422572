export interface Permission {
  id: number;
  name: string;
  label: string;
  description: string;
  permission_module_id: number;
  permission_module: Module;
  rank?: number;
  permission_control_type: PermissionControlType;
}

export interface Module {
  id: number;
  name: string;
  label: string;
  description: string;
  rank: number;
}

export enum PermissionControlType {
  Boolean = 'bool',
  AllOrNone = 'all_none',
  AllOrTeamOrNone = 'all_team_none',
  AllOrOwnerOrNone = 'all_owner_none',
  AllOrTeamsOrOwnerOrNone = 'all_team_owner_none',
}

export interface ControlTypeValueOption {
  name: string;
  label?: string;
}

export enum PermissionValue {
  True = 'value_true',
  False = 'value_false',
  Everything = 'value_all',
  TeamOnly = 'value_team',
  NoAccess = 'value_none',
  Owner = 'value_owner',
}

export namespace PermissionValues {
  export const truthy = Object.freeze({
    [PermissionValue.True]: true,
    [PermissionValue.Everything]: true,
    [PermissionValue.Owner]: true,
    [PermissionValue.TeamOnly]: true,
  });

  export const falsy = Object.freeze({
    [PermissionValue.False]: true,
    [PermissionValue.NoAccess]: true,
  });
}

export const PermissionLevelMeta = {
  [PermissionValue.True]: { name: PermissionValue.True },
  [PermissionValue.False]: { name: PermissionValue.False },
  [PermissionValue.Everything]: { name: PermissionValue.Everything, label: 'Everything' },
  [PermissionValue.TeamOnly]: { name: PermissionValue.TeamOnly, label: 'Team Only' },
  [PermissionValue.NoAccess]: { name: PermissionValue.NoAccess, label: 'No Access' },
  [PermissionValue.Owner]: { name: PermissionValue.Owner, label: 'Created by the user' },
};

export const PermissionControlTypeValue: { [key in PermissionControlType]: ControlTypeValueOption[] } = {
  [PermissionControlType.Boolean]: [
    PermissionLevelMeta[PermissionValue.True],
    PermissionLevelMeta[PermissionValue.False],
  ],
  [PermissionControlType.AllOrNone]: [
    PermissionLevelMeta[PermissionValue.Everything],
    PermissionLevelMeta[PermissionValue.NoAccess],
  ],
  [PermissionControlType.AllOrTeamOrNone]: [
    PermissionLevelMeta[PermissionValue.Everything],
    PermissionLevelMeta[PermissionValue.TeamOnly],
    PermissionLevelMeta[PermissionValue.NoAccess],
  ],
  [PermissionControlType.AllOrOwnerOrNone]: [
    PermissionLevelMeta[PermissionValue.Everything],
    PermissionLevelMeta[PermissionValue.Owner],
    PermissionLevelMeta[PermissionValue.NoAccess],
  ],
  [PermissionControlType.AllOrTeamsOrOwnerOrNone]: [
    PermissionLevelMeta[PermissionValue.Everything],
    PermissionLevelMeta[PermissionValue.TeamOnly],
    PermissionLevelMeta[PermissionValue.Owner],
    PermissionLevelMeta[PermissionValue.NoAccess],
  ],
};

export interface CustomObjectPermissions {
  VIEW: string;
  CREATE: string;
  EDIT: string;
  DELETE: string;
  MANAGE_ADD_FORM: string;
  MANAGE_LAYOUT: string;
  PUBLIC_CUSTOM_VIEWS: string;
  SHARING_RECORDS: string;
}
