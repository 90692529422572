import { Pipe, PipeTransform } from '@angular/core';
import { SimpleOption } from '../models/simple-option';

@Pipe({
  name: 'labelForValue',
  standalone: true,
})
export class LabelForValuePipe implements PipeTransform {
  transform(value: string | string[], allOptions: SimpleOption<any>[]): string {
    if (Array.isArray(value)) {
      return value.map((v) => allOptions.find((option: SimpleOption) => option.value === v)?.label || '').join(', ');
    }

    return allOptions.find((option: SimpleOption) => option.value === value)?.label || '';
  }
}
