import { CustomObject } from 'app/shared/modules/custom-objects/custom-objects.types';
import { ObjectLink } from '../objects-manager.types';
import { FieldDef } from '@core/models';

export namespace ObjectsManagerActions {
  export class Get {
    static readonly type = 'Get Custom Objects';
  }

  export class GetForMenu {
    static readonly type = 'Get Custom Objects For Menu';
  }

  export class GetById {
    static readonly type = 'Get Custom Object By Id';
    constructor(public customObjectId: number) {}
  }

  export class Create {
    static readonly type = 'Create Custom Object';
    constructor(public customObject: CustomObject) {}
  }

  export class SetCustomObject {
    static readonly type = 'Set Custom Object';
    constructor(public customObject: CustomObject) {}
  }

  export class Patch {
    static readonly type = 'Patch Custom Object';
    constructor(
      public customObjectId: number,
      public customObject: CustomObject,
    ) {}
  }

  export class CreateLink {
    static readonly type = 'Create Custom Object Link';
    constructor(
      public customObjectId: number,
      public link: ObjectLink,
    ) {}
  }

  export class Delete {
    static readonly type = 'Delete Custom Object';
    constructor(public customObjectId: number) {}
  }

  export class AddOrUpdateObjectField {
    static readonly type = '[Custom Object] Add Or Update Object Field';
    constructor(public fieldDef: FieldDef) {}
  }
}
