import { TASK_WORKFLOW_STATUES } from '@tasks/services/task-status.service';
import { Task } from './tasks.types';
import { FieldDef } from '@core/models';
import { RelatedToType } from '@shared/enums';
export namespace TaskUtils {
  export const deepFindTaskById = (tasks: Task[], parentId: number): Task | undefined => {
    for (const task of tasks) {
      if (task.id === parentId) {
        return task;
      }

      if (task.children) {
        const found = deepFindTaskById(task.children, parentId);
        if (found) {
          return found;
        }
      }
    }

    return undefined;
  };

  export const deepTaskDeleteById = (tasks: Task[], deletedTaskIds: number[]): Task[] => {
    return tasks.filter((task) => {
      if (deletedTaskIds.includes(task.id)) {
        return false;
      }

      if (task.children) {
        task.children = deepTaskDeleteById(task.children, deletedTaskIds);
      }

      return true;
    });
  };

  export const removeDuplicatedAndUnsearchedTasks = (tasks: Task[]): Task[] => {
    const searchedTaskIds = new Set();

    // Identify and remove tasks from root level if their parent_id exists in root
    const filteredTasks = tasks.filter((task) => {
      if (tasks.some((rootTask) => rootTask.id === task.parent_id)) {
        searchedTaskIds.add(task.id);
        return false;
      }
      return true;
    });

    filteredTasks.forEach((task) => {
      if (task.children) {
        task.children = task.children.filter((child) => searchedTaskIds.has(child.id));
      }
    });

    return filteredTasks;
  };

  export const deepUpdateTaskById = (tasks: Task[], updatedTask: Task): Task[] => {
    return tasks.map((task) => {
      if (task.id === updatedTask.id) {
        return { ...task, ...updatedTask };
      }

      if (task.children) {
        return { ...task, children: deepUpdateTaskById(task.children, updatedTask) };
      }

      return task;
    });
  };

  export const deepSortTasksByRank = (tasks: Task[]): Task[] => {
    tasks?.sort((a, b) => a.rank - b.rank);
    return (
      tasks?.map((task) => {
        if (task.children) {
          task.children?.sort((a, b) => a.rank - b.rank);
          deepSortTasksByRank(task.children);
        }
        return task;
      }) ?? []
    );
  };

  export const flattenTasks = (tasks: Task[]): Task[] => {
    return (
      tasks?.reduce((acc, task) => {
        if (task?.children) {
          acc?.push(task, ...flattenTasks(task?.children));
        } else {
          acc?.push(task);
        }
        return acc;
      }, [] as Task[]) ?? []
    );
  };

  export const groupTasksByStatus = (tasks: Task[]): { [key: string]: Task[] } => {
    let groupedTasksByStatus = {};

    return TASK_WORKFLOW_STATUES.reduce((acc, worflow) => {
      acc[worflow.key] = tasks.filter((task) => task.workflow_state === worflow.key && task.task_type == 'task');
      return acc;
    }, groupedTasksByStatus);

    // for (let worflow of TASK_WORKFLOW_STATUES) {
    //   const results = tasks.filter((task) => task.workflow_state == worflow.key && task.task_type == 'task');
    //   groupedTasksByStatus[worflow.key] = results;
    // }

    // return groupedTasksByStatus;
  };

  export const getTaskStatusLabel = (workflowKey: string): string => {
    const taskStatus = TASK_WORKFLOW_STATUES.find((workflow) => workflow.key === workflowKey);
    return taskStatus?.label || workflowKey;
  };

  export const taskCustomFieldValuesAsAttributes = (task: Task): { [key: string]: any } => {
    const transformCfValues = (task: Task): { [key: string]: any } => {
      const attributes = task.custom_field_values.reduce((acc, field) => {
        acc[field.name] = field.val;
        return acc;
      }, {});

      if (task.children) {
        attributes['children'] = task.children.map((tchild) => transformCfValues(tchild));
      }

      return { ...task, ...attributes };
    };

    return transformCfValues(task);
  };

  export const getTaskDeleteConfirmationMessage = (task: Task): string => {
    const childrenCount = task.children.length;

    if (task.task_type === 'section') {
      switch (task.children.length) {
        case 0:
          return `You are deleting a section which will delete all tasks in the section. Do you wish to proceed?`;
        case 1:
          return `You are deleting a section that will also <strong>delete ${childrenCount} task</strong> associated with it
            Do you wish to proceed?`;
        default:
          return `You are deleting a section that will also <strong>delete ${childrenCount} tasks</strong> associated with it.
            Do you wish to proceed?`;
      }
    } else {
      switch (childrenCount) {
        case 0:
          return `Are you sure you want to delete this task?`;
        case 1:
          return `You are deleting a task that will also <strong>delete ${childrenCount} subtask</strong>.
            Do you wish to proceed?`;
        default:
          return `You are deleting a task that will also <strong>delete ${childrenCount} subtasks</strong>.
            Do you wish to proceed?`;
      }
    }
  };

  export const getApplicableFields = (relatedTo: RelatedToType | string, fields: FieldDef[]): FieldDef[] => {
    let notApplicableFields = ['project_title', 'project_id', 'project_phase_id', 'project_stage_id'];

    if (relatedTo === RelatedToType.Project) {
      notApplicableFields = ['property_id', 'property_title'];
    } else if (
      relatedTo === RelatedToType.Account ||
      relatedTo === RelatedToType.CustomObjectValue ||
      relatedTo?.startsWith('co_')
    ) {
      notApplicableFields.push('assigned_task_role_id');
    } else if (relatedTo === RelatedToType.AggregatedTasks) {
      return fields;
    }

    return fields.filter((x) => !notApplicableFields.includes(x.name));
  };

  export const getEditStartDateConfirmationMessage = (task: Task, fieldLabel = 'start_date'): string => {
    const hasLinkedTo = task.linked_to && task.linked_to.length > 0;
    const hasPredecessors = task.predecessors && task.predecessors.length > 0;
    fieldLabel = fieldLabel.toLowerCase() === 'start_date' ? 'Start Date' : 'Due Date';

    if (hasLinkedTo && hasPredecessors) {
      return `Manually changing the '${fieldLabel}' will result in the removal 
      of all predecessor relationships and links. Do you wish to proceed?`;
    }

    if (hasLinkedTo) {
      return `Manually changing the '${fieldLabel}' will result in the removal of all links. Do you wish to proceed?`;
    }

    if (hasPredecessors) {
      return `Manually changing the '${fieldLabel}' will result in the removal of all predecessor relationships. Do you wish to proceed?`;
    }

    return '';
  };

  export const hasObjectChanged = (obj1: any, obj2: any): boolean => {
    // Check for strict equality
    if (obj1 === obj2) {
      return false; // No change if both are identical
    }

    // Check if either of them is not an object or is null
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return true; // Change if either is not an object
    }

    // Get all keys of both objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If the number of keys is different, the objects are different
    if (keys1.length !== keys2.length) {
      return true;
    }

    // Check if all keys and values are the same
    for (const key of keys1) {
      // If obj2 doesn't have the same key or the values are different
      if (!keys2.includes(key) || hasObjectChanged(obj1[key], obj2[key])) {
        return true; // Return true if there is a change
      }
    }

    return false; // No change if no differences were found
  };
}
