import { inject, Injectable } from '@angular/core';
import { FeatureFlag, ModuleRelatedToMap, NewPermission, Permission } from 'app/core/enums';
import { RelatedToType } from 'app/shared/enums';
import { UserState } from 'app/state/user/state';
import { TsPageSidenavAction, TsPageSidenavConfig } from 'tsui/lib/ts-page/ts-page.component';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { SavedViewAddComponent } from 'app/shared/modules/saved-views/modals/saved-view-add/saved-view-add.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SavedViewsState } from 'app/state/saved-view/state';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { AccountState } from '@state/account/state';
import { PermissionUtils } from '@core/utils/permission.utils';
import { TSTreeNode } from 'tsui';
import { SavedView } from '@core/models';
import { SavedViewsActions } from '@state/saved-view/action';

@Injectable({
  providedIn: 'root',
})
export class SavedViewsSidebarService {
  readonly store = inject(Store);
  readonly matDialog = inject(MatDialog);
  readonly router = inject(Router);
  readonly activatedRoute = inject(ActivatedRoute);
  readonly actions$ = inject(Actions);

  private static get defaultViews() {
    return [
      'All Properties',
      'All Aggregated Tasks',
      'Property Tenants',
      'Property Units',
      'All Contacts',
      'All Companies',
    ];
  }

  static isDefaultView(viewName: string): boolean {
    return SavedViewsSidebarService.defaultViews.includes(viewName);
  }

  static sortSavedViews(svA: SavedView, svB: SavedView): number {
    if (SavedViewsSidebarService.isDefaultView(svA?.name)) {
      return -1;
    } else {
      return 1;
    }
  }

  static sortSavedViewsTreeNodes(nodeA: TSTreeNode, nodeB: TSTreeNode): number {
    if (SavedViewsSidebarService.isDefaultView(nodeA?.name)) {
      return -1;
    } else {
      return 1;
    }
  }

  private get isNewPermissionsEnabled(): boolean {
    return this.store.selectSnapshot(AccountState.isFeatureEnabled(FeatureFlag.NEW_PERMISSIONS));
  }

  private get savedViewPermissionByType(): { [key in RelatedToType]?: Permission | NewPermission | boolean } {
    return {
      AggregatedTasks: this.isNewPermissionsEnabled
        ? NewPermission.AGGREGATED_TASK_LISTS_CREATE
        : Permission.TASK_LIST_VIEWS,
      Property: this.isNewPermissionsEnabled ? NewPermission.PROPERTIES_PUBLIC_CUSTOM_VIEWS : true,
    };
  }

  getSidenavConfig(type: RelatedToType, canAddSavedView = true): TsPageSidenavConfig {
    const actions: TsPageSidenavAction[] = [];

    if (this.canAddSavedViewByType(type) && canAddSavedView) {
      actions.push({
        actionFn: () => {
          this.openAddSavedViewModal(type);
        },
        description: 'Add new saved view',
        icon: 'add_circle_outline',
      });
    }

    return {
      title: 'Saved Views',
      actions,
    };
  }

  canAddSavedViewByType(type: RelatedToType): boolean {
    const permissions = this.store.selectSnapshot(UserState.getUserPermissionNames);
    if (type.startsWith('co_')) {
      const customObjectPermission = this.getManagePublicCustomViewsCustomObjectPermission(type);
      if (customObjectPermission === true) {
        return true;
      }
      return permissions.includes(customObjectPermission as string);
    }

    if (this.savedViewPermissionByType[type]) {
      if (this.savedViewPermissionByType[type] === true) {
        return true;
      }

      return permissions.includes(this.savedViewPermissionByType[type] as NewPermission | Permission);
    }

    return true;
  }

  private openAddSavedViewModal(type: RelatedToType): void {
    this.matDialog.open(SavedViewAddComponent, {
      panelClass: 'mailbox-compose-dialog',
      data: {
        relatedToType: type,
        saved_views: this.store.selectSnapshot(SavedViewsState.getViews),
      },
    });

    this.actions$.pipe(ofActionSuccessful(SavedViewsActions.AddView), take(1)).subscribe(() => {
      let pointer = this.activatedRoute;
      while (pointer.firstChild) {
        pointer = pointer.firstChild;
      }

      const lastView = this.store.selectSnapshot(
        SavedViewsState.getLastViewFromType(
          pointer.snapshot.data.customObject?.name ?? ModuleRelatedToMap[pointer.snapshot.data.module],
        ),
      );

      this.router.navigate(['../../', lastView.id, 'table'], { relativeTo: pointer });
    });
  }

  getManagePublicCustomViewsCustomObjectPermission(customObjectName: string): string | boolean {
    if (this.store.selectSnapshot(AccountState.isFeatureEnabled(FeatureFlag.NEW_PERMISSIONS))) {
      return PermissionUtils.generateCustomObjectPermissions(customObjectName).PUBLIC_CUSTOM_VIEWS;
    } else {
      return true;
    }
  }
}
