import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  GetEthanChats,
  GetEthanQuestions,
  AskEthan,
  UpdateEthanAnswer,
  CreateEthanChat,
  DeleteEthanChat,
  UpdateEthanQuestion,
  SetSelectedChat,
} from './ethan.action';
import { EthanService } from './ethan.service'; // Import EthanService
import { tap } from 'rxjs/operators';
import { EthanChat, EthanMessage } from '@core/models/ethan.types';

export interface EthanStateModel {
  ethanChats: EthanChat[] | null;
  ethanQuestions: {
    [chatId: number]: { ethan_messages: EthanMessage[]; total: number; current_page: number; loading: boolean };
  };
  selectedChat: EthanChat | null;
}

@State<EthanStateModel>({
  name: 'ethan',
  defaults: {
    ethanChats: [],
    ethanQuestions: [],
    selectedChat: null,
  },
})
@Injectable()
export class EthanState {
  constructor(private ethanService: EthanService) {} // Inject EthanService

  @Selector()
  static ethanChats(state: EthanStateModel) {
    return state.ethanChats;
  }

  @Selector()
  static ethanQuestions(state: EthanStateModel) {
    return state.ethanQuestions;
  }

  @Selector()
  static selectedChat(state: EthanStateModel) {
    return state.selectedChat;
  }

  @Action(GetEthanChats)
  getEthanChats(ctx: StateContext<EthanStateModel>, action: GetEthanChats) {
    return this.ethanService.getEthanChats(action.searchText).pipe(
      tap((response) => {
        ctx.patchState({ ethanChats: response.ethan_chats });

        // Get messages for top 5 chats
        if (response.ethan_chats && response.ethan_chats.length > 0) {
          const top5Chats = response.ethan_chats.slice(0, 5);
          top5Chats.forEach((chat) => {
            ctx.dispatch(new GetEthanQuestions(chat.id));
          });
        }
      }),
    );
  }

  @Action(GetEthanQuestions)
  getEthanQuestions(ctx: StateContext<EthanStateModel>, action: GetEthanQuestions) {
    const pageNum = action.pageNum || 1;
    const currentState = ctx.getState();
    const currentQuestions = currentState.ethanQuestions || {};
    const currentChatData = currentQuestions[action.chatId];
    if (
      currentState.ethanQuestions[action.chatId] &&
      action.pageNum > currentState.ethanQuestions[action.chatId].total / 10 + 1
    ) {
      ctx.patchState({
        ethanQuestions: {
          ...currentQuestions,
          [action.chatId]: { ...currentChatData, loading: false },
        },
      });
      return;
    }

    ctx.patchState({
      ethanQuestions: {
        ...currentQuestions,
        [action.chatId]: { ...currentChatData, loading: true },
      },
    });

    // If requesting page 1 or requesting a new page beyond what's loaded
    if (pageNum === 1 || !currentChatData || pageNum > currentChatData.current_page) {
      return this.ethanService.getEthanQuestions(action.chatId, pageNum).pipe(
        tap((response) => {
          const existingMessages = currentChatData?.ethan_messages || [];

          ctx.patchState({
            ethanQuestions: response.results
              ? {
                  ...currentQuestions,
                  [action.chatId]: {
                    ethan_messages:
                      pageNum === 1
                        ? response.results.reverse()
                        : [...existingMessages.reverse(), ...response.results].reverse(),
                    total: response.total,
                    current_page: pageNum,
                    loading: false,
                  },
                }
              : currentQuestions,
          });
        }),
      );
    }

    // Return current state if requesting already loaded page
    return currentQuestions[action.chatId];
  }

  @Action(AskEthan)
  askEthan(ctx: StateContext<EthanStateModel>, action: AskEthan) {
    return this.ethanService.askEthan(action.chatId, action.ethanQuestion, action.fileUrl, action.fileName).pipe(
      tap((response) => {
        const currentQuestions = ctx.getState().ethanQuestions || {};
        const updatedQuestions = {
          ...currentQuestions,
          [action.chatId]: {
            ethan_messages: [...(currentQuestions[action.chatId]?.ethan_messages || []), response.ethan_message],
            total: currentQuestions[action.chatId]?.total || 0,
            current_page: currentQuestions[action.chatId]?.current_page || 1,
            loading: false,
          },
        };
        ctx.patchState({ ethanQuestions: updatedQuestions });
      }),
    );
  }

  @Action(UpdateEthanAnswer)
  updateEthanAnswer(ctx: StateContext<EthanStateModel>, action: UpdateEthanAnswer) {
    return this.ethanService.updateEthanAnswer(action.chatId, action.messageId, action.answer).pipe(
      tap(() => {
        const currentQuestions = ctx.getState().ethanQuestions || {};
        const updatedQuestions = {
          ...currentQuestions,
          [action.chatId]: {
            ethan_messages: currentQuestions[action.chatId]?.ethan_messages?.map((message) =>
              message.id === action.messageId ? { ...message, answer: action.answer } : message,
            ),
            total: currentQuestions[action.chatId]?.total || 0,
            current_page: currentQuestions[action.chatId]?.current_page || 1,
            loading: false,
          },
        };
        ctx.patchState({ ethanQuestions: updatedQuestions });
      }),
    );
  }

  @Action(CreateEthanChat)
  createEthanChat(ctx: StateContext<EthanStateModel>, action: CreateEthanChat) {
    return this.ethanService.createEthanChat(action.chatName).pipe(
      tap((response) => {
        const currentChats = ctx.getState().ethanChats || [];
        ctx.patchState({ ethanChats: [...currentChats, response.ethan_chat] });
      }),
    );
  }

  @Action(DeleteEthanChat)
  deleteEthanChat(ctx: StateContext<EthanStateModel>, action: DeleteEthanChat) {
    return this.ethanService.deleteEthanChat(action.chatId).pipe(
      tap(() => {
        const currentChats = ctx.getState().ethanChats;
        ctx.patchState({ ethanChats: currentChats?.filter((chat) => chat.id !== action.chatId) || null });
      }),
    );
  }

  @Action(UpdateEthanQuestion)
  updateEthanQuestion(ctx: StateContext<EthanStateModel>, action: UpdateEthanQuestion) {
    return this.ethanService.updateEthanQuestion(action.ethanQuestion).pipe(
      tap((response) => {
        const ethanQuestions = ctx.getState().ethanQuestions || {};
        const index = ethanQuestions[action.chatId]?.ethan_messages?.findIndex(
          (q) => q.id === response.ethan_question.id,
        );
        if (index !== -1 && ethanQuestions[action.chatId]?.ethan_messages) {
          ethanQuestions[action.chatId].ethan_messages[index] = response.ethan_question;
        }
        ctx.patchState({ ethanQuestions });
      }),
    );
  }

  @Action(SetSelectedChat)
  setSelectedChat(ctx: StateContext<EthanStateModel>, action: SetSelectedChat) {
    ctx.patchState({ selectedChat: action.chat });
    return action.chat;
  }
}
