import { PlatformModule } from '@angular/cdk/platform';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, InjectionToken, isDevMode, NgModule } from '@angular/core';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsModule, Store } from '@ngxs/store';
import { TreoModule } from 'tsui/@treo';
import { TreoConfigModule } from 'tsui/@treo';
import { MarkdownModule, MARKED_OPTIONS } from 'ngx-markdown';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from 'app/core/services/rollbar.service';
import { environment } from '../environments/environment';
import { AccountService, AuthService } from './core';
import { appInitializer } from './app.initializer';
import { PipelinesState } from './state/pipelines/state';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SavedFiltersState } from './state/saved-filters/state';
import { SavedViewsState } from './state/saved-view/state';
import { TsButtonModule } from 'tsui';
import { AppStates } from './app.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { LookupService } from '@services/lookup.service';
import { LOOKUP_SERVICE_TOKEN } from 'tsui/lib/cross-cutting/util';

declare global {
  interface Window {
    analytics: any;
    rnw: any;
  }
}

const routerConfig: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  paramsInheritanceStrategy: 'always',
  ...(isDevMode() ? {} : { preloadingStrategy: PreloadAllModules }),
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, routerConfig),

    // Treo & Treo Mock API
    TreoModule,
    TreoConfigModule.forRoot({ theme: 'light', layout: 'modern' }),
    PlatformModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatButtonModule,
    TsButtonModule,
    // Core
    CoreModule,

    // Layout
    LayoutModule,

    // 3rd party modules
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useValue: {
          gfm: true,
          breaks: true,
          pedantic: false,
          smartLists: true,
          smartypants: true,
          headerIds: true,
          mangle: false,
        },
      },
    }),

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    NgxsModule.forRoot([PipelinesState, SavedFiltersState, SavedViewsState, ...AppStates]),
    NgxsReduxDevtoolsPluginModule.forRoot({
      maxAge: 50,
      name: `TermSheet - ${environment?.baseUrl || ''}`,
      disabled: !environment?.enableReduxDevtools, // Disable the devtools plugin in production
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, deps: [AuthService, AccountService, Store], multi: true },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: LOOKUP_SERVICE_TOKEN, useClass: LookupService },
  ],
})
export class AppModule {}
