import { Component, Inject, OnInit, ViewEncapsulation, inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Actions, Store, ofActionSuccessful } from '@ngxs/store';
import { SavedView } from 'app/core/models';
import { RelatedToType } from 'app/shared/enums';
import { SavedViewsActions } from 'app/state/saved-view/action';
import * as CustomValidator from 'app/shared/directives/custom-validators.directive';
@Component({
  selector: 'saved-view-add',
  templateUrl: './saved-view-add.component.html',
  styleUrls: ['./saved-view-add.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SavedViewAddComponent extends OnDestroyMixin implements OnInit {
  public addPipelineForm: UntypedFormGroup;

  private store = inject(Store);
  private actions = inject(Actions);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { relatedToType: RelatedToType; saved_views: SavedView[] },
    public matDialogRef: MatDialogRef<SavedViewAddComponent>,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.addPipelineForm = this.formBuilder.group({
      name: ['', [Validators.required, CustomValidator.uniqueNameValidator(this.data.saved_views, 'name')]],
      related_to_type: [''],
    });

    this.addPipelineForm.get('related_to_type').setValue(this.data.relatedToType);
  }

  private addDefinitionBeforeSave(formValue: { name: string; related_to_type: RelatedToType; definition?: any }) {
    switch (formValue.related_to_type) {
      case RelatedToType.AggregatedTasks:
        formValue.definition = JSON.stringify({
          table: { fields: ['title', 'start_date', 'assigned_user_id'] },
        });
        break;
      default:
        break;
    }
  }

  discard(): void {
    this.matDialogRef.close(true);
  }

  save(): void {
    this.addPipelineForm.disable();
    const formValue = this.addPipelineForm.value;
    this.addDefinitionBeforeSave(formValue);
    this.actions
      .pipe(ofActionSuccessful(SavedViewsActions.AddView), take(1), untilComponentDestroyed(this))
      .subscribe(() => {
        this.store.dispatch(new SavedViewsActions.LoadViews());
        this.discard();
      });

    this.store.dispatch(new SavedViewsActions.AddView(formValue));
  }
}
